<template lang="html">
  <div class="user-image-block">
    <div class="user-image-container">
      <ImageItem
        v-if="imageHref"
        :source="imageHref"
      />
    </div>
  </div>
</template>

<script>
//import components
import ImageItem from '@/components/common/ImageItem.vue';

const API_ROUTE = process.env.VUE_APP_API_URL + "img/"
export default {
  components: {
    ImageItem,
  },
  props: {
    img: {
      type: String,
      required: true,
    }
  },
  computed: {
    imageHref: function() {
      return this.img ? API_ROUTE + this.img : '/img/no_photo_squared.png';
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/css/common/UserProfilePicture.scss";
</style>
