<template lang="html">
  <div>
    <div class="grid border rounded">
      <div class="grid grid__title">
        {{ grid_title }}
      </div>
      <div class="grid grid__body">
        <div v-if="!courses.length" class="nothing-found text-center">
          <div class="no-course-text my-auto">
            {{ localizedTextFct("course_grid", "no_course_found") }}
          </div>
        </div>
        <div v-if="courses.length" class="row m-1 p-2 course-col">
          <div
            class="col-md-2 col-sm-4 col-6 my-2 text-center"
            :class="custom_class_style"
            v-for="(course, index) in courses"
            :key="index"
          >
            <CourseGridElement :course="course" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CourseGridElement from "@/components/common/CourseGridElement";

export default {
  name: "course_grid",
  components: {
    CourseGridElement,
  },
  props: {
    courses: {
      type: Array,
      required: true,
    },
    course_list_name: {
      type: String,
      required: true,
    },
    grid_title: {
      type: String,
      required: true,
    },
    custom_class_style: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/common/CourseGrid";
</style>
