import axios from 'axios';
import TokenService from "./TokenService";


const API_URL = process.env.VUE_APP_API_URL + 'users/';

class AuthService {
  login(user) {
    return axios.post(API_URL + 'login', {
        username: user.username,
        password: user.password
      },
      {
        withCredentials: process.env.VUE_APP_MODE == "staging" ? false : true, // disable for network testing
      }
    ).then(response => {
        if (response.data.user.accessToken) {
          TokenService.setUser(response.data.user, response.data.user_type_info, true);
        }
        return response.data.user;
      });
  }
  adminLogin(user) {
    return axios.post(API_URL + 'admin_login', {
        username: user.username,
        password: user.password
      }).then(response => {
        if (response.data.user.accessToken) {
          TokenService.setUser(response.data.user);
        }
        return response.data.user;
      });
  }

  logout(type=null) {
    TokenService.removeUser(type);
  }

  register(user, recaptchaToken, user_time_zone) {
    return axios.post(API_URL + 'register', {
      user_type: user.user_type,
      username: user.username,
      email: user.email,
      password: user.password,
      password2: user.password2,
      recaptchaToken: recaptchaToken,
      user_time_zone: user_time_zone,
    });
  }

  forgot(email) {
    return axios.post(API_URL + 'forgot', {
      email: email,
    });
  }

  reset(user, token) {
    return axios.post(API_URL + 'reset' + '/' + token, {
      password: user.password,
      password2: user.password2,
    });
  }

  verify(id, email, user_time_zone) {
    return axios.post(API_URL + 'verify' + '?' + 'id=' + id + '&' + 'email=' + email, {
      user_time_zone: user_time_zone,
    });
  }
}

export default new AuthService();
