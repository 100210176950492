<template lang="html">
  <div class="about">
    <div class="about__name">
      {{ user.first_name }}
    </div>
    <div class="about__title">
      {{ user.description.title }}
    </div>
    <!-- BEM is retarded -->
    <div class="about__info">
      <div class="about__item about__subjects">
        <font-awesome-icon class="about__icon" :icon="['fas', 'graduation-cap']" />
        <div class="about__text">
          {{ localizedTextFct("teacher", "teaches") }} {{ user.description.subjects[0] }}
        </div>
      </div>
      <!-- <div class="about__item about__languages">
        <font-awesome-icon class="about__icon" :icon="['fas', 'globe']" />
        <div class="about__text">{{ localizedTextFct("teacher", "english") }}</div>
      </div>
      <div class="about__item about__number-of-lessons">
        <font-awesome-icon class="about__icon" :icon="['fas', 'user']" />
        <div class="about__text">100</div>
      </div> -->
    </div>

    <div class="details">
      <div class="switch switch--sticky">
        <div
          @click="scrollToElement('about')"
          class="switch__button"
          :class="{ 'switch__button--active': current_tab == 'about' }"
        >
          <div class="switch__text">{{ localizedTextFct("teacher", "about") }}</div>
        </div>
        <div
          @click="scrollToElement('reviews')"
          class="switch__button"
          v-show="user.reviews.length"
          :class="{ 'switch__button--active': current_tab == 'reviews' }"
        >
          <div class="switch__text">{{ localizedTextFct("teacher", "reviews") }}</div>
        </div>
        <div
          @click="scrollToElement('resume')"
          class="switch__button"
          :class="{ 'switch__button--active': current_tab == 'resume' }"
        >
          <div class="switch__text">{{ localizedTextFct("teacher", "resume") }}</div>
        </div>
        <div
          @click="scrollToElement('schedule')"
          class="switch__button"
          :class="{ 'switch__button--active': current_tab == 'schedule' }"
        >
          <div class="switch__text">{{ localizedTextFct("teacher", "schedule") }}</div>
        </div>
      </div>
      <div ref="about" class="details__item details__about">
        <div class="details__title">
          {{ localizedTextFct("teacher", "about_teacher") }}
        </div>

        <div
          class="details__description"
          :class="
            show_description
              ? 'details__description--full'
              : 'details__description--short'
          "
        >
          {{ user.description.about_me }}
          <br />
          <br />
          {{ user.description.experience }}
        </div>
        <a
          v-show="!show_description"
          class="details__button"
          @click="show_description = true"
        >
          {{ localizedTextFct("teacher", "show_more") }}
        </a>
        <a
          v-show="show_description"
          class="details__button"
          @click="show_description = false"
        >
          {{ localizedTextFct("teacher", "hide") }}
        </a>
      </div>
      <div
        v-show="user.reviews.length"
        ref="reviews"
        class="details__item details__reviews"
      >
        <div class="details__title">{{ localizedTextFct("teacher", "reviews") }}</div>
        <div class="details__content">
          <div class="review" v-for="review in user.reviews" :key="review._id">
            <!-- <div class="review__title"></div>
          <div class="review__title"></div> -->
          </div>
        </div>
      </div>
      <div ref="resume" class="details__item details__resume">
        <div class="details__title">{{ localizedTextFct("teacher", "resume") }}</div>
        <div class="switch switch--justify-left">
          <div
            @click="current_resume_tab = 'education'"
            class="switch__button"
            :class="current_resume_tab == 'education' ? 'switch__button--active' : ''"
          >
            <div class="switch__text switch__text--small">
              <font-awesome-icon
                class="about__icon"
                :icon="['fas', 'graduation-cap']"
              />{{ localizedTextFct("teacher", "education") }}
            </div>
          </div>
          <div
            @click="current_resume_tab = 'work_experience'"
            class="switch__button"
            :class="
              current_resume_tab == 'work_experience' ? 'switch__button--active' : ''
            "
          >
            <div class="switch__text switch__text--small">
              <font-awesome-icon class="about__icon" :icon="['fas', 'briefcase']" />
              {{ localizedTextFct("teacher", "work_experience") }}
            </div>
          </div>
          <div
            @click="current_resume_tab = 'certificates'"
            class="switch__button"
            :class="current_resume_tab == 'certificates' ? 'switch__button--active' : ''"
          >
            <div class="switch__text switch__text--small">
              <font-awesome-icon class="about__icon" :icon="['fas', 'certificate']" />{{
                localizedTextFct("teacher", "certification")
              }}
            </div>
          </div>
        </div>
        <div v-show="current_resume_tab == 'education'" class="tab">
          <div
            class="education"
            v-for="education in user.educational_background"
            :key="education._id"
          >
            <div class="tab__element">
              <div class="tab__year-span">
                {{ education.start + " — " + education.end }}
              </div>
              <div class="tab__content">
                <div class="tab__title">{{ education.institution_name }}</div>
                <div class="tab__sub-title">{{ education.degree }}</div>
                <div v-if="education.file_status == 'verified'" class="tab__status">
                  <font-awesome-icon
                    class="about__icon about__icon--green"
                    :icon="['far', 'check-circle']"
                  />{{ localizedTextFct("teacher", "diploma_verified") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="current_resume_tab == 'work_experience'" class="tab">
          <div
            class="education"
            v-for="experience in user.proffessional_experience"
            :key="experience._id"
          >
            <div class="tab__element">
              <div class="tab__year-span">
                {{ experience.start + " — " + experience.end }}
              </div>
              <div class="tab__content">
                <div class="tab__title">{{ experience.company }}</div>
                <div class="tab__sub-title">{{ experience.occupation }}</div>
                <div v-if="experience.file_status == 'verified'" class="tab__status">
                  <font-awesome-icon
                    class="about__icon about__icon--green"
                    :icon="['far', 'check-circle']"
                  />{{ localizedTextFct("teacher", "experience_verified") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="current_resume_tab == 'certificates'" class="tab">
          <div
            class="education"
            v-for="certificate in user.certificates"
            :key="certificate._id"
          >
            <div class="tab__element">
              <div class="tab__year-span">
                {{ certificate.start + " — " + certificate.end }}
              </div>
              <div class="tab__content">
                <div class="tab__title">{{ certificate.issued_by }}</div>
                <div class="tab__sub-title">{{ certificate.subject }}</div>
                <div v-if="certificate.file_status == 'verified'" class="tab__status">
                  <font-awesome-icon
                    class="about__icon about__icon--green"
                    :icon="['far', 'check-circle']"
                  />{{ localizedTextFct("teacher", "certificate_verified") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref="schedule" class="details__item details__schedule">
        <div class="details__title">{{ localizedTextFct("teacher", "schedule") }}</div>
        <FullCalendarScheduleTime
          :user_id="this.user.user"
          select_id="timezone2"
          @element-clicked="$emit('element-clicked')"
        />
      </div>
    </div>
  </div>
</template>
<script>
import FullCalendarScheduleTime from "@/components/common/FullCalendarScheduleTime.vue";

export default {
  components: {
    FullCalendarScheduleTime,
  },
  props: {
    user: {
      type: Object,
    },
  },
  data() {
    return {
      show_description: false,
      current_resume_tab: "education",
      current_tab: "about",
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll, true);
  },
  methods: {
    onScroll(e) {
      const currentScrollPosition = e.target.documentElement?.scrollTop;
      const about = this.$refs["about"];
      const reviews = this.$refs["reviews"];
      const resume = this.$refs["resume"];
      const schedule = this.$refs["schedule"];
      const resumeTop = resume.offsetTop;
      const reviewsTop = reviews.offsetTop ? reviews.offsetTop : resumeTop;
      const aboutTop = about.offsetTop;
      const scheduleTop = schedule.offsetTop;
      if (currentScrollPosition >= aboutTop && currentScrollPosition < reviewsTop) {
        this.current_tab = "about";
      } else if (
        currentScrollPosition >= reviewsTop &&
        currentScrollPosition < resumeTop
      ) {
        this.current_tab = "reviews";
      } else if (
        currentScrollPosition >= resumeTop &&
        currentScrollPosition < scheduleTop
      ) {
        this.current_tab = "resume";
      } else if (currentScrollPosition >= scheduleTop) {
        this.current_tab = "schedule";
      }
    },
    scrollToElement(name) {
      const el = this.$refs[name];

      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({ behavior: "smooth" });
      }
      this.current_tab = name;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/css/variables.scss";

.tab {
  padding-left: $lap;
  padding-top: $lap;
  padding-right: $lap;
  &__element {
    display: flex;
    flex-direction: row;
  }
  &__content {
    line-height: 1.6;
    margin-bottom: $lap;
  }
  &__title {
    font-weight: 400;
  }
  &__sub-title {
    font-weight: 400;
    opacity: 0.9;
  }
  &__year-span {
    margin-right: $lap;
    letter-spacing: 0.15em;
  }
  &__status {
    color: green;
  }
}

.details {
  &__title {
    font-size: $fontSizeLarge;
    font-weight: 600;
    line-height: 36px;
    margin-top: $f5;
    margin-bottom: $f5;
  }
  &__description {
    padding-left: $lap;
    padding-right: $lap;
    white-space: pre-line;
    text-align: justify;
    overflow: hidden;
    &--short {
      height: 200px;
    }
  }
  &__button {
    color: $buttonBgColor;
    text-decoration: underline;
    &:hover {
      cursor: pointer;
      color: $buttonBgColorHover;
    }
    &:active {
      cursor: default;
      color: $buttonBgColorActive;
    }
  }
  &__item {
    border-top: 1px solid rgba($color: $courseElementBgColor, $alpha: 1);
  }
}

.switch {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  background-color: $commonBgColor;
  border-bottom: 1px solid rgba($color: $courseElementBgColor, $alpha: 1);
  &--sticky {
    top: 0;
    position: sticky;
    z-index: 1;
  }
  &--justify-left {
    justify-content: flex-start;
    margin-left: $lap;
    border-bottom: 1px solid rgba($color: $courseElementBgColor, $alpha: 1);
  }
  &__button {
    border-bottom: 0.2em solid $commonBgColor;
    &:hover {
      cursor: pointer;
      border-bottom: 0.2em solid $courseElementBgColor;
    }
    &:active {
      border-bottom: 0.2em solid $courseElementBgColorHover;
    }
    &--active {
      &:hover {
        cursor: pointer;
        border-bottom: 0.2em solid $courseElementBgColorHover;
      }
      border-bottom: 0.2em solid $courseElementBgColorHover;
    }
  }
  &__text {
    padding: $f4 $f7;
    font-weight: 500;
    font-size: $fontSizeHeader;
    &--small {
      font-size: initial;
    }
  }
}

.about {
  background-color: $commonBgColor;
  border-radius: $borderRadius;
  padding: $lap;
  &__name {
    font-size: $fontSizeExtraLarge;
    font-weight: 600;
    line-height: 36px;
  }
  &__title {
    font-family: $fontFamilyVerdana;
    font-size: $fontSizeHeader;
  }
  &__text {
  }
  &__languages {
  }
  &__icon {
    margin-right: 4px;
    width: 20px;
    color: white;
    &--green {
      color: green;
    }
  }
  &__item {
    margin: 4px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
  }
  &__info {
    margin: 8px;
    opacity: 0.9;
    color: black;
  }
}

@media screen and (max-width: 576px) {
  .timeslots {
    font-size: $fontSizeMedium;
  }

  .schedule__header {
    flex-wrap: wrap;
  }

  .switch--justify-left {
    text-align: center;
    justify-content: center;
    width: 100%;
    margin-left: 0;
    flex-wrap: wrap;
  }

  .switch__button {
    flex-grow: 1;
    border-radius: $borderRadius;
    &--active {
      background-color: $courseElementBgColorHover;
    }
  }
}
</style>
