<template lang="html">
  <div class="panel" :class="'panel' + '-' + type">
    <div class="panel-text">
      {{ text }}
    </div>
    <font-awesome-icon
      icon="info-circle"
      class="panel-icon panel-icon--info"
      v-if="type === 'info'"
    />
    <font-awesome-icon
      :icon="['far', 'check-circle']"
      class="panel-icon panel-icon--success"
      v-if="type === 'success'"
    />
    <font-awesome-icon
      icon="exclamation-circle"
      class="panel-icon panel-icon--caution"
      v-if="type === 'caution'"
    />
    <font-awesome-icon
      :icon="['far', 'times-circle']"
      class="panel-icon panel-icon--danger"
      v-if="type === 'danger'"
    />
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      validator: function (value) {
        if (
          value === "info" ||
          value === "alert" ||
          value === "caution" ||
          value === "danger" ||
          value === "success"
        ) {
          return true;
        } else {
          return false;
        }
      },
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.panel {
  position: relative;
  padding: 4px 4px 4px 24px;
  border-radius: 4px;
  &-icon {
    position: absolute;
    top: 8px;
    left: 4px;

    &--info {
      color: royalblue;
    }
    &--caution {
      color: yellow;
    }
    &--success {
      color: green;
    }
    &--danger {
      color: firebrick;
    }
  }
  &-text {
    margin-right: 4px;
  }
  &-info {
    background-color: #89cff0;
  }
  &-caution {
    background-color: darkorange;
  }
  &-success {
    background-color: lightgreen;
  }
  &-danger {
    background-color: red;
  }
}
</style>
