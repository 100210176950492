<template lang="html">
  <div>
    <div class="grid border rounded">
      <div class="grid grid__title">
        {{ grid_title }}
      </div>
      <div class="grid grid__body">
        <div v-if="!users.length" class="nothing-found text-center">
          <div class="no-course-text my-auto">
            {{ localizedTextFct("general", "nothing_found") }}
          </div>
        </div>
        <div class="row m-1 p-2 course-col">
          <div
            class="col-md-2 col-sm-4 col-6 my-2 text-center"
            :class="custom_class_style"
            v-for="(user, index) in users"
            :key="index"
          >
            <GridElement
              :element="user"
              :list_name="list_name"
              :user_type="user_type"
              :grid_content="grid_content"
              :show_buttons="show_buttons"
              element_type="user"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GridElement from "@/components/students/GridElement";

export default {
  name: "users_grid",
  components: {
    GridElement,
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
    list_name: {
      type: String,
      required: true,
    },
    user_type: {
      type: String,
      required: true,
    },
    grid_content: {
      type: String,
      required: true,
    },
    grid_title: {
      type: String,
      required: true,
    },
    show_buttons: {
      type: Boolean,
      default: true,
    },
    custom_class_style: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/common/CourseGrid";
</style>
