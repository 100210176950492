var render = function render(){var _vm=this,_c=_vm._self._c;return (this.course_progress)?_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 course-image-block-container"},[(this.course.img)?_c('div',{staticClass:"course-image-container"},[_c('div',{staticClass:"img-container"},[_c('ImageItem',{attrs:{"source":_vm.courseImg(this.course.img)}})],1),_c('div',{staticClass:"course-title"},[_vm._v(" "+_vm._s(_vm.course.title)+" ")])]):_vm._e()]),_c('div',{staticClass:"col-md-4 section-names"},[_c('div',{staticClass:"section-names-header"},[_vm._v(" "+_vm._s(_vm.localizedTextFct("sections", "sections"))+" ")]),_c('div',{staticClass:"section-names-container"},_vm._l((_vm.course.sections),function(section,index){return _c('div',{key:index,staticClass:"section-name"},[_vm._v(" "+_vm._s(section.title)+" ")])}),0)]),_c('div',{staticClass:"col-md-4 course-description-container"},[_c('div',{staticClass:"section-names-header"},[_vm._v(" "+_vm._s(_vm.localizedTextFct("sections", "description"))+" ")]),_c('div',{staticClass:"course-description"},[_vm._v(" "+_vm._s(_vm.course.description)+" ")]),_c('div',{staticClass:"overview-button-container"},[(this.course_progress.status == 'not_started')?_c('button',{staticClass:"btn btn-primary overview-button",attrs:{"type":"button","name":"button","disabled":(_vm.course.teacher_access_type === 'subscription' &&
              _vm.user_type === 'student' &&
              !_vm.activeSubscription) ||
            (_vm.course.teacher_access_type === 'purchase' &&
              _vm.user_type === 'student' &&
              _vm.$store.state.user_module.user_type_info.purchased_courses.indexOf(
                _vm.course.id
              ) === -1)},on:{"click":_vm.startCourse}},[_vm._v(" "+_vm._s(_vm.localizedTextFct("course_overview", "start_course"))+" ")]):_vm._e(),_c('router-link',{attrs:{"to":{ name: 'course', params: { id: _vm.$route.params.id } }}},[(this.course_progress.status == 'in_progress')?_c('button',{staticClass:"btn btn-primary overview-button",attrs:{"type":"button","name":"button","disabled":(_vm.course.teacher_access_type === 'subscription' &&
                _vm.user_type === 'student' &&
                !_vm.activeSubscription) ||
              (_vm.course.teacher_access_type === 'purchase' &&
                _vm.user_type === 'student' &&
                _vm.$store.state.user_module.user_type_info.purchased_courses.indexOf(
                  _vm.course.id
                ) === -1)}},[_vm._v(" "+_vm._s(_vm.localizedTextFct("course_overview", "continue_course"))+" ")]):_vm._e()]),_c('router-link',{attrs:{"to":{ name: 'course', params: { id: _vm.$route.params.id } }}},[(this.course_progress.status == 'finished')?_c('button',{staticClass:"btn btn-primary overview-button",attrs:{"type":"button","name":"button","disabled":(_vm.course.teacher_access_type === 'subscription' &&
                _vm.user_type === 'student' &&
                !_vm.activeSubscription) ||
              (_vm.course.teacher_access_type === 'purchase' &&
                _vm.user_type === 'student' &&
                _vm.$store.state.user_module.user_type_info.purchased_courses.indexOf(
                  _vm.course.id
                ) === -1)}},[_vm._v(" "+_vm._s(_vm.localizedTextFct("course_overview", "view_course"))+" ")]):_vm._e()])],1)])]),(
      _vm.course.teacher_access_type === 'subscription' &&
      _vm.user_type === 'student' &&
      !_vm.activeSubscription
    )?_c('div',{staticClass:"alert-container"},[_c('div',{staticClass:"panel-container"},[_c('Panel',{attrs:{"text":_vm.localizedTextFct('messages', 'subscribe_warning_course'),"type":"caution"}})],1),_c('div',{staticClass:"router-container"},[_c('router-link',{attrs:{"to":{ name: 'settings', params: { option: 'subscriptions' } }}},[_c('button',{staticClass:"btn btn-primary h-100 w-100",attrs:{"type":"button","name":"button"}},[_vm._v(" "+_vm._s(_vm.localizedTextFct("course_overview", "subscribe"))+" ")])])],1)]):_vm._e(),(
      _vm.course.teacher_access_type === 'purchase' &&
      _vm.user_type === 'student' &&
      _vm.$store.state.user_module.user_type_info.purchased_courses.indexOf(_vm.course.id) ===
        -1
    )?_c('div',{staticClass:"alert-container"},[_c('div',{staticClass:"panel-container"},[_c('Panel',{attrs:{"text":_vm.localizedTextFct('messages', 'course_not_free'),"type":"caution"}})],1),_c('div',{staticClass:"price-container"},[_c('div',{},[_vm._v(" "+_vm._s("Price:")+" "),_c('span',{staticClass:"price-tag"},[_vm._v(" "+_vm._s(" $" + _vm.course.price)+" ")])])]),_c('div',{staticClass:"router-container"},[_c('button',{staticClass:"btn btn-primary h-100 w-100",attrs:{"type":"button","name":"button","disabled":_vm.loading,"aria-disabled":_vm.loading},on:{"click":_vm.purchaseCourse}},[_vm._v(" "+_vm._s(_vm.localizedTextFct("course_overview", "buy_course"))+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"spinner-container"},[_c('ImageSpinner')],1)])]):_vm._e(),(
      _vm.course.teacher_access_type === 'purchase' &&
      _vm.user_type === 'student' &&
      !_vm.$store.state.user_module.user.default_card_id &&
      _vm.$store.state.user_module.user_type_info.purchased_courses.indexOf(_vm.course.id) ===
        -1
    )?_c('div',{staticClass:"alert-container",class:{ shake: _vm.animated }},[_c('div',{staticClass:"panel-container panel-container--big"},[_c('Panel',{attrs:{"text":_vm.localizedTextFct('messages', 'add_card_warning'),"type":"caution"}})],1),_c('div',{staticClass:"router-container"},[_c('router-link',{attrs:{"to":{ name: 'settings', params: { option: 'add-card' } }}},[_c('button',{staticClass:"btn btn-primary h-100 w-100",attrs:{"type":"button","name":"button"}},[_vm._v(" "+_vm._s(_vm.localizedTextFct("course_overview", "add_card"))+" ")])])],1)]):_vm._e(),_c('div',{ref:"message_box",staticClass:"message_container"},[_c('MessageBox',{attrs:{"message_type":_vm.message_type,"messages":_vm.messages}})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }