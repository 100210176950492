<template lang="html">
  <div class="course-container" @mouseover="hovered = true" @mouseleave="hovered = false">
    <div class="image-button-wrapper">
      <a @click="$emit('element-clicked')">
        <div class="image-container">
          <ImageItem :source="elementImg()" :size="128" />
        </div>
      </a>
    </div>

    <a @click="$emit('element-clicked')">
      <div class="course-title">{{ element.first_name }} {{ element.second_name }}</div>
    </a>
  </div>
</template>

<script>
import ImageItem from "@/components/common/ImageItem";

export default {
  name: "GridElement",
  props: {
    element: {
      type: Object,
      required: true,
    },
    element_type: {
      type: String,
      required: true,
    },
    list_name: {
      type: String,
      required: true,
    },
    user_type: {
      type: String,
      required: true,
    },
    grid_content: {
      type: String,
    },
  },
  components: {
    ImageItem,
  },
  data() {
    return {
      loading: false,
      hovered: false,
    };
  },
  methods: {
    elementImg() {
      if (this.element.img) {
        if (this.element_type == "user") {
          return process.env.VUE_APP_API_URL + "img/" + this.element.img;
        } else if (this.element_type == "course") {
          return (
            process.env.VUE_APP_API_URL +
            "img/courses/" +
            this.element._id +
            "/" +
            this.element.img
          );
        }
      } else {
        return "/img/no_photo_squared.png";
      }
    },
  },
  computed: {
    routeName() {
      return `${this.element_type}-${this.user_type}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/common/GridElement";

.extra-container {
  border-top: 2px solid black;
}
</style>
