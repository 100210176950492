<template>
  <div class="container-fluid zoom-room my-3">
    <div class="row" v-if="get_access && !loading">
      <ZoomVideoChat
        :token="Token"
        :room_name="roomName"
        :lesson="lesson"
        :participants="getLessonParticipants()"
      />
    </div>
    <div v-if="!get_access && !loading">
      <div class="row session">
        <div class="col-md-2">
          <div class="image-container-mine">
            <ImageItem :source="video_conference" />
          </div>
          <div class="session__element page__title">
            <div class="session__item"></div>
            <div class="session__item">
              <div class="session__title">
                {{ localizedTextFct("video_room", "video_conference") }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="session__element">
            <FullCalendarListView @event-clicked="showEventDetails" />
          </div>
        </div>
        <div class="col-md-2" ref="scrollToMe">
          <div v-if="event_selected" class="session__element">
            <div class="pop-up__element">
              <div class="pop-up__title">{{ event.title }}</div>
            </div>
            <div class="pop-up__element pop-up__element--detail">
              <div class="info__item info__teacher-img">
                <ImageItem
                  :source="imageHref(getLessonParticipants().otherUser.user_type_id.img)"
                />
              </div>
              <div class="info__item info__teacher-details">
                <div class="detail detail__name">
                  {{
                    getLessonParticipants().otherUser.user_type_id.first_name +
                    " " +
                    getLessonParticipants().otherUser.user_type_id.second_name
                  }}
                </div>
              </div>
            </div>
            <div class="pop-up__element">
              <div class="pop-up__description" v-if="other_user_type === 'teacher'">
                {{
                  getLessonParticipants().teacherUser.user_type_id.description
                    .subjects[0] + " teacher"
                }}
              </div>
              <div class="info__item info__title">
                {{ localizedTextFct("video_room", "event_start") }}
              </div>
              <div class="info__item info__date">
                {{ formatDate(event.start) }}
              </div>
              <div class="info__item info__title">
                {{ localizedTextFct("video_room", "event_end") }}
              </div>
              <div class="info__item info__date">
                {{ formatDate(event.end) }}
              </div>
            </div>
            <div class="pop-up__element pop-up__buttons">
              <button
                v-show="joinTime"
                class="btn pop-up__button"
                @click="joinLesson"
                :disabled="loadingLesson"
              >
                Join the lesson
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ZoomVideoChat from "@/components/common/ZoomVideoChat.vue";
import ImageItem from "@/components/common/ImageItem.vue";
import FullCalendarListView from "@/components/common/FullCalendarListView.vue";

import { format, parseISO, compareAsc, sub } from "date-fns";

import { enGB, ru } from "date-fns/locale";

//import services
import SharedDataService from "@/services/SharedDataService.js";

//import mixins
import windowSize from "@/mixins/windowSize.js";

const API_ROUTE = process.env.VUE_APP_API_URL + "img/";

export default {
  name: "ZoomRoom",
  metaInfo: {
    title: "Video call",
  },
  mixins: [windowSize],
  data() {
    return {
      video_conference: process.env.VUE_APP_PUBLIC_CDN + "video_conference_new.png",
      username: this.$store.state.user_module.user.username,
      roomName: "",
      get_access: false,
      lessons: [],

      current_user_type: this.$store.state.user_module.user.user_type,
      other_user_type:
        this.$store.state.user_module.user.user_type === "student"
          ? "teacher"
          : "student",

      event_selected: false,
      Token: "",
      event: {},
      lesson: {},

      locales: {
        en: enGB,
        ru: ru,
      },

      loading: true,
      loadingLesson: true,
    };
  },
  components: {
    ZoomVideoChat,
    ImageItem,
    FullCalendarListView,
  },
  async mounted() {
    if (this.$route.params.lesson_id) {
      this.lesson._id = this.$route.params.lesson_id;
      await this.joinLesson();
    } else {
      this.loading = false;
      this.$Progress.finish();
    }
  },
  methods: {
    async joinLesson() {
      try {
        let res = await SharedDataService.getMeetingToken(this.lesson._id);
        this.lesson = res.data.lesson;
        this.Token = res.data.token;
        this.roomName = res.data.lesson.event.title;
        this.get_access = true;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
        this.$Progress.finish();
      }
    },
    formatDate(date) {
      if (date instanceof Date) {
        return format(date, "H:mm, MMM d, yyyy", {
          locale: this.locales[this.$i18n.locale],
        });
      } else {
        return format(parseISO(date), "H:mm, MMM d, yyyy", {
          locale: this.locales[this.$i18n.locale],
        });
      }
    },
    async showEventDetails(event) {
      this.loadingLesson = true;
      if (event.extendedProps.type === "lesson") {
        let res = await SharedDataService.getLessonDetails(event.extendedProps.lesson);
        this.lesson = res.data.lesson;
      }
      this.loadingLesson = false;
      this.event = event;
      this.event_selected = true;
      // if (this.windowWidth < 768) {
      this.$nextTick(function () {
        this.scrollToElement();
      });
      // }
    },
    scrollToElement() {
      const el = this.$refs.scrollToMe;

      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    getLessonParticipants() {
      let lesson = this.lesson;
      if (!this.lesson.participants) {
        return {};
      }
      let participant1 = lesson.participants[0];
      let participant2 = lesson.participants[1];
      let currentUser, otherUser, studentUser, teacherUser;

      if (participant1._id === this.$store.state.user_module.user._id) {
        currentUser = participant1;
        otherUser = participant2;
      } else if (participant2._id === this.$store.state.user_module.user._id) {
        currentUser = participant2;
        otherUser = participant1;
      }

      if (participant1.user_type === "student") {
        studentUser = participant1;
        teacherUser = participant2;
      } else if (participant2.user_type === "student") {
        studentUser = participant2;
        teacherUser = participant1;
      }

      return { currentUser, otherUser, studentUser, teacherUser };
    },
    imageHref(img) {
      return img ? API_ROUTE + img : "/img/no_photo_squared.png";
    },
  },
  computed: {
    joinTime() {
      let currentDate = new Date();
      let minTimeBuffer = sub(this.event.start, {
        minutes: 30,
      });
      let isBigger = compareAsc(currentDate, minTimeBuffer);
      let isSmaller = compareAsc(this.event.end, currentDate);

      return isBigger >= 0 && isSmaller >= 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/_button.scss";
@import "@/css/_text.scss";

.btn {
  @include blue-button;
  font-size: 12px;

  &--danger {
    @include red-button;
  }
}

.room {
  background-color: $commonBgColor;
  border-radius: $borderRadius;
  padding: $f7;
}

.image-container-mine {
  min-width: 128px;
}

.page__title {
  margin-top: $f5;
}
.session {
  &__title {
    font-size: 24px;
  }
  &__element {
    text-align: center;
    background-color: $commonBgColor;
    border-radius: $borderRadius;
    padding: $f6;
    margin-bottom: $f6;
  }
  &__image {
    max-width: 128px;
  }
}

.info {
  padding: $f6;
  margin-top: $f7;
  border: 1px solid $courseElementBgColorHover;

  background-color: $commonBgColor;
  border-radius: $borderRadius;

  &__group {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    padding: $f5;

    &--border {
      border-bottom: 1px solid $courseElementBgColorHover;
    }

    &--column {
      flex-direction: column;
      padding-top: $f7;
      padding-bottom: $f7;
    }
  }

  &__item {
    padding: $f2;
    margin: $f2;
  }

  &__order,
  &__price,
  &__total {
    font-weight: 500;
  }

  &__total,
  &__text--large {
    font-size: $fontSizeHeader;
  }

  &__order {
    font-size: $fontSizeLarge;
  }

  &__date {
    font-family: $fontFamilyCommon;
    font-weight: 600;
  }

  &__text {
    font-family: $fontFamilyCommon;

    &--small {
      font-size: $fontSizeMedium;
      font-family: Arial, Helvetica, sans-serif;
    }
  }

  &__teacher-img {
    width: 128px;
    height: 128px;
  }

  &__title {
    font-size: $fontSizeMedium;
  }
}

.pop-up {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  overflow-y: scroll;
  transition: opacity 2s ease-in-out;
  text-align: center;

  &__buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: $f5;
  }

  &__button {
    flex: 1;
  }

  &__close {
    align-self: flex-end;
    margin-bottom: auto;
    color: $buttonBgColor;
    border: $buttonBgColor;
    &:hover {
      color: $buttonBgColorHover;
      border: $buttonBgColorHover;
    }
    &:active {
      color: $buttonBgColorActive;
      border: $buttonBgColorActive;
    }
    &:hover {
      cursor: pointer;
    }
  }

  &__element {
    margin-top: $f4;
    text-align: center;
    &--header {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: row;
    }
    &--detail {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }

  &__content {
    border-radius: $borderRadius;
    padding: $lap;
    display: inline-block;
    background-color: $commonBgColor;
    margin-top: 20vh;
  }

  &__tab {
    display: flex;
    flex-direction: column;
    align-items: center;
    &--big {
      min-width: 800px;
    }
  }

  &__date {
    font-family: $fontFamilyCommon;
    font-weight: 600;
  }

  &__title {
    @include pop-up__title;
    margin: auto;
  }

  &__sub-title {
    font-size: $fontSizeMedium;
  }
}
</style>
