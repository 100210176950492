<template lang="html">
  <div>
    <div id="paypal-button-container"></div>
    <div class="message__container" ref="message_box">
      <MessageBox :message_type="message_type" :messages="messages" />
    </div>
  </div>
</template>
<script>
import { loadScript } from "@paypal/paypal-js";

import MessageBox from "@/components/common/MessageBox.vue";

import PaymentDataService from "@/services/PaymentDataService.js";

//import mixins
import handleServerMessage from "@/mixins/handleServerMessage.js";

export default {
  name: "PaypalButton",

  props: {
    product: {
      type: String,
      required: true,
    },
    quantity: {
      type: Number,
      default: 1,
    },
    details: {
      type: Object,
    },
  },

  components: {
    MessageBox,
  },
  mixins: [handleServerMessage],

  data() {
    return {
      paypal: "",
      messages: [],
      message_type: [],
    };
  },
  async mounted() {
    this.paypal = await loadScript({
      "client-id": process.env.VUE_APP_PAYPAL_CLIENT_ID,
      currency: "USD",
      // "enable-funding": "venmo",
      "disable-funding": "paylater,card",
      components: ["buttons", "messages"],
    });
    let vueThis = this;
    this.paypal
      .Buttons({
        style: {
          layout: "vertical",
          color: "gold",
          shape: "rect",
          label: "paypal",
        },
        async createOrder() {
          try {
            let teacher_id = vueThis.details.teacher_id;
            const res = await PaymentDataService.paypalCreateOrder({
              product: vueThis.product,
              teacher_id: teacher_id,
              quantity: vueThis.quantity,
            });

            const orderData = await res.data;

            if (orderData.id) {
              return orderData.id;
            } else {
              const errorDetail = orderData?.details?.[0];
              const errorMessage = errorDetail
                ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                : JSON.stringify(orderData);

              throw new Error(errorMessage);
            }
          } catch (err) {
            console.error(err);
            handleServerMessage(
              {
                message: `Could not initiate PayPal Checkout...${err}`,
                type: "error",
              },
              "err",
              5000
            );
          }
        },
        async onApprove(data) {
          try {
            let res;
            if (vueThis.product === "lesson") {
              res = await vueThis.$store.dispatch("user_module/purchaseLessons", {
                verificationToken: "",
                teacher_id: vueThis.details.teacher_id,
                quantity: vueThis.quantity,
                date: vueThis.details.date,
                type: "one_time_purchase",
                payment_method: "paypal",
                orderID: data.orderID,
              });
            }

            vueThis.handleServerMessage(res, "res", 3000);

            // const orderData = res.data;
            // // Three cases to handle:
            // //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
            // //   (2) Other non-recoverable errors -> Show a failure message
            // //   (3) Successful transaction -> Show confirmation or thank you message

            // const errorDetail = orderData?.details?.[0];

            // if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
            //   // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
            //   // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
            //   return actions.restart();
            // } else if (errorDetail) {
            //   // (2) Other non-recoverable errors -> Show a failure message
            //   throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
            // } else if (!orderData.purchase_units) {
            //   throw new Error(JSON.stringify(orderData));
            // } else {
            //   // (3) Successful transaction -> Show confirmation or thank you message
            //   // Or go to another URL:  actions.redirect('thank_you.html');
            //   const transaction =
            //     orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
            //     orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];
            //   vueThis.handleServerMessage(
            //     {
            //       message: `Transaction ${transaction.status}: ${transaction.id}See console for all available details`,
            //       type: "error",
            //     },
            //     "err",
            //     5000
            //   );
            //   console.log(
            //     "Capture result",
            //     orderData,
            //     JSON.stringify(orderData, null, 2)
            //   );
            // }
          } catch (err) {
            vueThis.handleServerMessage(err, "err", 5000);
          }
        },
      })
      .render("#paypal-button-container");
  },
};
</script>
<style lang="scss" scoped></style>
