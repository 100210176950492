//common
export const lap = "19px";

//body
export const bodyBgColor = "#f5f5f5";

//course grid
export const gridBgColor = "#3c7bff";
export const commonBgColor = "#d3d5f5";
//course elements
export const courseElementBgColor = "#adadff";
export const courseElementBgColorHover = "#8787ff";
export const courseElementBgColorDark = "#6d6df9";
export const paddingPercentage = "2.5%";
//buttons
export const buttonBgColor = "#405bef";
export const buttonBgColorHover = "#1b3df8";
export const buttonBgColorActive = "#0e2fe5";
export const buttonRedBgColor = "#f9431d";
export const buttonRedBgColorHover = "#ff1400";
export const buttonRedBgColorActive = "#ff1400";
export const buttonGreenBgColor = "limegreen";
export const buttonGreenBgColorHover = "green";
export const buttonGreenBgColorActive = "#ff1400";

//text colors
export const lightBlueHeader = "#1b65ff";
export const lightGreyHeader = "#8f8f8f";

export const borderRadius = "4px";
export const gridElementBorderRadius = "8px";
//image related
export const imageBg = "#adadff";
export const imageBorder = "#9999fb";

//code editor background color
export const codeEditorBgColor = "#2d2d2d";

//fibonacchi sequence
export const f0 = "1px";
export const f1 = "1px";
export const f2 = "2px";
export const f3 = "3px";
export const f4 = "5px";
export const f5 = "8px";
export const f6 = "13px";
export const f7 = "21px";
export const f8 = "34px";
export const f9 = "55px";

//common fonts
export const fontFamilyCommon = "Arial, Helvetica";

export const fontSizeSmall = "8px";
export const fontSizeMedium = "12px";

export const bspadding = "15px";

export const marginSize = "0px 0px 8px 0px";

export const school_subjects = [
  "3D design",
  "Accounting",
  "Acting skills",
  "Afrikaans",
  "Albanian",
  "Algebra",
  "Algorithms",
  "Amharic",
  "Ancient Greek",
  "Arabic",
  "Armenian",
  "Art classes",
  "Artificial Intelligence",
  "Arts",
  "Azerbaijani",
  "Bash",
  "Basque",
  "Belarusian",
  "Bengali",
  "Biology",
  "Bosnian",
  "Bulgarian",
  "Burmese",
  "Business Analytics",
  "Business Modelling",
  "Business Strategy",
  "Business",
  "C",
  "CSS",
  "Cantonese",
  "Catalan",
  "Cebuano",
  "Chemistry",
  "Chess",
  "Chinese(Mandarin)",
  "Computer science",
  "Content marketing",
  "Copywriting",
  "Corporate Finance",
  "Creole",
  "Croatian",
  "Czech",
  "Danish",
  "Data Science",
  "Databases",
  "Dutch",
  "Economics",
  "Email marketing",
  "English",
  "Esperanto",
  "Estonian",
  "Finnish",
  "Foreign Languages",
  "French",
  "Geography",
  "Geometry",
  "Georgian",
  "German",
  "Go language",
  "Graphic design",
  "Greek",
  "Gujarati",
  "HTML",
  "Hawaiian",
  "Hebrew",
  "Hindi",
  "History",
  "Hungarian",
  "IT Project",
  "Icelandic",
  "Igbo",
  "Indonesian",
  "Irish",
  "Italian",
  "Japanese",
  "Java",
  "JavaScript",
  "Kannada",
  "Kazakh",
  "Khmer",
  "Kinyarwanda",
  "Korean",
  "Kurdish",
  "Lao",
  "Latin",
  "Latvian",
  "Law",
  "Literature",
  "Lithuanian",
  "Luganda",
  "Luxembourgish",
  "Macedonian",
  "Malay",
  "Malayalam",
  "Maltese",
  "Maori",
  "Marathi",
  "Marketing Strategy",
  "Math",
  "Mongolian",
  "Motion design",
  "Music",
  "NodeJS",
  "Norwegian",
  "Objective C",
  "PHP",
  "PPC",
  "PR",
  "Pashto",
  "Persian(Farsi)",
  "Philosophy",
  "Photography",
  "Physics",
  "Polish",
  "Portuguese",
  "Product Management",
  "Psychology",
  "Public speaking",
  "Punjabi",
  "Python",
  "Quechua",
  "Quichua",
  "R",
  "Romanian",
  "Ruby",
  "Russian",
  "Rust",
  "SEO",
  "SMM",
  "Sales",
  "Sanskrit",
  "Scala",
  "Serbian",
  "Sign",
  "Sinhala",
  "Slovak",
  "Slovenian",
  "Sociology",
  "Somali",
  "Spanish",
  "Statistics",
  "Swahili",
  "Swedish",
  "Swift",
  "Tagalog",
  "Tamazight",
  "Tamil",
  "Telugu",
  "Tests",
  "Thai",
  "Tibetan",
  "Turkish",
  "UX / UI",
  "Ukrainian",
  "Urdu",
  "Uzbek",
  "Vietnamese",
  "Web Analytics",
  "Web Development",
  "Welsh",
  "Writing",
  "XML",
  "Xhosa",
  "Yiddish",
  "Yoruba",
  "iOS app development",
  "С#",
  "С++"
];
