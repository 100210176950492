export default {
  methods: {
    handleServerMessage(obj, type, time_to_live=3000) {
      if(type == 'res') {
        if (this.$t(obj.data.message)) {
          this.messages.unshift(this.$t(obj.data.message));
          this.message_type.unshift(obj.data.type);

        }
        else {
          this.messages.unshift(obj.data.message);
          this.message_type.unshift(obj.data.type);

        }
        // this.scrollToElement('message_box');
        setTimeout(() => {
          this.messages.pop();
          this.message_type.pop();
        }, time_to_live);
      }
      else if(type == 'err') {
        let count = 1;
        if(obj.response) {
          if (Array.isArray(obj.response.data.message)) {
            count = obj.response.data.message.length;
            for (let i = 0; i < obj.response.data.message.length; i++) {
              this.messages.unshift(this.$t(obj.response.data.message[i]) );
              this.message_type.unshift(obj.response.data.type);
            }
          }
          else {
            this.messages.unshift(obj.response.data.message);
            this.message_type.unshift(obj.response.data.type);
          }
        }
        else if(obj.message) {
          if (obj.message == "Network Error") {
            this.messages.unshift(this.$t("Too many requests. Please wait."));
          }
          else {
            this.messages.unshift(this.$t(obj.message) );
          }
          if (obj.type) {
            this.message_type.unshift(obj.type);
          }
          else {
            this.message_type.unshift('info');
          }
        }
        else {
          this.messages.unshift(this.$t('Unknown Error'));
        }
        // this.scrollToElement('message_box');
        setTimeout(() => {
          this.messages.splice(-count, count);
          this.message_type.splice(-count, count);
        }, 5000);
      }
    },
    handleServerMessageNotify(obj, type, time_to_live=3000, speed=2000) {
      if(type == 'res') {
        if (this.$t(obj.data.message)) {
          this.$notify({
            // (optional)
            // Name of the notification holder
            group: 'main',

            // (optional)
            // Title (will be wrapped in div.notification-title)
            title: "Success",

            // Content (will be wrapped in div.notification-content)
            text: this.$t(obj.data.message),

            // (optional)
            // Class that will be assigned to the notification
            type: obj.data.type,

            // (optional, override)
            // Time (in ms) to keep the notification on screen
            duration: time_to_live,

            // (optional, override)
            // Time (in ms) to show / hide notifications
            speed: speed,

            position: "bottom right",

            // (optional)
            // Data object that can be used in your template
            data: {}
          })

        }
        else {
          this.$notify({
            // (optional)
            // Name of the notification holder
            group: 'main',

            // (optional)
            // Title (will be wrapped in div.notification-title)
            title: "Success",

            // Content (will be wrapped in div.notification-content)
            text: this.$t(obj.data.message),

            // (optional)
            // Class that will be assigned to the notification
            type: obj.data.type,

            // (optional, override)
            // Time (in ms) to keep the notification on screen
            duration: time_to_live,

            // (optional, override)
            // Time (in ms) to show / hide notifications
            speed: speed,

            position: "bottom right",

            // (optional)
            // Data object that can be used in your template
            data: {}
          })

        }
      }
      else if(type == 'err') {
        if(obj.response) {
          if (Array.isArray(obj.response.data.message)) {
            for (let i = 0; i < obj.response.data.message.length; i++) {
              this.$notify({
                // (optional)
                // Name of the notification holder
                group: 'main',

                // (optional)
                // Title (will be wrapped in div.notification-title)
                title: "Error",

                // Content (will be wrapped in div.notification-content)
                text: this.$t(obj.response.data.message[i]),

                // (optional)
                // Class that will be assigned to the notification
                type: obj.response.data.type,

                // (optional, override)
                // Time (in ms) to keep the notification on screen
                duration: time_to_live,

                // (optional, override)
                // Time (in ms) to show / hide notifications
                speed: speed,

                position: "bottom right",

                // (optional)
                // Data object that can be used in your template
                data: {}
              })
            }
          }
          else {
            this.$notify({
              // (optional)
              // Name of the notification holder
              group: 'main',

              // (optional)
              // Title (will be wrapped in div.notification-title)
              title: "Error",

              // Content (will be wrapped in div.notification-content)
              text: obj.response.data.message,

              // (optional)
              // Class that will be assigned to the notification
              type: obj.response.data.type,

              // (optional, override)
              // Time (in ms) to keep the notification on screen
              duration: time_to_live,

              // (optional, override)
              // Time (in ms) to show / hide notifications
              speed: speed,

              position: "bottom right",

              // (optional)
              // Data object that can be used in your template
              data: {}
            })
          }
        }
        else if(obj.message) {
          if (obj.message == "Network Error") {
            this.$notify({
              // (optional)
              // Name of the notification holder
              group: 'main',

              // (optional)
              // Title (will be wrapped in div.notification-title)
              title: 'Warning',

              // Content (will be wrapped in div.notification-content)
              text: this.$t("Too many requests. Please wait."),

              // (optional)
              // Class that will be assigned to the notification
              type: 'warn',

              // (optional, override)
              // Time (in ms) to keep the notification on screen
              duration: time_to_live,

              // (optional, override)
              // Time (in ms) to show / hide notifications
              speed: speed,

              position: "bottom right",

              // (optional)
              // Data object that can be used in your template
              data: {}
            })
          }
          else {
            this.$notify({
              // (optional)
              // Name of the notification holder
              group: 'main',

              // (optional)
              // Title (will be wrapped in div.notification-title)
              title: 'Warning',

              // Content (will be wrapped in div.notification-content)
              text: this.$t(obj.message),

              // (optional)
              // Class that will be assigned to the notification
              type: 'warn',

              // (optional, override)
              // Time (in ms) to keep the notification on screen
              duration: time_to_live,

              // (optional, override)
              // Time (in ms) to show / hide notifications
              speed: speed,

              position: "bottom right",

              // (optional)
              // Data object that can be used in your template
              data: {}
            })
          }
        }
        else {
          this.$notify({
            // (optional)
            // Name of the notification holder
            group: 'main',

            // (optional)
            // Title (will be wrapped in div.notification-title)
            title: 'Warning',

            // Content (will be wrapped in div.notification-content)
            text: this.$t('Unknown Error'),

            // (optional)
            // Class that will be assigned to the notification
            type: 'warn',

            // (optional, override)
            // Time (in ms) to keep the notification on screen
            duration: time_to_live,

            // (optional, override)
            // Time (in ms) to show / hide notifications
            speed: speed,

            position: "bottom right",

            // (optional)
            // Data object that can be used in your template
            data: {}
          })
        }
      }
    },
  },
};
