<template lang="html">
  <div class="container-fluid px-0">
    <div class="chat-container">
      <transition name="chat" mode="in-out">
        <a
          class="video-icon-container"
          @click.prevent="show_mini_chat = true"
          v-show="!show_mini_chat"
          ><font-awesome-icon class="chat-icon" :icon="['fas', 'comment']"
        /></a>
      </transition>
      <transition name="chat" mode="out-in">
        <div class="chat" v-show="show_mini_chat">
          <a class="" @click.prevent="show_mini_chat = false" v-show="show_mini_chat">
            <font-awesome-icon
              class="chat-icon chat-icon--close color-changing-icon"
              :icon="['far', 'times-circle']"
            />
          </a>
          <Chat ref="chat_mini" :mini="true" />
        </div>
      </transition>
    </div>
    <div class="buttons-wrapper" v-if="show_connect_button">
      <button type="submit" @click="showRoom(room_name)" class="btn button-connect">
        Connect
      </button>
    </div>
    <div class="tracks--container">
      <div
        class="participant-connecting"
        v-show="!loading && !show_connect_button && !otherUser"
      >
        <div class="pop-up__element pop-up__element--detail">
          <div class="info__item info__teacher-img">
            <ImageItem :source="imageHref(participants.otherUser.user_type_id.img)" />
          </div>
          <div class="info__item info__teacher-details">
            <div class="detail detail__name">
              {{
                participants.otherUser.user_type_id.first_name +
                " " +
                participants.otherUser.user_type_id.second_name
              }}
            </div>
            <div class="detail">
              is not connected. <br />
              Please wait.
            </div>
          </div>
        </div>
      </div>
      <div class="participant-connecting" v-show="show_expired_meeting_tab">
        <div class="pop-up__element pop-up__element--detail">
          <!-- <div class="info__item info__teacher-img">
            <ImageItem :source="imageHref(participants.otherUser.user_type_id.img)" />
          </div> -->
          <div class="info__item info__teacher-details">
            <!-- <div class="detail detail__name">
              {{
                participants.otherUser.user_type_id.first_name +
                " " +
                participants.otherUser.user_type_id.second_name
              }}
            </div> -->
            <div class="detail">This lesson is over.</div>
          </div>
        </div>
      </div>
      <div
        class="participant-connecting"
        v-show="!loading && !show_connect_button && !showRemoteTrack && otherUser"
      >
        <div class="pop-up__element pop-up__element--detail">
          <div class="info__item info__teacher-img">
            <ImageItem :source="imageHref(participants.otherUser.user_type_id.img)" />
          </div>
          <div class="info__item info__teacher-details">
            <div class="detail detail__name">
              {{ participants.otherUser.user_type_id.first_name + "'s" }}
            </div>
            <div class="detail">camera is off.</div>
          </div>
        </div>
      </div>
      <div
        id="remoteTrack"
        v-show="this.otherUser && showRemoteTrack"
        class="remote-track--container"
      >
        <video
          autoplay=""
          playsinline=""
          id="myShareScreenVideo"
          v-show="current_main_screen === 'my_share_screen_video'"
        ></video>
        <canvas
          autoplay=""
          playsinline=""
          id="myShareScreenCanvas"
          v-show="current_main_screen === 'my_share_screen_canvas'"
        ></canvas>
        <canvas
          autoplay=""
          playsinline=""
          id="otherParticipantShareScreenCanvas"
          v-show="current_main_screen === 'other_participant_share_screen'"
        ></canvas>
        <canvas
          autoplay=""
          playsinline=""
          id="otherParticipantCameraCanvas"
          v-show="current_main_screen === 'other_participant_camera'"
        ></canvas>
      </div>
      <div
        id="remoteTrackMini"
        v-show="showRemoteTrackMini"
        class="remote-track-mini--container"
      >
        <canvas autoplay="" playsinline="" id="remoteTrackMiniCanvas"></canvas>
        <font-awesome-icon
          v-show="!otherUserAudio"
          class="video-icon video-icon--super"
          icon="microphone-slash"
        />
      </div>
      <div
        v-show="
          otherUser &&
          !showRemoteTrackMini &&
          current_main_screen != 'other_participant_camera'
        "
        class="remote-track-mini--container"
      >
        <div class="camera-off-mini">
          <div class="camera-off-mini__image-container">
            <ImageItem :source="imageHref(participants.otherUser.user_type_id.img)" />
            <font-awesome-icon
              v-show="!otherUserAudio"
              class="video-icon video-icon--overlay"
              icon="microphone-slash"
            />
          </div>
        </div>
      </div>
      <div id="localTrack" v-show="showLocalTrack" class="local-track--container">
        <video
          v-show="camera"
          autoplay=""
          playsinline=""
          id="localTrackVideo"
          width="160px"
          height="120px"
        ></video>
      </div>
    </div>
    <div class="video-chat-buttons">
      <div class="video-chat-buttons-group">
        <a
          v-if="this.microphone === true"
          href="#"
          class="video-icon-container"
          role="button"
          :disabled="loading"
          @click.prevent="muteAudio"
        >
          <font-awesome-icon class="video-icon" icon="microphone" />
        </a>
        <a
          v-if="this.microphone === false"
          href="#"
          class="video-icon-container"
          role="button"
          :disabled="loading"
          @click.prevent="unmuteAudio"
        >
          <font-awesome-icon class="video-icon" icon="microphone-slash" />
        </a>

        <a
          v-if="this.camera === true"
          href="#"
          class="video-icon-container"
          role="button"
          :disabled="loading"
          @click.prevent="muteVideo"
        >
          <font-awesome-icon class="video-icon" icon="video" />
        </a>
        <a
          v-if="this.camera === false"
          href="#"
          class="video-icon-container"
          role="button"
          :disabled="loading"
          @click.prevent="unmuteVideo"
        >
          <font-awesome-icon class="video-icon" icon="video-slash" />
        </a>
        <a
          href="#"
          class="video-icon-container"
          role="button"
          :disabled="loading"
          @click.prevent="showSettings"
        >
          <font-awesome-icon class="video-icon" icon="cog" />
        </a>
        <a
          v-if="this.share_screen === false"
          href="#"
          class="video-icon-container"
          role="button"
          :disabled="loading"
          @click.prevent="shareScreen"
        >
          <img
            class="video-icon color-changing-icon"
            src="@/assets/img/screen_share.svg"
          />
        </a>
        <a
          v-if="this.share_screen === true"
          href="#"
          class="video-icon-container"
          role="button"
          :disabled="loading"
          @click.prevent="stopShareScreen"
        >
          <img
            class="video-icon color-changing-icon"
            src="@/assets/img/stop_screen_share.svg"
          />
        </a>
      </div>
    </div>
    <transition name="video-settings">
      <div
        v-show="show_settings_dialog"
        class="video-settings-background"
        @click.self="hideSettings"
      >
        <div class="video-settings-body">
          <div>
            <div id="localPreviewTrack" class="local-track--container">
              <video
                autoplay=""
                playsinline=""
                id="localPreviewTrackVideo"
                width="160px"
                height="120px"
              ></video>
              <!-- <video
                autoplay=""
                playsinline=""
                id="localPreviewTrackVideo"
                width="160px"
                height="120px"
                :srcObject.prop="localPreviewTrack"
              ></video> -->
            </div>
          </div>
          <div class="video-settings-option">
            <div class="dropdown">
              <button
                class="btn btn-primary dropdown-toggle"
                type="button"
                data-toggle="dropdown"
              >
                Video Device <span class="caret"></span>
              </button>
              <div class="dropdown-option--selected" v-show="current_video_device">
                {{ current_video_device.label }}
              </div>
              <div class="dropdown-option--not-selected" v-show="!current_video_device">
                {{ localizedTextFct("exams", "select_target_grade") }}
              </div>
              <ul class="dropdown-menu">
                <li
                  v-for="video_device in video_devices"
                  :key="video_device.deviceId"
                  class="dropdown-option-container"
                  @click.prevent="changeVideoDevice(video_device.deviceId, video_device)"
                >
                  <a class="dropdown-option">{{ video_device.label }}</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="video-settings-option">
            <div class="dropdown">
              <button
                class="btn btn-primary dropdown-toggle"
                type="button"
                data-toggle="dropdown"
              >
                Microphone <span class="caret"></span>
              </button>
              <div class="dropdown-option--selected" v-show="current_audio_input_device">
                {{ current_audio_input_device.label }}
              </div>
              <div
                class="dropdown-option--not-selected"
                v-show="!current_audio_input_device"
              >
                {{ localizedTextFct("exams", "select_target_grade") }}
              </div>
              <ul class="dropdown-menu">
                <li
                  v-for="audio_input_device in audio_input_devices"
                  :key="audio_input_device.deviceId"
                  class="dropdown-option-container"
                  @click.prevent="
                    changeAudioInputDevice(
                      audio_input_device.deviceId,
                      audio_input_device
                    )
                  "
                >
                  <a class="dropdown-option">{{ audio_input_device.label }}</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="test-device__container">
            <div class="progress-bar">
              <span :style="{ width: microphone_progress + '%' }">{{
                Math.ceil(microphone_progress) + "%"
              }}</span>
            </div>
            <button class="test-button btn btn-primary" @click="testMicrophone">
              {{ microphone_test_text }}
            </button>
          </div>

          <div class="video-settings-option">
            <div class="dropdown">
              <button
                class="btn btn-primary dropdown-toggle"
                type="button"
                data-toggle="dropdown"
              >
                Speaker <span class="caret"></span>
              </button>
              <div class="dropdown-option--selected" v-show="current_audio_output_device">
                {{ current_audio_output_device.label }}
              </div>
              <div
                class="dropdown-option--not-selected"
                v-show="!current_audio_output_device"
              >
                {{ localizedTextFct("exams", "select_target_grade") }}
              </div>
              <ul class="dropdown-menu">
                <li
                  v-for="audio_output_device in audio_output_devices"
                  :key="audio_output_device.deviceId"
                  class="dropdown-option-container"
                  @click.prevent="
                    changeAudioOutputDevice(
                      audio_output_device.deviceId,
                      audio_output_device
                    )
                  "
                >
                  <a class="dropdown-option">{{ audio_output_device.label }}</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="test-device__container">
            <div class="progress-bar">
              <span :style="{ width: speaker_progress + '%' }">{{
                Math.ceil(speaker_progress) + "%"
              }}</span>
            </div>
            <button class="test-button btn btn-primary" @click="testSpeaker">
              {{ speaker_test_text }}
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import ZoomDataService from "@/services/ZoomDataService.js";

import ZoomVideo from "@zoom/videosdk";

import Chat from "@/components/common/Chat.vue";
import ImageItem from "@/components/common/ImageItem.vue";

const API_ROUTE = process.env.VUE_APP_API_URL + "img/";

export default {
  name: "ZoomVideoChat",
  components: {
    Chat,
    ImageItem,
  },
  data() {
    return {
      videoWidth: 320,
      videoHeight: 180,
      videoQuality: 4,

      loading: false,
      data: {},
      localTrack: false,
      remoteTrack: "",
      screenTrack: "",
      screenTrackType: "",
      activeRoom: "",
      previewTracks: "",
      identity: "",
      roomName: null,
      microphone: false,
      camera: false,
      share_screen: false,
      current_video_device: "",
      current_audio_input_device: "",
      current_audio_output_device: "",
      video_devices: [],
      audio_input_devices: [],
      audio_output_devices: [],
      show_settings_dialog: false,
      show_expired_meeting_tab: false,
      show_mini_chat: false,
      show_connect_button: true,
      localPreviewTrack: "",
      localVideoTrack: "",
      localAudioTrack: "",
      remoteVideoTrack: "",
      showRemoteTrack: false,
      showLocalTrack: false,
      showRemoteTrackMini: false,
      shareScreenOn: false,
      joined: false,

      current_main_screen: "other_participant_camera",
      useCanvasForRemote: false,

      zoomVideo: "",
      zoomSession: "",
      currentUser: "",
      currentUserIdentity: this.$store.state.user_module.user._id,
      otherUser: "",
      otherUserAudio: true,

      microphoneTester: "",
      microphone_progress: 0,
      microphone_test_status: 0,
      microphone_test_text: "Test Microphone",

      speaker_progress: 0,
      speaker_test_status: 0,
      speaker_test_text: "Test Speaker",

      stream: "",
    };
  },
  props: {
    token: {
      type: String,
      required: true,
    },
    room_name: {
      type: String,
      required: true,
    },
    lesson: {
      type: Object,
      required: true,
    },
    participants: {
      type: Object,
      required: true,
    },
  },
  async mounted() {
    try {
      if (
        !navigator.mediaDevices?.enumerateDevices ||
        !navigator.mediaDevices?.getUserMedia
      ) {
        console.log("enumerateDevices() not supported.");
      } else {
        // List cameras and microphones.
        // this.stream = await navigator.mediaDevices.getUserMedia({
        //   video: true,
        //   audio: true,
        // });
        await this.getConnectedDevices();
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.$refs.chat_mini.changeAddRoomUserId(this.participants.otherUser._id);
      this.$Progress.finish();
    }
  },
  beforeCreate() {
    if (this.zoomVideo) this.zoomVideo.leave();
    ZoomVideo.destroyClient();
    this.zoomVideo = "";
    this.zoomSession = "";
    this.joined = false;
    this.video_devices = [];
    this.show_connect_button = true;
  },
  methods: {
    async showSettings() {
      this.show_settings_dialog = true;
      if (!this.localPreviewTrack.isVideoStarted) {
        this.localPreviewTrack.start(document.querySelector("#localPreviewTrackVideo"));
      }

      if (!this.localAudioTrack.isAudioStarted) {
        this.localAudioTrack.start();
      }
    },
    async hideSettings() {
      this.show_settings_dialog = false;
      if (this.speakerTester) {
        this.speakerTester.destroy();
        this.speaker_test_status = "0";
        this.speaker_progress = 0;
        this.speaker_test_text = "Test Speaker";
      }

      if (this.microphoneTester) {
        this.microphone_progress = 0;
        this.microphoneTester.stop();
        this.microphone_test_status = "0";
        this.microphone_test_text = "Test Microphone";
      }
    },
    async getConnectedDevices() {
      try {
        let devices = await ZoomVideo.getDevices();
        devices.forEach((device) => {
          if (device.kind === "videoinput") {
            this.video_devices.push(device);
          } else if (device.kind === "audioinput") {
            this.audio_input_devices.push(device);
          } else if (device.kind === "audiooutput") {
            this.audio_output_devices.push(device);
          }
        });
        if (!this.current_video_device) {
          this.current_video_device = this.video_devices[0];
        }
        if (!this.current_audio_input_device) {
          this.current_audio_input_device = this.audio_input_devices[0];
        }
        if (!this.current_audio_output_device) {
          this.current_audio_output_device = this.audio_output_devices[0];
        }
        this.localPreviewTrack = ZoomVideo.createLocalVideoTrack(
          this.current_video_device.deviceId
        );
        this.localAudioTrack = ZoomVideo.createLocalAudioTrack(
          this.current_audio_input_device.deviceId
        );
      } catch (err) {
        console.error(`${err.name}: ${err.message}`);
      }
    },

    async createChat(sessionName) {
      try {
        this.loading = true;

        this.leaveRoomIfJoined();

        this.zoomVideo = ZoomVideo.createClient();

        await this.zoomVideo
          .init("en-US", "Global", { patchJsMedia: true })
          .catch((error) => {
            console.log(error);
          });
        await this.zoomVideo
          .join(
            sessionName,
            this.token,
            this.$store.state.user_module.user._id,
            "password"
          )
          .catch((error) => {
            console.log(
              error,
              error.reason ==
                "The token has expired, is over 2 days old, or is ineffective."
            );
            if (
              error.reason ==
              "The token has expired, is over 2 days old, or is ineffective."
            ) {
              this.show_expired_meeting_tab = true;
            }
          });

        this.zoomSession = this.zoomVideo.getMediaStream();

        // listen to events
        this.zoomVideo.on("peer-video-state-change", async (payload) => {
          try {
            console.log("peer-video-state-change", payload);
            if (payload.action === "Start") {
              let currentParticipant = await this.getParticipantDetails(true);
              let otherParticipant = await this.getParticipantDetails(false);
              if (currentParticipant.sharerOn || otherParticipant.sharerOn) {
                this.showRemoteTrackMini = true;
                this.zoomSession.renderVideo(
                  document.querySelector("#remoteTrackMiniCanvas"),
                  payload.userId,
                  this.videoWidth,
                  this.videoHeight,
                  0,
                  0,
                  this.videoQuality
                );
              } else {
                this.showRemoteTrack = true;
                this.current_main_screen = "other_participant_camera";
                this.zoomSession.renderVideo(
                  document.querySelector("#otherParticipantCameraCanvas"),
                  payload.userId,
                  this.videoWidth,
                  this.videoHeight,
                  0,
                  0,
                  this.videoQuality
                );
              }
              // a user turned on their video, render it
            } else if (payload.action === "Stop") {
              let currentParticipant = await this.getParticipantDetails(true);
              let otherParticipant = await this.getParticipantDetails(false);
              if (currentParticipant?.sharerOn || otherParticipant?.sharerOn) {
                this.showRemoteTrackMini = false;
              } else {
                this.showRemoteTrack = false;
              }
              // a user turned off their video, stop rendering it
              this.zoomSession.stopRenderVideo(
                document.querySelector("#otherParticipantCameraCanvas"),
                payload.userId
              );
            }
          } catch (error) {
            console.log(error);
          }
        });

        this.zoomVideo.on("user-added", (payload) => {
          // user joined
          console.log("user-added", payload);
          if (this.$store.state.user_module.user._id !== payload[0].userIdentity) {
            this.otherUser = payload[0];
          }
        });

        this.zoomVideo.on("user-updated", (payload) => {
          // user updated, like unmuting and muting
          console.log("user-updated", payload);
          if (this.otherUser.userId == payload[0].userId && "muted" in payload[0]) {
            this.otherUserAudio = !payload[0].muted;
            console.log(this.otherUserAudio);
          }
        });

        this.zoomVideo.on("user-removed", async (payload) => {
          try {
            // user left
            console.log("user-removed", payload);
            if (payload[0]?.sharerOn) {
              this.stopShareView(payload[0]);
            }
            if (payload[0]?.bVideoOn) {
              let currentParticipant = await this.getParticipantDetails(true);
              if (currentParticipant?.sharerOn) {
                this.showRemoteTrackMini = false;
                await this.zoomSession.stopRenderVideo(
                  document.querySelector("#remoteTrackMiniCanvas"),
                  payload[0].userId
                );
              } else {
                this.showRemoteTrack = false;
                // a user turned off their video, stop rendering it
                await this.zoomSession.stopRenderVideo(
                  document.querySelector("#otherParticipantCameraCanvas"),
                  payload[0].userId
                );
              }
            }
            if (this.otherUser?.userId === payload[0].userId) {
              this.otherUser = "";
            }
          } catch (error) {
            console.log(error);
          }
        });

        this.zoomVideo.on("connection-change", (payload) => {
          // session ended by host
          console.log("connection-change", payload);
        });

        this.zoomVideo.on("passively-stop-share", () => {
          this.stopShareScreen();
        });

        this.zoomVideo.on("active-share-change", (payload) => {
          console.log("active-share-change", payload);
          if (payload.state === "Active") {
            this.shareView(payload.userId);
          } else if (payload.state === "Inactive") {
            this.stopShareView();
          }
        });

        this.joined = true;

        this.zoomVideo.getAllUser().forEach(async (user) => {
          try {
            if (user.userIdentity === this.currentUserIdentity) {
              this.currentUser = user;
            } else {
              this.otherUser = user;
            }
            if (user.bVideoOn && !user.sharerOn) {
              this.showRemoteTrack = true;
              this.useCanvasForRemote = true;
              await this.zoomSession.renderVideo(
                document.querySelector("#otherParticipantCameraCanvas"),
                user.userId,
                this.videoWidth,
                this.videoHeight,
                0,
                0,
                this.videoQuality
              );
            } else if (user.bVideoOn && user.sharerOn) {
              this.showRemoteTrackMini = true;
              await this.zoomSession.renderVideo(
                document.querySelector("#remoteTrackMiniCanvas"),
                this.otherUser.userId,
                this.videoWidth,
                this.videoHeight,
                0,
                0,
                this.videoQuality
              );
            }
            if (user.sharerOn) {
              await this.shareView(user.userId);
            }
          } catch (error) {
            console.log(error);
          }
        });

        if (this.zoomSession.isRenderSelfViewWithVideoElement()) {
          this.showLocalTrack = true;
          await this.zoomSession.startVideo({
            cameraId: this.current_video_device.deviceId,
            videoElement: document.getElementById("localTrackVideo"),
            hd: true,
          });
          // self video started and rendered
        }
        // else {
        //   await this.zoomSession.startVideo()
        //   await this.zoomSession.renderVideo(document.getElementById('self-view-canvas'), this.zoomVideo.getCurrentUserInfo().userId, 1920, 1080, 0, 0, 3)
        //   // self video started and rendered
        // }
        await this.zoomSession
          .switchMicrophone(this.current_audio_input_device.deviceId)
          .catch((error) => {
            console.log(error);
          });
        await this.zoomSession.startAudio().catch((error) => {
          console.log(error);
        });
        if (!this.microphone) {
          this.muteAudio();
        }

        if (!this.camera) {
          this.muteVideo();
        }

        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },

    //Access token generation using username and room name
    async getAccessToken() {
      return await ZoomDataService.generateToken(this.username, this.room_name).catch(
        (error) => {
          console.log(error);
        }
      );
    },

    //Click connect button
    showRoom(room) {
      this.show_connect_button = false;
      this.room_name = room;
      this.startDate = new Date();

      this.createChat(this.room_name);

      window.addEventListener("beforeunload", this.leaveRoomIfJoined);

      if (this.isMobile) {
        // iOS Safari does not emit the "beforeunload" event on window.
        // Use "pagehide" instead.
        window.addEventListener("pagehide", this.leaveRoomIfJoined);
      }
    },

    // Leave Room.
    leaveRoomIfJoined() {
      if (this.joined) {
        ZoomVideo.destroyClient();
        this.zoomVideo.leave();
        this.zoomVideo = "";
        this.zoomSession = "";
        this.joined = false;
        this.video_devices = [];
        this.show_connect_button = true;
      }
    },

    async changeVideoDevice(deviceId, device) {
      this.current_video_device = device;
      await this.localPreviewTrack.stop().catch((error) => {
        console.log(error);
      });
      this.localPreviewTrack = ZoomVideo.createLocalVideoTrack(deviceId);
      this.localPreviewTrack.start(document.querySelector("#localPreviewTrackVideo"));
      if (this.joined)
        await this.zoomSession.switchCamera(deviceId).catch((error) => {
          console.log(error);
        });
    },

    async changeAudioInputDevice(deviceId, device) {
      this.current_audio_input_device = device;
      await this.localAudioTrack.stop().catch((error) => {
        console.log(error);
      });
      this.localAudioTrack = ZoomVideo.createLocalAudioTrack(deviceId);
      await this.localAudioTrack.start().catch((error) => {
        console.log(error);
      });
      if (this.joined)
        await this.zoomSession.switchMicrophone(deviceId).catch((error) => {
          console.log(error);
        });
      if (this.microphoneTester) {
        this.microphone_progress = 0;
        this.microphoneTester.stop();
        this.microphone_test_status = "0";
        this.microphone_test_text = "Test Microphone";
      }
    },

    async changeAudioOutputDevice(deviceId, device) {
      this.current_audio_output_device = device;

      if (this.speakerTester) {
        this.speakerTester.destroy();
        this.speaker_test_status = "0";
        this.speaker_progress = 0;
        this.speaker_test_text = "Test Speaker";
      }
    },

    testMicrophone() {
      const value = this.microphone_test_status;
      let vueThis = this;
      /**
       * 1 - start
       * 2 - recording
       * 3 - playing recording
       * 0|undefined - init
       */
      if (value === "1" || value === "3") {
        if (vueThis.microphoneTester) {
          vueThis.microphoneTester.stop();
          vueThis.microphone_test_status = "0";
          vueThis.microphone_test_text = "Test Microphone";
          if (value === "1") {
            vueThis.microphone_progress = 0;
          }
        }
      } else if (!value || value === "0") {
        vueThis.microphoneTester = vueThis.localAudioTrack.testMicrophone({
          microphoneId: vueThis.current_audio_input_device.deviceId,
          speakerId: vueThis.current_audio_output_device.deviceId,
          onAnalyseFrequency: (v) => {
            vueThis.microphone_progress = v;
          },
          recordAndPlay: true,
          onStartRecording: () => {
            vueThis.microphone_test_text = "Recording";
            vueThis.microphone_test_status = "2";
          },
          onStartPlayRecording: () => {
            vueThis.microphone_test_text = "Playing";
            vueThis.microphone_test_status = "3";
          },
          onStopPlayRecording: () => {
            vueThis.microphone_test_text = "Stop test";
            vueThis.microphone_test_status = "1";
          },
        });
        vueThis.microphone_test_status = "1";
        vueThis.microphone_test_text = "Stop test";
      } else if (value === "2") {
        vueThis.microphoneTester.stopRecording();
      }
    },

    testSpeaker() {
      const value = this.speaker_test_status;
      let vueThis = this;

      if (value === "1") {
        if (vueThis.speakerTester) {
          vueThis.speakerTester.destroy();
          vueThis.speaker_test_status = "0";
          vueThis.speaker_progress = 0;
          vueThis.speaker_test_text = "Test Speaker";
        }
      } else {
        vueThis.speakerTester = vueThis.localAudioTrack.testSpeaker({
          speakerId: vueThis.current_audio_output_device.deviceId,
          onAnalyseFrequency: (v) => {
            vueThis.speaker_progress = v;
          },
        });
        vueThis.speaker_test_status = "1";
        vueThis.speaker_test_text = "Stop test";
      }
    },

    //mute video
    muteVideo() {
      if (this.zoomSession) {
        this.zoomSession.stopVideo();
      }
      this.camera = false;
    },

    //unmute video
    async unmuteVideo() {
      if (this.zoomSession) {
        await this.zoomSession
          .startVideo({
            videoElement: document.getElementById("localTrackVideo"),
            hd: true,
          })
          .catch((error) => {
            console.log(error);
          });
      }
      this.camera = true;
    },

    //mute audio of video chat
    muteAudio() {
      if (this.zoomSession) {
        this.zoomSession.muteAudio();
      }
      this.microphone = false;
    },

    //unmute audio of video chat
    unmuteAudio() {
      if (this.zoomSession) {
        this.zoomSession.unmuteAudio();
      }
      this.microphone = true;
    },

    async shareScreen() {
      try {
        let currentParticipant = await this.getParticipantDetails(true);
        let otherParticipant = await this.getParticipantDetails(false);
        if (currentParticipant.sharerOn || otherParticipant.sharerOn) {
          return console.log("you can't share screen over others");
        }

        this.share_screen = true;

        if (this.zoomSession.isStartShareScreenWithVideoElement()) {
          this.current_main_screen = "my_share_screen_video";
          await this.zoomSession.startShareScreen(
            document.querySelector("#myShareScreenVideo"),
            {
              hideShareAudioOption: false,
              optimizedForSharedVideo: true,

              controls: {
                selfBrowserSurface: "include",
                systemAudio: "exclude",
              },
            }
          );
        } else {
          this.current_main_screen = "my_share_screen_canvas";
          await this.zoomSession.startShareScreen(
            document.querySelector("#myShareScreenCanvas"),
            {
              hideShareAudioOption: false,
              optimizedForSharedVideo: true,
              controls: {
                selfBrowserSurface: "include",
                systemAudio: "exclude",
              },
            }
          );
        }

        if (this.showRemoteTrack) {
          this.showRemoteTrackMini = true;
          await this.zoomSession.stopRenderVideo(
            document.querySelector("#otherParticipantCameraCanvas"),
            this.otherUser.userId
          );
          await this.zoomSession.renderVideo(
            document.querySelector("#remoteTrackMiniCanvas"),
            this.otherUser.userId,
            this.videoWidth,
            this.videoHeight,
            0,
            0,
            this.videoQuality
          );
        } else {
          this.showRemoteTrack = true;
        }
      } catch (error) {
        console.log(error);
        if (error.reason === "user deny screen share") {
          this.share_screen = false;

          this.current_main_screen = "other_participant_camera";
        }
      }
    },

    async shareView(userId) {
      try {
        let currentParticipant = await this.getParticipantDetails();
        let otherParticipant = await this.getParticipantDetails(false);
        if (currentParticipant.sharerOn || this.share_screen) {
          return console.log("participant's can't share screen over others");
        }

        if (otherParticipant.bVideoOn) {
          this.showRemoteTrackMini = true;
          await this.zoomSession
            .stopRenderVideo(
              document.querySelector("#otherParticipantCameraCanvas"),
              this.otherUser.userId
            )
            .catch((error) => {
              console.log(error);
            });
          await this.zoomSession
            .renderVideo(
              document.querySelector("#remoteTrackMiniCanvas"),
              this.otherUser.userId,
              this.videoWidth,
              this.videoHeight,
              0,
              0,
              this.videoQuality
            )
            .catch((error) => {
              console.log(error);
            });
        }

        this.showRemoteTrack = true;
        this.current_main_screen = "other_participant_share_screen";

        this.zoomSession.startShareView(
          document.querySelector("#otherParticipantShareScreenCanvas"),
          userId
        );
      } catch (error) {
        console.log(error);
      }
    },

    async stopShareScreen() {
      let currentParticipant = await this.getParticipantDetails();
      let otherParticipant = await this.getParticipantDetails(false);
      if (!this.share_screen || !currentParticipant.sharerOn) {
        return console.log("The screen share is not on");
      }

      this.share_screen = false;
      await this.zoomSession.stopShareScreen().catch((error) => {
        console.log(error);
      });

      if (otherParticipant?.bVideoOn) {
        if (this.showRemoteTrackMini) {
          await this.zoomSession
            .stopRenderVideo(
              document.querySelector("#remoteTrackMiniCanvas"),
              otherParticipant.userId
            )
            .catch((error) => {
              console.log(error);
            });
          await this.zoomSession
            .stopRenderVideo(
              document.querySelector("#myShareScreenCanvas"),
              this.otherUser.userId
            )
            .catch((error) => {
              console.log(error);
            });
        }

        await this.zoomSession
          .renderVideo(
            document.querySelector("#otherParticipantCameraCanvas"),
            otherParticipant.userId,
            this.videoWidth,
            this.videoHeight,
            0,
            0,
            this.videoQuality
          )
          .catch((error) => {
            console.log(error);
          });
        this.showRemoteTrack = true;
        this.current_main_screen = "other_participant_camera";
      }
      this.showRemoteTrackMini = false;
    },

    async stopShareView(user = null) {
      let otherParticipant;
      if (user) {
        otherParticipant = user;
      } else {
        otherParticipant = await this.getParticipantDetails(false);
      }
      await this.zoomSession.stopShareView().catch((error) => {
        console.log(error);
      });

      if (otherParticipant.bVideoOn) {
        if (this.showRemoteTrackMini) {
          await this.zoomSession
            .stopRenderVideo(
              document.querySelector("#remoteTrackMiniCanvas"),
              otherParticipant.userId
            )
            .catch((error) => {
              console.log(error);
            });
          await this.zoomSession
            .stopRenderVideo(
              document.querySelector("#otherParticipantShareScreenCanvas"),
              otherParticipant.userId
            )
            .catch((error) => {
              console.log(error);
            });
          this.showRemoteTrackMini = false;
        }
        this.showRemoteTrack = true;
        this.current_main_screen = "other_participant_camera";
        await this.zoomSession
          .renderVideo(
            document.querySelector("#otherParticipantCameraCanvas"),
            otherParticipant.userId,
            this.videoWidth,
            this.videoHeight,
            0,
            0,
            this.videoQuality
          )
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.showRemoteTrack = false;
        this.current_main_screen = "other_participant_camera";
      }
    },

    async getParticipantDetails(local = true) {
      let participants = await this.zoomVideo.getAllUser();
      if (local) {
        return participants.find((p) => p.userIdentity === this.currentUserIdentity);
      } else {
        return participants.find((p) => p.userIdentity !== this.currentUserIdentity);
      }
    },
    imageHref(img) {
      return img ? API_ROUTE + img : "/img/no_photo_squared.png";
    },
  },
  beforeDestroy() {
    if (this.localPreviewTrack) this.localPreviewTrack.stop();
    if (this.localAudioTrack) this.localAudioTrack.stop();
    ZoomVideo.destroyClient();
    this.leaveRoomIfJoined();

    if (this.speakerTester) {
      this.speakerTester.destroy();
      this.speaker_test_status = "0";
      this.speaker_progress = 0;
      this.speaker_test_text = "Test Speaker";
    }

    if (this.microphoneTester) {
      this.microphone_progress = 0;
      this.microphoneTester.stop();
      this.microphone_test_status = "0";
      this.microphone_test_text = "Test Microphone";
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/css/variables.scss";
@import "@/css/_button.scss";
@import "@/css/_text.scss";

.chat {
  transition: opacity 2s ease-in-out;
  width: 400px;
  right: 10px;
  position: absolute;
  z-index: 5;

  &-container {
    position: relative;
    height: 0;
  }

  &-icon {
    &--close {
      z-index: 11;
    }

    position: absolute;
    right: 15px;
    top: 15px;
    width: 24px;
    height: 24px;
    z-index: 3;

    &:hover {
      cursor: pointer;
    }
  }
}

.btn {
  @include blue-button;
  font-size: 12px;

  &--danger {
    @include red-button;
  }
}

.buttons-wrapper {
  display: flex;
  justify-content: center;
}

.button {
  &-connect {
    position: absolute;
    top: 50%;
  }

  &-leave {
    position: absolute;
    left: 15px;
    top: 105px;
    z-index: 4;
  }
}

.video-icon {
  width: 100%;
  height: 100%;

  &--blue {
    color: blue;
  }

  &--overlay {
    width: 16px;
    height: 16px;
    color: darkred;
    position: absolute;
    right: $f4;
    bottom: $f4;
  }

  &--super {
    width: 16px;
    height: 16px;
    color: darkred;
    position: absolute;
    left: calc(15px + 2 * 160px + $lap - 16px - $f4);
    bottom: calc(15px + $f4);
    z-index: 3;
  }

  &-container {
    width: 24px;
    height: 24px;
    margin: 0px 19px;
  }
}

#localTrack :is(video, canvas) {
  width: 160px !important;
  object-fit: cover;
  background-repeat: no-repeat;
  height: 120px;
  left: 15px;
  bottom: 15px;
  position: absolute;
  border-radius: $gridElementBorderRadius;
  z-index: 2;
}

#remoteTrack {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: calc(100vh - 90px);
  background-color: black;
}

#remoteTrack video {
  width: 100% !important;
  //object-fit: cover;
  //background-repeat: no-repeat;
  height: calc(100vh - 90px);
  z-index: 2;
}

#remoteTrack canvas {
  width: 100% !important;
  //max-height: 100%;
  height: calc(100vh - 90px);
  object-fit: contain;
  overflow-clip-margin: content-box;
  overflow: clip;
  aspect-ratio: 16/9;
  z-index: 2;
}

#remoteTrackMini :is(video, canvas) {
  width: 160px !important;
  object-fit: cover;
  background-repeat: no-repeat;
  height: 120px;
  left: calc(15px + 160px + $lap);
  bottom: 15px;
  position: absolute;
  border-radius: $gridElementBorderRadius;
  z-index: 2;
}

.camera-off-mini {
  width: 160px !important;
  object-fit: cover;
  background-repeat: no-repeat;
  height: 120px;
  left: calc(15px + 160px + $lap);
  bottom: 15px;
  position: absolute;
  border-radius: $gridElementBorderRadius;
  z-index: 2;
  background-color: $commonBgColor;
  display: flex;
  flex-direction: row;
  justify-content: center;

  &__image-container {
    height: 120px;
    width: 120px;
  }
}

#localPreviewTrack :is(video, canvas) {
  width: 100% !important;
  object-fit: cover;
  background-repeat: no-repeat;
  height: 330px;
  border-radius: $gridElementBorderRadius;
}

#remoteTrackMini {
  width: 0px;
  height: 0px;
}

.spacing {
  padding: 20px;
  width: 100%;
}

.video-chat-buttons {
  &-group {
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  position: absolute;
  width: 100%;
  bottom: 15px;
  left: 0px;
  display: flex;
  justify-content: center;
  z-index: 3;
}

.video-chat-container {
  background-color: lightgray;
  height: auto;
}

//transitions
.video-settings {
  &-option {
    text-align: center;
  }

  &-body {
    background: white;
    width: 480px;
    margin-top: 90px;
    padding: $lap;
    margin-left: auto;
    margin-right: auto;
    border-radius: $borderRadius;
  }

  &-background {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    padding: 5% 10%;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
    overflow-y: scroll;
    transition: opacity 2s ease-in-out;
  }
}

.test-button {
  width: 32%;
}

.test-device__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.progress-bar {
  background-color: whiteSmoke;
  border-radius: 2px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25) inset;

  width: 64%;
  height: 100%;

  position: relative;
  display: block;
}

.progress-bar > span {
  background-color: blue;
  border-radius: 2px;

  display: block;
  text-indent: -9999px;
}

.dropdown {
  &-title {
    font-size: 24px;
  }

  &-group {
    display: flex;
    flex-direction: row;
  }

  &-container {
    margin-bottom: 8px;
    width: 50%;
    padding: 8px;
  }

  &-menu {
    width: 100%;
  }

  &-toggle {
    width: 100%;
    display: flex;
    position: relative;
    justify-content: space-between;
    margin: 4px 0px;

    &::after {
      right: 8px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &-option {
    color: black;
    text-decoration: none;

    &--not-selected {
      opacity: 0.5;
    }

    &-container {
      cursor: pointer;

      &:hover {
        background: $courseElementBgColorHover;
      }

      padding: 4px;
    }
  }

  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 0;
  margin-right: 2%;
}

.participant-connecting {
  height: calc(100vh - 90px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pop-up {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  overflow-y: scroll;
  transition: opacity 2s ease-in-out;
  text-align: center;

  &__buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: $f5;
  }

  &__button {
    flex: 1;
  }

  &__close {
    align-self: flex-end;
    margin-bottom: auto;
    color: $buttonBgColor;
    border: $buttonBgColor;

    &:hover {
      color: $buttonBgColorHover;
      border: $buttonBgColorHover;
    }

    &:active {
      color: $buttonBgColorActive;
      border: $buttonBgColorActive;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__element {
    margin-top: $f4;
    text-align: center;

    &--header {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: row;
    }

    &--detail {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }

  &__content {
    border-radius: $borderRadius;
    padding: $lap;
    display: inline-block;
    background-color: $commonBgColor;
    margin-top: 20vh;
  }

  &__tab {
    display: flex;
    flex-direction: column;
    align-items: center;

    &--big {
      min-width: 800px;
    }
  }

  &__date {
    font-family: $fontFamilyCommon;
    font-weight: 600;
  }

  &__title {
    @include pop-up__title;
    margin: auto;
  }

  &__sub-title {
    font-size: $fontSizeMedium;
  }
}

.info {
  padding: $f6;
  margin-top: $f7;
  border: 1px solid $courseElementBgColorHover;

  background-color: $commonBgColor;
  border-radius: $borderRadius;

  &__group {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    padding: $f5;

    &--border {
      border-bottom: 1px solid $courseElementBgColorHover;
    }

    &--column {
      flex-direction: column;
      padding-top: $f7;
      padding-bottom: $f7;
    }
  }

  &__item {
    padding: $f2;
    margin: $f2;
  }

  &__order,
  &__price,
  &__total {
    font-weight: 500;
  }

  &__total,
  &__text--large {
    font-size: $fontSizeHeader;
  }

  &__order {
    font-size: $fontSizeLarge;
  }

  &__date {
    font-family: $fontFamilyCommon;
    font-weight: 600;
  }

  &__text {
    font-family: $fontFamilyCommon;

    &--small {
      font-size: $fontSizeMedium;
      font-family: Arial, Helvetica, sans-serif;
    }
  }

  &__teacher-img {
    width: 128px;
    height: 128px;
  }

  &__title {
    font-size: $fontSizeMedium;
  }
}

.center-h {
  display: flex;
  /* or display:grid */
  justify-content: center;
}

.center-v {
  display: flex;
  align-items: center;
}

.video-settings-enter-active,
.video-settings-leave-active {
  transition: opacity 0.5s;
}

.video-settings-enter,
.video-settings-leave-to {
  opacity: 0;
}

.chat-enter-active,
.chat-leave-active {
  transition: opacity 0.5s;
}

.chat-enter,
.chat-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@media screen and (max-width: 1200px) {
  //#remoteTrack :is(video, canvas) {
  // height: calc(100dvh - 63px);
  //}
  #remoteTrack {
    height: calc(100dvh - 63px);
  }

  .tracks--container {
    height: calc(100dvh - 63px);
  }

  .participant-connecting {
    height: calc(100dvh - 63px);
  }

  .remote-track-mini--container {
    height: calc(100dvh - 63px);
  }

  #localPreviewTrack :is(video, canvas) {
    width: 100% !important;
    object-fit: cover;
    background-repeat: no-repeat;
    height: 140px;
    border-radius: $gridElementBorderRadius;
  }

  .video-settings {
    &-body {
      width: 90%;
    }
  }
}

@media screen and (max-width: 576px) {
  .chat {
    width: 100%;
    right: 0%;
    left: 0%;
  }
}
</style>
