<template lang="html">
  <div class="container-fluid">
    <div class="row">
      <CoursePanelLeft
        :img="course.img"
        :sections="course.sections"
        :title="course.title"
        :course_index="course.course_index"
        :course_id="course.id"
        :section_index="current_index"
        @section-selected="changeSelectedSection"
        ref="CoursePanelLeft"
      />
      <CoursePanelCentral
        :section="current_section"
        :course_id="course.id"
        :enable_click="enable_click"
        @section-element-selected="handleSelectedSection"
        @navigate="navigateTo"
      />
      <CoursePanelRight
        :no_additional_courses="no_additional_courses"
        :related_courses="course.related_courses"
        :preliminary_courses="course.preliminary_courses"
        :future_courses="course.future_courses"
      />
    </div>
  </div>
</template>

<script>
import Course from "@/models/course";
import Section from "@/models/section";

import SharedDataService from "@/services/SharedDataService.js";

import CoursePanelLeft from "@/components/common/CoursePanelLeft";
import CoursePanelCentral from "@/components/common/CoursePanelCentral";
import CoursePanelRight from "@/components/common/CoursePanelRight";

export default {
  name: "Course",
  metaInfo() {
    return {
      title: this.course.title ? this.course.title : "Course",
    };
  },
  components: {
    CoursePanelLeft,
    CoursePanelCentral,
    CoursePanelRight,
  },
  props: {
    course_id: {
      type: String,
      default: "",
    },
    enable_click: {
      type: Boolean,
      default: false,
    },
    no_additional_courses: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      course: new Course("", "", [], -1),
      current_section: new Section(),

      current_index: null,
    };
  },
  methods: {
    scrollToTop() {
      if (this.enable_click) {
        return this.$emit("scroll-popup-to-top");
      } else {
        window.scrollTo(0, 0);
      }
    },
    handleSelectedSection(section_element_id) {
      this.$emit(
        "section-element-selected",
        section_element_id,
        this.course.id,
        this.course.author
      );
    },
    navigateTo(direction) {
      if (Number.isInteger(this.current_index)) {
        let index = 0;
        if (direction == "previous") {
          if (this.current_index == 0) {
            index = this.course.sections.length - 1;
          } else {
            index = this.current_index - 1;
          }
          this.$refs.CoursePanelLeft.selectSection(index);
          this.scrollToTop();
        } else if (direction == "next") {
          if (this.current_index == this.course.sections.length - 1) {
            index = 0;
          } else {
            index = this.current_index + 1;
          }
          this.$refs.CoursePanelLeft.selectSection(index);
          this.scrollToTop();
        }
      }
    },
    getCourse(id = null) {
      let course_id;
      if (id) {
        course_id = id;
      } else if (this.$route.params.id) {
        course_id = this.$route.params.id;
      } else if (this.course_id) {
        course_id = this.course_id;
      } else {
        return;
      }
      SharedDataService.getCourse(course_id)
        .then((res) => {
          this.course = res.data;
          if (this.course.sections.length && this.$refs.CoursePanelLeft) {
            this.$refs.CoursePanelLeft.selectSection(0);
            this.current_index = 0;
          }
          this.$Progress.finish();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeSelectedSection(e) {
      this.current_index = e;
      this.current_section = this.course.sections[this.current_index];
    },
  },
  created() {
    this.getCourse();
  },
  beforeRouteUpdate(to, from, next) {
    this.current_section = null;
    this.current_index = null;
    this.getCourse(to.params.id);
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.current_section = null;
    this.current_index = null;
    this.getCourse(to.params.id);
    next();
  },
};
</script>

<style lang="scss" scoped>
$lap: 19px;
.row {
  margin-top: $lap;
}
</style>
