<template lang="html">
  <div class="container-fluid">
    <form @submit.prevent="updateMyProfile" method="post" class="dropdown-container">
      <div class="dropdown-title">Update Profile Info</div>
      <div class="form-group">
        <div class="dropdown-group">
          <div class="dropdown">
            <button
              class="btn btn-primary dropdown-toggle"
              type="button"
              data-toggle="dropdown"
            >
              Choose the subject you teach <span class="caret"></span>
            </button>
            <div class="dropdown-option--selected" v-show="description.subjects.length">
              {{ description.subjects[0] }}
            </div>
            <div
              class="dropdown-option--not-selected"
              v-show="!description.subjects.length"
            >
              Subject
            </div>
            <ul class="dropdown-menu">
              <li
                v-for="(school_subject, index) in school_subjects"
                :key="index"
                class="dropdown-option-container"
                @click.prevent="
                  $store.commit('user_module/updateValue', {
                    field: 'description',
                    sub_field: 'subjects',
                    new_value: [school_subject],
                  })
                "
              >
                <a class="dropdown-option">{{ school_subject }}</a>
              </li>
              <li
                class="dropdown-option-container"
                @click.prevent="
                  $store.commit('user_module/updateValue', {
                    field: 'description',
                    sub_field: 'subjects',
                    new_value: ['Other'],
                  })
                "
              >
                <a class="dropdown-option">Other</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="title">
          Please enter a title for your profile(this will be visible to your potential
          students)</label
        >
        <input
          class="form-control"
          type="text"
          name="title"
          id="title"
          autocomplete="title"
          required
          :value="description.title"
          @input="
            $store.commit('user_module/updateValue', {
              field: 'description',
              sub_field: 'title',
              new_value: $event.target.value,
            })
          "
          v-validate="'required'"
        />
      </div>
      <div class="form-group">
        <div>
          <label for="price"> Please enter your hourly rate</label>
          <input
            type="number"
            max="100"
            min="5"
            step="1"
            placeholder="Price"
            name="price"
            id="price"
            class="form-control"
            autocomplete="price"
            required
            :value="settings.tutoring_hourly_rate"
            @input="
              $store.commit('user_module/updateValue', {
                field: 'settings',
                sub_field: 'tutoring_hourly_rate',
                new_value: $event.target.value,
              })
            "
            v-validate="'required|between:5,100|decimal:0'"
          />
        </div>
        <div v-if="errors.has('price')" class="alert alert-danger" role="alert">
          Please enter a valid price between $5 and $100. Max number of digits after
          decimal point is 2. Ex: 5.55
        </div>
      </div>
      <div class="form-group">
        <label class="" for="about_me">About Me(400 characters minimum)</label>
        <textarea
          name="about_me"
          id="about_me"
          rows="6"
          :value="description.about_me"
          v-validate="'required|min:400'"
          @input="
            $store.commit('user_module/updateValue', {
              field: 'description',
              sub_field: 'about_me',
              new_value: $event.target.value,
            })
          "
        ></textarea>
        <div
          v-if="submitted && errors.has('about_me')"
          class="alert alert-danger"
          role="alert"
        >
          The about me field must contain at least 400 characters
        </div>
      </div>
      <div class="form-group">
        <label class="" for="experience">Experience(400 characters minimum)</label>
        <textarea
          name="experience"
          id="experience"
          rows="6"
          v-validate="'required|min:400'"
          :value="description.experience"
          @input="
            $store.commit('user_module/updateValue', {
              field: 'description',
              sub_field: 'experience',
              new_value: $event.target.value,
            })
          "
        ></textarea>
        <div
          v-if="submitted && errors.has('experience')"
          class="alert alert-danger"
          role="alert"
        >
          The experince field must contain at least 400 characters
        </div>
      </div>
      <div class="form-group">
        <UploadVideo
          :url="video_introduction.video_name"
          :presigned_url="video_introduction.video"
          :poster="video_introduction.poster_name"
          :presigned_img_href="video_introduction.poster"
          @input="
            $store.commit('user_module/updateValue', {
              field: 'video_introduction',
              sub_field: arguments[0],
              new_value: arguments[1],
            })
          "
          @start_uploading="loading = true"
          @finish_uploading="loading = false"
        />
        <Panel
          v-show="video_introduction.status == 'under_review'"
          text="Thank you for submitting your information. Please allow us up to 2-3 bussiness days to review it."
          type="info"
        />
        <Panel
          v-show="video_introduction.status == 'verified'"
          text="This video has been approved."
          type="success"
        />
        <Panel
          v-show="video_introduction.status == 'rejected'"
          text="This video has been rejected. Please consider to make the neccessary changes before resubmitting it."
          type="danger"
        />
        <div class="m-2" v-show="video_introduction.review_comment">
          <b>Review comment</b>
        </div>
        <Panel
          v-if="
            video_introduction.review_comment && video_introduction.status == 'verified'
          "
          :text="video_introduction.review_comment"
          type="info"
        />
        <Panel
          v-if="
            video_introduction.review_comment && video_introduction.status == 'rejected'
          "
          :text="video_introduction.review_comment"
          type="info"
        />
      </div>
      <div class="education-background--container">
        <div class="row">
          <div
            class="education-background col-md-6 my-2 text-center"
            v-for="(element, i) in this.educational_background"
            :key="'educational_background' + i"
          >
            <FormResume
              title="Education"
              type="educational_background"
              :formDataFields="educationalBackgroundFormFields"
              :formData="element"
              :form_index="i"
              @input="
                $store.commit('user_module/updateValue', {
                  field: 'educational_background',
                  index: i,
                  sub_field: arguments[0],
                  new_value: arguments[1],
                })
              "
            />
          </div>
          <div
            class="education-background col-md-6 my-2 text-center"
            v-for="(element, i) in this.proffessional_experience"
            :key="'proffessional_experience' + i"
          >
            <FormResume
              title="Professional Experience"
              type="proffessional_experience"
              :formDataFields="proffessionalExperienceFormFields"
              :formData="element"
              :form_index="i"
              @input="
                $store.commit('user_module/updateValue', {
                  field: 'proffessional_experience',
                  index: i,
                  sub_field: arguments[0],
                  new_value: arguments[1],
                })
              "
            />
          </div>
          <div
            class="education-background col-md-6 my-2 text-center"
            v-for="(element, i) in this.certificates"
            :key="'certificates' + i"
          >
            <FormResume
              title="Teaching Certificates"
              type="certificates"
              :formDataFields="certificatesFormFields"
              :formData="element"
              :form_index="i"
              @input="
                $store.commit('user_module/updateValue', {
                  field: 'certificates',
                  index: i,
                  sub_field: arguments[0],
                  new_value: arguments[1],
                })
              "
            />
          </div>
        </div>
        <div class="add-button--container">
          <button
            type="button"
            class="add-button btn btn-primary"
            @click="addForm('educational_background')"
          >
            <font-awesome-icon
              :icon="['fas', 'graduation-cap']"
              class="btn-icon--left"
            />Add Diploma
          </button>
          <button
            type="button"
            class="add-button btn btn-primary"
            @click="addForm('proffessional_experience')"
          >
            <font-awesome-icon :icon="['fas', 'briefcase']" class="btn-icon--left" />Add
            proffessional Experience
          </button>
          <button
            type="button"
            class="add-button btn btn-primary"
            @click="addForm('certificates')"
          >
            <font-awesome-icon :icon="['fas', 'certificate']" class="btn-icon--left" />Add
            a Teaching Certificate
          </button>
        </div>
      </div>

      <button
        type="submit"
        class="btn btn-primary update-profile"
        :disabled="loading"
        id="update"
      >
        {{ this.localizedTextFct("buttons", "update_profile_info") }}
      </button>
      <MessageBox :messages="messages" :message_type="message_type" />
    </form>
    <v-tour
      name="HomepageTour"
      :steps="steps"
      :options="tour_options"
      :callbacks="tour_callbacks"
    >
    </v-tour>
  </div>
</template>
<script>
//import components
import MessageBox from "@/components/common/MessageBox.vue";
import FormResume from "@/components/common/FormResume.vue";
import UploadVideo from "@/components/common/UploadVideo.vue";

import Panel from "@/components/common/Panel.vue";

//import mixins
import handleServerMessage from "@/mixins/handleServerMessage.js";

import { mapState } from "vuex";

import { generateArrayOfYears } from "@/utils/dates";
import { school_subjects } from "@/utils/variables";

// import TeacherDataService from "@/services/TeacherDataService";

export default {
  name: "MyProfile",
  metaInfo: {
    title: "My Profile",
  },
  mixins: [handleServerMessage],
  components: {
    MessageBox,
    FormResume,
    UploadVideo,
    Panel,
  },
  computed: mapState("user_module", {
    // arrow functions can make the code very succinct!
    under_review: (state) => state.user_type_info.under_review.under_review,
    description: (state) => state.user_type_info.under_review.description,
    video_introduction: (state) => state.user_type_info.under_review.video_introduction,
    educational_background: (state) =>
      state.user_type_info.under_review.educational_background,
    certificates: (state) => state.user_type_info.under_review.certificates,
    proffessional_experience: (state) =>
      state.user_type_info.under_review.proffessional_experience,
    settings: (state) => state.user_type_info.under_review.settings,
    // thumbnail: (state) => state.user_type_info.thumbnail,
    tour_options() {
      return {
        useKeyboardNavigation: true,
        labels: {
          buttonSkip: this.localizedTextFct("tours", "skip_tour"),
          buttonPrevious: this.localizedTextFct("tours", "previous"),
          buttonNext: this.localizedTextFct("tours", "next"),
          buttonStop: this.localizedTextFct("tours", "finish"),
        },
      };
    },
    steps() {
      return [
        // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
        {
          target: '[data-tour-step="1"]',
          header: {
            title: this.localizedTextFct("tours", "get_started"),
          },
          content: this.localizedTextFct("tours", "welcome"),
          params: {
            highlight: true,
            placement: "bottom",
            arrow: {
              enabled: false,
            },
          },
        },
        {
          target: '[data-tour-step="2"]',
          header: {
            title: this.localizedTextFct("tours", "update_photo"),
          },
          content: this.localizedTextFct("tours", "update_photo_details"),
          params: {
            highlight: true,
            placement: "bottom",
          },
        },
        {
          target: '[data-tour-step="3"]',
          header: {
            title: this.localizedTextFct("tours", "update_first_name"),
          },
          content: this.localizedTextFct("tours", "update_first_name_details"),
          params: {
            highlight: true,
            placement: "bottom",
          },
        },
        {
          target: '[data-tour-step="4"]',

          header: {
            title: this.localizedTextFct("tours", "update_second_name"),
          },
          content: this.localizedTextFct("tours", "update_second_name_details"),
          params: {
            highlight: true,
            placement: "bottom",
          },
        },
        {
          target: '[data-tour-step="1"]',
          header: {
            title: this.localizedTextFct("tours", "gret_job"),
          },
          content: this.localizedTextFct("tours", "gret_job_details"),
          params: {
            highlight: true,
            placement: "bottom",
          },
        },
      ];
    },
  }),
  data() {
    return {
      url: "",
      presigned_url: "",
      presigned_img_href: "",
      img_href: "",
      loading: false,
      submitted: false,
      messages: [],
      message_type: [],
      choosedFile: {},
      tour_callbacks: {
        onSkip: this.SkipTour,
        // onStop: this.StopTour,
        onFinish: this.FinishTour,
      },
      school_subjects: school_subjects,
      educationalBackgroundFormFields: [
        {
          type: "text",
          name: "Institution Name",
          label: "institution_name",
          field_name: "institution_name",
          required: true,
        },
        {
          type: "text",
          name: "Degree",
          label: "degree",
          field_name: "degree",
          required: true,
        },
        {
          type: "dropdown",
          name: "Start Date",
          label: "dropdown_start",
          field_name: "start",
          options: generateArrayOfYears(50),
          placeholder: "Select start date",
          choose: "Please choose your education start date",
        },
        {
          type: "dropdown",
          name: "End Date",
          field_name: "end",
          label: "dropdown_end",
          options: generateArrayOfYears(50),
          placeholder: "Select end date",
          choose: "Please choose your education end date",
        },
        {
          type: "upload",
          name: "diploma",
          field_name: "diploma",
          accepted_file_types: ".jpg, .jpeg, .png, .pdf",
          label: "diploma",
        },
      ],
      proffessionalExperienceFormFields: [
        {
          type: "text",
          name: "Company Name",
          label: "company",
          field_name: "company",
          required: true,
        },
        {
          type: "text",
          name: "Occupation",
          label: "occupation",
          field_name: "occupation",
          required: true,
        },
        {
          type: "textarea",
          name: "Description",
          label: "description",
          field_name: "description",
          required: true,
        },
        {
          type: "dropdown",
          name: "Start Date",
          label: "dropdown_start",
          field_name: "start",
          options: generateArrayOfYears(50),
          placeholder: "Select start date",
          choose: "Please choose start date",
        },
        {
          type: "dropdown",
          name: "End Date",
          field_name: "end",
          label: "dropdown_end",
          options: generateArrayOfYears(50),
          placeholder: "Select end date",
          choose: "Please choose end date",
        },
        {
          type: "upload",
          name: "certificate",
          field_name: "certificate",
          accepted_file_types: ".jpg, .jpeg, .png, .pdf",
          label: "certificate",
        },
      ],
      certificatesFormFields: [
        {
          type: "text",
          name: "Issued By",
          label: "issued_by",
          field_name: "issued_by",
          required: true,
        },
        {
          type: "text",
          name: "Subject",
          label: "subject",
          field_name: "subject",
          required: true,
        },
        {
          type: "textarea",
          name: "Description",
          label: "description",
          field_name: "description",
          required: true,
        },
        {
          type: "dropdown",
          name: "Start Date",
          label: "dropdown_start",
          field_name: "start",
          options: generateArrayOfYears(50),
          placeholder: "Select start date",
          choose: "Please choose start date",
        },
        {
          type: "dropdown",
          name: "End Date",
          field_name: "end",
          label: "dropdown_end",
          options: generateArrayOfYears(50),
          placeholder: "Select end date",
          choose: "Please choose end date",
        },
        {
          type: "upload",
          name: "certificate",
          field_name: "certificate",
          accepted_file_types: ".jpg, .jpeg, .png, .pdf",
          label: "certificate",
        },
      ],
    };
  },
  methods: {
    SkipTour(currentStep) {
      this.$store.dispatch("user_module/updateTour", {
        tour_name: "myprofile",
        status: "skipped",
        last_step: currentStep,
      });
    },
    StopTour(currentStep) {
      this.$store.dispatch("user_module/updateTour", {
        tour_name: "myprofile",
        status: "skipped",
        last_step: currentStep,
      });
    },
    FinishTour(currentStep) {
      this.$store.dispatch("user_module/updateTour", {
        tour_name: "myprofile",
        status: "finished",
        last_step: currentStep,
      });
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    updateMyProfile() {
      let self = this;
      self.submitted = true;
      self.$validator.validate().then(function (isValid) {
        if (isValid) {
          if (
            self.$store.state.user_module.user_type_info.under_review.description.subjects
              .length == 0
          ) {
            self.scrollToTop();
            return self.handleServerMessageNotify(
              {
                response: {
                  data: {
                    message: "Please select the subject you teach",
                    type: "error",
                  },
                },
              },
              "err",
              5000
            );
          }
          if (self.$store.state.user_module.user_type_info.first_name == "Unknown") {
            return self.handleServerMessageNotify(
              {
                response: {
                  data: {
                    message: "Please update your first name in homepage",
                    type: "error",
                  },
                },
              },
              "err",
              5000
            );
          }
          if (self.$store.state.user_module.user_type_info.second_name == "Unknown") {
            return self.handleServerMessageNotify(
              {
                response: {
                  data: {
                    message: "Please update your second name in homepage",
                    type: "error",
                  },
                },
              },
              "err",
              5000
            );
          }

          if (!self.$store.state.user_module.user_type_info.img) {
            return self.handleServerMessageNotify(
              {
                response: {
                  data: {
                    message: "Please update your profile picture in homepage",
                    type: "error",
                  },
                },
              },
              "err",
              5000
            );
          }

          self.loading = true;

          self.status = "submitting";
          self.$store
            .dispatch(
              "user_module/updateMyProfile",
              self.$store.state.user_module.user_type_info.under_review
            )
            .then((res) => {
              self.handleServerMessageNotify(res, "res", 3000);
              self.loading = false;
            })
            .catch((err) => {
              self.handleServerMessageNotify(err, "err", 5000);
              self.loading = false;
            });
        } else {
          self.scrollToTop();
        }
      });
    },
    async addForm(type) {
      let newForm = {};
      if (type == "educational_background") {
        newForm = {
          institution_name: "",
          degree: "",
          start: "",
          end: "",
          status: "not_reviewed",
        };
      } else if (type == "proffessional_experience") {
        newForm = {
          company: "",
          occupation: "",
          description: "",
          start: "",
          end: "",
          status: "not_reviewed",
        };
      } else if (type == "certificates") {
        newForm = {
          subject: "",
          issued_by: "",
          description: "",
          start: "",
          end: "",
          status: "not_reviewed",
        };
      } else {
        return this.handleServerMessageNotify(
          {
            type: "error",
            message: "Invalid form type",
          },
          "err",
          5000
        );
      }
      if (this.$store.state.user_module.user_type_info.under_review[type].length < 5) {
        await this.$store.commit("user_module/addForm", { type: type, newForm: newForm });
      } else {
        return this.handleServerMessageNotify(
          {
            type: "error",
            message: "Maxinum number of forms is 5",
          },
          "err",
          5000
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/css/variables.scss";
@import "@/css/_button.scss";

.update-profile {
  width: 100%;
}

.add-button {
  width: 30%;
  &--container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.education-background {
  &--container {
    text-align: center;
  }
}

textarea {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  resize: vertical;
  padding: 0.5rem;
  border-color: white;
  background-color: white;
  border-radius: 0.2rem;
  border-width: 2px;
  outline: none;
  appearance: none;
  display: flex;
}

.btn {
  @include blue-button;
  font-size: 12px;
  position: relative;
  margin-bottom: 2 * $lap;
  &--danger {
    @include red-button;
  }
  &--primary {
    margin-right: 2%;
  }
  &-icon {
    &-container {
      vertical-align: middle;
    }
    height: 16px;
    &--left {
      position: absolute;
      left: 4px;
      top: 50%;
      transform: translateY(-50%);
    }
    &--right {
      position: absolute;
      right: 4px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.btn-selected {
  @include red-button;

  &--green {
    @include green-button;
    box-shadow: none;
  }

  box-shadow: none;
}

.dropdown {
  &-title {
    font-size: 24px;
  }

  &-container {
    margin-bottom: 8px;
    width: 100%;
    padding: 8px;
  }

  &-menu {
    width: 100%;
    overflow-y: scroll;
    height: 200px;
  }

  &-toggle {
    width: 100%;
    display: flex;
    position: relative;
    justify-content: space-between;
    margin: 4px 0px;

    &::after {
      right: 8px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &-option {
    color: black;
    text-decoration: none;

    &--not-selected {
      opacity: 0.5;
    }

    &-container {
      cursor: pointer;

      &:hover {
        background: $courseElementBgColorHover;
      }

      padding: 4px;
    }
  }

  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 0;
  margin-right: 2%;
}
</style>
