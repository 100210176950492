<template>
  <div v-lazyload :class="imageClass('image__wrapper')">
    <div class="image__spinner">
      <div :class="imageClass('loader')"></div>
    </div>
    <img class="image__item" :data-url="source" alt="random image" />
  </div>
</template>

<script>
export default {
  name: "ImageItem",
  components: {},
  props: {
    source: {
      type: String,
      required: true,
    },
    aspectRatio: {
      type: Number,
      default: 1,
      validator: function (value) {
        if (value === 1 || value === 9 / 16) {
          return true;
        } else {
          return false;
        }
      },
    },
  },
  methods: {
    imageClass(className) {
      if (this.aspectRatio == 1) {
        return `${className} ${className}-1-1`;
      } else if (this.aspectRatio == 9 / 16) {
        return `${className} ${className}-16-9`;
      } else {
        return `${className} ${className}-1-1`;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@use "sass:math";

.image {
  &__wrapper {
    position: relative;
    &-1-1 {
      padding-bottom: 100%;
    }
    &-16-9 {
      padding-bottom: math.div(9, 16) * 100%;
    }
    align-items: center;
    border-radius: 4px;

    &.loaded {
      .image {
        &__item {
          visibility: visible;
          opacity: 1;
          border: 0;
        }

        &__spinner {
          display: none;
        }
      }
    }
  }

  &__item {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    transition: all 0.4s ease-in-out;
    opacity: 0;
    visibility: hidden;
  }

  &__spinner {
    display: inline-block;
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
  }
}
.loader {
  border: 5px solid darkgrey;
  border-radius: 50%;
  border-top: 5px solid black;
  position: absolute;
  &-16-9 {
    top: math.div(100% - math.div(16, 9) * 20%, 2);
    left: 40%;
    width: 20%;
    height: math.div(16, 9) * 20%;
  }
  &-1-1 {
    top: 25%;
    left: 25%;
    width: 50%;
    height: 50%;
  }
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
