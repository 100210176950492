var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('form',{staticClass:"dropdown-container",attrs:{"method":"post"},on:{"submit":function($event){$event.preventDefault();return _vm.updateMyProfile.apply(null, arguments)}}},[_c('div',{staticClass:"dropdown-title"},[_vm._v("Update Profile Info")]),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"dropdown-group"},[_c('div',{staticClass:"dropdown"},[_vm._m(0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.description.subjects.length),expression:"description.subjects.length"}],staticClass:"dropdown-option--selected"},[_vm._v(" "+_vm._s(_vm.description.subjects[0])+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.description.subjects.length),expression:"!description.subjects.length"}],staticClass:"dropdown-option--not-selected"},[_vm._v(" Subject ")]),_c('ul',{staticClass:"dropdown-menu"},[_vm._l((_vm.school_subjects),function(school_subject,index){return _c('li',{key:index,staticClass:"dropdown-option-container",on:{"click":function($event){$event.preventDefault();return _vm.$store.commit('user_module/updateValue', {
                  field: 'description',
                  sub_field: 'subjects',
                  new_value: [school_subject],
                })}}},[_c('a',{staticClass:"dropdown-option"},[_vm._v(_vm._s(school_subject))])])}),_c('li',{staticClass:"dropdown-option-container",on:{"click":function($event){$event.preventDefault();return _vm.$store.commit('user_module/updateValue', {
                  field: 'description',
                  sub_field: 'subjects',
                  new_value: ['Other'],
                })}}},[_c('a',{staticClass:"dropdown-option"},[_vm._v("Other")])])],2)])])]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"title"}},[_vm._v(" Please enter a title for your profile(this will be visible to your potential students)")]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control",attrs:{"type":"text","name":"title","id":"title","autocomplete":"title","required":""},domProps:{"value":_vm.description.title},on:{"input":function($event){return _vm.$store.commit('user_module/updateValue', {
            field: 'description',
            sub_field: 'title',
            new_value: $event.target.value,
          })}}})]),_c('div',{staticClass:"form-group"},[_c('div',[_c('label',{attrs:{"for":"price"}},[_vm._v(" Please enter your hourly rate")]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required|between:5,100|decimal:0'),expression:"'required|between:5,100|decimal:0'"}],staticClass:"form-control",attrs:{"type":"number","max":"100","min":"5","step":"1","placeholder":"Price","name":"price","id":"price","autocomplete":"price","required":""},domProps:{"value":_vm.settings.tutoring_hourly_rate},on:{"input":function($event){return _vm.$store.commit('user_module/updateValue', {
              field: 'settings',
              sub_field: 'tutoring_hourly_rate',
              new_value: $event.target.value,
            })}}})]),(_vm.errors.has('price'))?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._v(" Please enter a valid price between $5 and $100. Max number of digits after decimal point is 2. Ex: 5.55 ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"about_me"}},[_vm._v("About Me(400 characters minimum)")]),_c('textarea',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:400'),expression:"'required|min:400'"}],attrs:{"name":"about_me","id":"about_me","rows":"6"},domProps:{"value":_vm.description.about_me},on:{"input":function($event){return _vm.$store.commit('user_module/updateValue', {
            field: 'description',
            sub_field: 'about_me',
            new_value: $event.target.value,
          })}}}),(_vm.submitted && _vm.errors.has('about_me'))?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._v(" The about me field must contain at least 400 characters ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"experience"}},[_vm._v("Experience(400 characters minimum)")]),_c('textarea',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:400'),expression:"'required|min:400'"}],attrs:{"name":"experience","id":"experience","rows":"6"},domProps:{"value":_vm.description.experience},on:{"input":function($event){return _vm.$store.commit('user_module/updateValue', {
            field: 'description',
            sub_field: 'experience',
            new_value: $event.target.value,
          })}}}),(_vm.submitted && _vm.errors.has('experience'))?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._v(" The experince field must contain at least 400 characters ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('UploadVideo',{attrs:{"url":_vm.video_introduction.video_name,"presigned_url":_vm.video_introduction.video,"poster":_vm.video_introduction.poster_name,"presigned_img_href":_vm.video_introduction.poster},on:{"input":function($event){return _vm.$store.commit('user_module/updateValue', {
            field: 'video_introduction',
            sub_field: arguments[0],
            new_value: arguments[1],
          })},"start_uploading":function($event){_vm.loading = true},"finish_uploading":function($event){_vm.loading = false}}}),_c('Panel',{directives:[{name:"show",rawName:"v-show",value:(_vm.video_introduction.status == 'under_review'),expression:"video_introduction.status == 'under_review'"}],attrs:{"text":"Thank you for submitting your information. Please allow us up to 2-3 bussiness days to review it.","type":"info"}}),_c('Panel',{directives:[{name:"show",rawName:"v-show",value:(_vm.video_introduction.status == 'verified'),expression:"video_introduction.status == 'verified'"}],attrs:{"text":"This video has been approved.","type":"success"}}),_c('Panel',{directives:[{name:"show",rawName:"v-show",value:(_vm.video_introduction.status == 'rejected'),expression:"video_introduction.status == 'rejected'"}],attrs:{"text":"This video has been rejected. Please consider to make the neccessary changes before resubmitting it.","type":"danger"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.video_introduction.review_comment),expression:"video_introduction.review_comment"}],staticClass:"m-2"},[_c('b',[_vm._v("Review comment")])]),(
          _vm.video_introduction.review_comment && _vm.video_introduction.status == 'verified'
        )?_c('Panel',{attrs:{"text":_vm.video_introduction.review_comment,"type":"info"}}):_vm._e(),(
          _vm.video_introduction.review_comment && _vm.video_introduction.status == 'rejected'
        )?_c('Panel',{attrs:{"text":_vm.video_introduction.review_comment,"type":"info"}}):_vm._e()],1),_c('div',{staticClass:"education-background--container"},[_c('div',{staticClass:"row"},[_vm._l((this.educational_background),function(element,i){return _c('div',{key:'educational_background' + i,staticClass:"education-background col-md-6 my-2 text-center"},[_c('FormResume',{attrs:{"title":"Education","type":"educational_background","formDataFields":_vm.educationalBackgroundFormFields,"formData":element,"form_index":i},on:{"input":function($event){return _vm.$store.commit('user_module/updateValue', {
                field: 'educational_background',
                index: i,
                sub_field: arguments[0],
                new_value: arguments[1],
              })}}})],1)}),_vm._l((this.proffessional_experience),function(element,i){return _c('div',{key:'proffessional_experience' + i,staticClass:"education-background col-md-6 my-2 text-center"},[_c('FormResume',{attrs:{"title":"Professional Experience","type":"proffessional_experience","formDataFields":_vm.proffessionalExperienceFormFields,"formData":element,"form_index":i},on:{"input":function($event){return _vm.$store.commit('user_module/updateValue', {
                field: 'proffessional_experience',
                index: i,
                sub_field: arguments[0],
                new_value: arguments[1],
              })}}})],1)}),_vm._l((this.certificates),function(element,i){return _c('div',{key:'certificates' + i,staticClass:"education-background col-md-6 my-2 text-center"},[_c('FormResume',{attrs:{"title":"Teaching Certificates","type":"certificates","formDataFields":_vm.certificatesFormFields,"formData":element,"form_index":i},on:{"input":function($event){return _vm.$store.commit('user_module/updateValue', {
                field: 'certificates',
                index: i,
                sub_field: arguments[0],
                new_value: arguments[1],
              })}}})],1)})],2),_c('div',{staticClass:"add-button--container"},[_c('button',{staticClass:"add-button btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.addForm('educational_background')}}},[_c('font-awesome-icon',{staticClass:"btn-icon--left",attrs:{"icon":['fas', 'graduation-cap']}}),_vm._v("Add Diploma ")],1),_c('button',{staticClass:"add-button btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.addForm('proffessional_experience')}}},[_c('font-awesome-icon',{staticClass:"btn-icon--left",attrs:{"icon":['fas', 'briefcase']}}),_vm._v("Add proffessional Experience ")],1),_c('button',{staticClass:"add-button btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.addForm('certificates')}}},[_c('font-awesome-icon',{staticClass:"btn-icon--left",attrs:{"icon":['fas', 'certificate']}}),_vm._v("Add a Teaching Certificate ")],1)])]),_c('button',{staticClass:"btn btn-primary update-profile",attrs:{"type":"submit","disabled":_vm.loading,"id":"update"}},[_vm._v(" "+_vm._s(this.localizedTextFct("buttons", "update_profile_info"))+" ")]),_c('MessageBox',{attrs:{"messages":_vm.messages,"message_type":_vm.message_type}})],1),_c('v-tour',{attrs:{"name":"HomepageTour","steps":_vm.steps,"options":_vm.tour_options,"callbacks":_vm.tour_callbacks}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn btn-primary dropdown-toggle",attrs:{"type":"button","data-toggle":"dropdown"}},[_vm._v(" Choose the subject you teach "),_c('span',{staticClass:"caret"})])
}]

export { render, staticRenderFns }