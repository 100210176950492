<template lang="html">
  <div>
    <div class="player-container" v-if="section_element.url">
      <video-player :src="section_element.url" :poster="section_element.img_href"/>
    </div>
  </div>
</template>

<script>
import VideoPlayer from "@/components/common/VideoPlayer.vue";

export default {
	name: "SectionElementVideoUploaded",
	components: {
		VideoPlayer
	},
  props: {
    section_element: {
      type: Object,
      required: true,
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
