import authHeader from './auth-header';

import {httpNoCache} from '@/services/httpSetup';

class ChatService {

  addRoom(obj) {
    return httpNoCache.post('chat/' + 'add_room',
    {
      users: obj.users,
      lastUpdated: obj.lastUpdated,
      socket_id: obj.socket_id,
    });
  }

  getRooms(startAfter) {
    return httpNoCache.post('chat/' + 'get_rooms',
    {
      startAfter: startAfter,
    });
  }

  updateRoom(roomId, field, value, opts={}) {
    return httpNoCache.put('chat/' + 'update_room',
    {
      room_id: roomId,
      field: field,
      value: value,
      ...opts,
    });
  }

  deleteRoom(roomId) {
    return httpNoCache.delete('chat/' + 'delete_room',
    {
      params: {
        room_id: roomId,
      }
    });
  }

  getUser(id) {
    return httpNoCache.get('chat/' + 'get_user/' + id);
  }

  getMessages(roomId) {
    return httpNoCache.get('chat/' + 'get_messages/' + roomId);
  }

  addMessage(roomId, message) {
    return httpNoCache.put('chat/' + 'add_message/' + roomId,
    {
      message: message
    });
  }


  sendMessage(roomId, messageId) {
    return httpNoCache.post('chat/' + 'send_message',
      {
        room_id: roomId,
        message_id: messageId,
      });
  }

  updateMessage(roomId, messageId, field, value, opts = {}) {
    return httpNoCache.put('chat/' + 'update_message',
    {
      room_id: roomId,
      message_id: messageId,
      field: field,
      value: value,
      ...opts,
    });
  }

  deleteMessage(roomId, messageId) {
    return httpNoCache.put('chat/' + 'delete_message',
    {
      room_id: roomId,
      message_id: messageId,
    });
  }

  getSignedFormUrl(roomId, filename, type) {
    return httpNoCache.post('chat/signed_form_url',
      {
        room_id: roomId,
        filename: filename,
        mimetype: type,
      }
    );
  }

  uploadFile(form_data, roomId, userId, messageId) {
    return httpNoCache.put('chat/' + 'upload_file/' + roomId + '/' + userId + '/' + messageId,
     form_data,
     {
       headers: {
       'accept': 'application/json',
       'Accept-Language': 'en-US,en;q=0.8',
       'Content-Type': 'multipart/form-data',
       ... authHeader()
      }
    });
  }
}

export default new ChatService();
