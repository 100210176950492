<template lang="html">
  <div>
    {{ localizedTextFct("sections", "video") + ":" }}
    <a target="_blank" href="https://www.youtube.com/watch?v=SlnXyladeBg">Example</a>
    <div class="input-group mb-3">
      <div class="custom-file">
        <input
          ref="input"
          type="file"
          name="video"
          accept="video/*"
          @change="onFileSelected($event, 'video')"
          class="custom-file-input"
          :id="'inputGroupFile01'"
          :required="!(choosedFile['video'] || presigned_url)"
        />
        <label
          :style="cssVars"
          class="custom-file-label"
          id="custom-file-label-1"
          :for="'inputGroupFile01'"
        >
          {{
            choosedFile["video"]
              ? choosedFile["video"]
              : url
              ? decodeURIComponent(url.replace(allCDN, "")).split("?")[0]
              : localizedTextFct("sections", "choose_file")
          }}
        </label>
      </div>
      <!-- <div class="input-group-append">
        <span
          class="input-group-text"
          @click="handleSubmit('video')"
          :class="loadingURL ? 'disabled' : ''"
          >{{ localizedTextFct("buttons", "upload") }}</span
        >
      </div> -->
    </div>
    {{ localizedTextFct("sections", "thumbnail") + ":" }}
    <div class="input-group mb-3">
      <div class="custom-file">
        <input
          ref="input"
          :content="localizedTextFct('buttons', 'upload')"
          type="file"
          name="image"
          accept="image/*"
          @change="onFileSelected($event, 'poster')"
          class="custom-file-input"
          :id="'inputGroupFile02'"
          :required="!(choosedFile['poster'] || presigned_img_href)"
        />
        <label :style="cssVars" class="custom-file-label" :for="'inputGroupFile02'">{{
          choosedFile["poster"]
            ? choosedFile["poster"]
            : poster
            ? poster.replace(cdnURL, "")
            : localizedTextFct("sections", "choose_image")
        }}</label>
      </div>
      <!-- <div class="input-group-append">
        <span
          class="input-group-text"
          @click="handleSubmit('poster')"
          :class="loadingURL ? 'disabled' : ''"
          >{{ localizedTextFct("buttons", "upload") }}</span
        >
      </div> -->
    </div>
    <div class="upload-result" v-show="uploading">
      <div
        class="upload-result__progress"
        :style="{ width: upload_progress + '%' }"
      ></div>
    </div>
    <div class="player-container" v-if="presigned_url">
      <video-player :poster="presigned_img_href" :src="presigned_url" />
    </div>
    <MessageBox :messages="messages" :message_type="message_type" />
  </div>
</template>

<script>
//import components
import MessageBox from "@/components/common/MessageBox.vue";

//import services
import SharedDataService from "@/services/SharedDataService.js";
import S3DataService from "@/services/S3DataService.js";
import VideoPlayer from "@/components/common/VideoPlayer.vue";

//import mixins
import handleServerMessage from "@/mixins/handleServerMessage.js";

export default {
  name: "SectionElementVideoUploaded",
  mixins: [handleServerMessage],
  components: {
    VideoPlayer,
    MessageBox,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    presigned_url: {
      type: String,
      required: true,
    },
    poster: {
      type: String,
      required: true,
    },
    presigned_img_href: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      messages: [],
      message_type: [],
      loadingURL: true,
      uploading: false,
      upload_progress: 0,

      choosedFile: {
        video: "",
        poster: "",
      },
      presigned_video: this.presigned_url,
      saved_video: "",
      forms: {
        video: {},
        poster: {},
      },
      presigned_poster: this.presigned_img_href,
      saved_poster: "",
      files: {
        video: {},
        poster: {},
      },
      type_to_field: {
        video: "url",
        poster: "img_href",
      },
    };
  },
  methods: {
    onFileSelected(e, type) {
      const file = e.target.files[0];
      if (file) {
        this.files[type] = file;
        this.choosedFile[type] = file.name;
        SharedDataService.getSignedUrlForm(file.name, file.type, {
          upload_type: type,
          field_name: "video_introduction",
          user_type: "teacher",
          file_name: file.name,
        })
          .then((res) => {
            this.handleServerMessageNotify(res, "res", 3000);

            this["saved_" + type] = res.data.presigned_url;
            this.forms[type] = res.data.form;
            this.loadingURL = false;
            this.handleSubmit(type);
          })
          .catch((err) => {
            this.handleServerMessageNotify(err, "err", 5000);
            this.loadingURL = false;
          });
      }
    },
    updateUploadBar(progressEvent) {
      this.upload_progress = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
    },
    handleSubmit(type) {
      if (!this.loadingURL) {
        this.uploading = true;

        this.$emit("start_uploading");
        S3DataService.uploadFileToS3(
          this.forms[type],
          this.files[type],
          this.updateUploadBar
        )
          .then(() => {
            let src = this.cdn + this.forms[type].fields.key;
            this.$emit("input", type, src);
            this["presigned_" + type] = src;
            this.uploading = false;

            this.$emit("finish_uploading");
          })
          .catch((err) => {
            this.uploading = false;
            console.log(err);
          });
      }
    },
  },
  computed: {
    cssVars() {
      let text = this.localizedTextFct("buttons", "upload");
      return { "--customfilelabel": `'${text}''` };
    },
    cdn() {
      return process.env.VUE_APP_PUBLIC_CDN;
    },
    cdnURL() {
      return process.env.VUE_APP_PUBLIC_CDN;
    },
    allCDN() {
      return new RegExp(
        process.env.VUE_APP_CDN +
          "private/" +
          "|" +
          process.env.VUE_APP_CDN +
          "private_by_url/" +
          "|" +
          process.env.VUE_APP_PUBLIC_CDN
      );
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
@import "@/css/variables.scss";

.input-group-text:hover {
  background-color: $buttonBgColorHover;
  border-color: $buttonBgColorHover;
  color: white;
}

.custom-file-input:hover + .custom-file-label::after {
  background-color: $buttonBgColorHover;
  border-color: $buttonBgColorHover;
  color: white;
}

.custom-file-label::after {
  content: var(--customfilelabel);
}

.player-container {
  margin-left: auto;
}

.custom-file-input {
  &:hover {
    cursor: pointer;
  }
}

.custom-file-label {
  overflow: hidden;
  cursor: pointer;
  background-color: #edf2f7;
}

.input-group-append {
  &:hover {
    cursor: pointer;
  }
}
.upload-result {
  background-color: white;
  height: $lap;

  &__progress {
    height: $lap;
    background-color: green;
  }
}

input[type="file"] {
  opacity: 0;
}
</style>
