<template lang="html">
  <div class="container-fluid my-3">
    <div class="row">
      <div class="col-md-2 user-info">
        <UserInfo :user="user" @show-grid="showGrid" />
      </div>
      <div class="col-md-10">
        <UserInfoCourseGrid
          v-if="this.current_grid == 'my_courses'"
          :courses="user.my_courses"
          :grid_title="localizedTextFct('grid_titles', 'student_courses')"
          course_list_name="my_courses"
        />
        <UserInfoCourseGrid
          v-if="this.current_grid == 'favorite_courses'"
          :courses="user.favorite_courses"
          :grid_title="localizedTextFct('grid_titles', 'student_favorite_courses')"
          course_list_name="favorite_courses"
        />
        <UserInfoUserGrid
          v-if="this.current_grid == 'friends'"
          :users="user.friends"
          list_name="friends"
          grid_content="friends"
          user_type="student"
          :grid_title="localizedTextFct('grid_titles', 'student_friends')"
          course_list_name="friends"
          :show_buttons="false"
        />
        <UserInfoUserGrid
          v-if="this.current_grid == 'teachers'"
          :users="user.teachers"
          list_name="teachers"
          grid_content="teachers"
          user_type="teacher"
          :grid_title="localizedTextFct('grid_titles', 'student_teachers')"
          course_list_name="teachers"
          :show_buttons="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UserInfo from "@/components/students/StudentInfo";
import UserInfoCourseGrid from "@/components/common/UserInfoCourseGrid";
import UserInfoUserGrid from "@/components/common/UserInfoUserGrid";

import SharedDataService from "@/services/SharedDataService.js";

export default {
  name: "student",
  metaInfo: {
    title: "Student",
  },
  components: {
    UserInfo,
    UserInfoCourseGrid,
    UserInfoUserGrid,
  },
  data() {
    return {
      user: {
        _id: "",
        username: "",
        img: "",
        user_type: "",
        first_name: "",
        second_name: "",
        my_courses: [],
        favorite_courses: [],
        friends: [],
        teachers: [],
      },
      loaded: {
        friends: false,
        my_courses: false,
        teachers: false,
        favorite_courses: false,
      },
      current_grid: "",
    };
  },
  methods: {
    async getUser() {
      try {
        let res = await SharedDataService.getUser(this.$route.params.id);
        this.user = res.data;
        this.showGrid("my_courses");
        this.$Progress.finish();
      } catch (e) {
        console.log(e);
      }
    },
    async showGrid(grid_name) {
      if (this.loaded[grid_name]) {
        this.current_grid = grid_name;
      } else {
        try {
          let res = await SharedDataService.getStudentInfo(grid_name, this.user._id);
          let old_user = this.user;
          old_user[grid_name] = res.data[grid_name];
          this.user = old_user;
          this.loaded[grid_name] = true;
          this.current_grid = grid_name;
          this.$Progress.finish();
        } catch (e) {
          console.log(e);
        }
      }
    },
  },
  created() {
    this.getUser();
  },
};
</script>

<style lang="scss" scoped></style>
