<template lang="html">
  <div data-tour-step="2" class="user-image-block">
    <input
      ref="input"
      type="file"
      name="image"
      accept="image/*"
      class="user-img user-img__upload-img"
      @change="onImageSelected"
    />
    <a
      class="show-crop-button"
      @click="showFileChooser"
      :class="{ 'show-crop-button--vissible': hovered }"
    >
      <font-awesome-icon class="click-me" icon="edit" />
    </a>
    <transition name="crop">
      <div
        class="crop-background"
        v-if="show_crop_dialog"
        @click.self="show_crop_dialog = false"
      >
        <div class="crop-window">
          <div class="crop-header">
            Crop Image
            <button
              type="button"
              name="button"
              class="btn"
              @click="show_crop_dialog = false"
            >
              <font-awesome-icon icon="window-close" />
            </button>
          </div>
          <div class="crop-image">
            <ImageCrop
              :parentImgSrc="parentImgSrc"
              @upload-image="emitGoddamElement"
              @set-image="setImage"
            />
          </div>
          <div class="crop-footer">
            <button
              type="button"
              name="button"
              class="btn"
              @click="show_crop_dialog = false"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </transition>
    <div @mouseover="hovered = true" @mouseleave="hovered = false">
      <div v-if="!imageHref" class="user-image-container">
        <img class="no-photo" src="@/assets/img/no_photo_squared.png" />
      </div>
      <div v-if="imageHref" class="user-image-container">
        <ImageItem v-if="img_data" :source="img_data" />
      </div>
    </div>
  </div>
</template>

<script>
//import components
import ImageItem from "@/components/common/ImageItem.vue";
import ImageCrop from "@/components/common/ImageCrop.vue";

export default {
  components: {
    ImageItem,
    ImageCrop,
  },
  props: {
    img_data: {
      type: String,
    },
    imageHref: {
      type: String,
    },
  },
  data() {
    return {
      hovered: false,
      show_crop_dialog: false,
      parentImgSrc: null,
      file: {},
    };
  },
  methods: {
    showFileChooser() {
      this.$refs.input.click();
    },
    setImage(file) {
      this.file = file;
    },
    emitGoddamElement(canvasEl) {
      this.show_crop_dialog = false;
      this.$emit("upload-image", canvasEl, this.file);
    },
    onImageSelected(e) {
      const file = e.target.files[0];
      if (file) {
        if (file.type.indexOf("image/") === -1) {
          alert("Please select an image file");
          return;
        }
        if (typeof FileReader === "function") {
          const reader = new FileReader();
          reader.onload = (event) => {
            this.parentImgSrc = event.target.result;
            this.file = file;
            this.show_crop_dialog = true;
            this.$refs.input.value = null;
          };
          reader.readAsDataURL(file);
        } else {
          alert("Sorry, FileReader API not supported");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/common/UserProfilePicture.scss";
</style>
