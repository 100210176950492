import authHeader from './auth-header';
const LOCATION_ID = process.env.VUE_APP_SQUARE_LOCATION_ID;


import {http, httpNoCache} from '@/services/httpSetup';

class StudentDataService {
  getTheCourses(course_list_name) {
    return httpNoCache.get('students/' + course_list_name);
  }
  getCourse(id) {
    return http.get('students/get_course/' + id);
  }
  getUserData() {
    return http.get('students/get_user_data')
  }
  getProfilePicture() {
    return http.get('students/get_profile_picture');
  }
  getAllCoverages() {
    return httpNoCache.get('students/get_coverages');
  }
  getProgramDates() {
    return http.get('students/get_program_dates');
  }
  getProgramDetails(program_id) {
    return http.put('students/get_program_details', {
      program_id: program_id,
    });
  }
	startCourse(course_id) {
		return http.post('students/start_course',
		{
			course_id: course_id
		});
	}
  saveCourseProgress(course_id, course_progress) {
    return http.put('students/save_course_progress',
		{
			course_id: course_id,
			course_progress: course_progress //not validated
		});
  }
  finishCourse(course_id) {
    return http.post('students/finish_course',
		{
			course_id: course_id,
		});
  }
  getCourseProgress(course_id) {
    return httpNoCache.get('students/get_course_progress',
		{
			params: {course_id: course_id}
		});
  }
  getHint(course_id, section_element) {
    return http.post('students/get_hint',
		{
			course_id: course_id,
      section_element: section_element, //not validated
		});
  }
  submitAnswer(course_id, section_element, choosed_answer) {
    return http.put('students/submit_answer',
		{
			course_id: course_id,
      section_element: section_element, //not validated
      choosed_answer: choosed_answer, //not validated
		});
  }
  updateUserData(field, value) {
    return http.put('students/update_user_data',
    {
      field: field,
      value: value
    });
  }
  updateProfilePicture(form_data) {
    return http.put('students/update_profile_picture',
     form_data,
     {
       headers: {
       'accept': 'application/json',
       'Accept-Language': 'en-US,en;q=0.8',
       'Content-Type': 'multipart/form-data',
       ... authHeader()
      }
    });
  }
  purchaseEnglishCourse(form_data, verificationToken) {
    return http.put('students/purchase_english_course',
      { 
        start_date: form_data.start_date,
        first_language: form_data.first_language,

        location_id: LOCATION_ID,
        verificationToken: verificationToken,
       });
  }
  addToMyCourses(course) {
    return http.put('students/add_to_my_courses',
    {course_id: course._id});
  }
  removeFromMyCourses(course) {
    return http.put('students/remove_from_my_courses',
    {course_id: course._id});
  }
  addToMyFavoriteCourses(course) {
    return http.put('students/add_to_my_favorite_courses',
    {course_id: course._id});
  }
  removeFromMyFavoriteCourses(course) {
    return http.put('students/remove_from_my_favorite_courses',
    {course_id: course._id});
  }
  getUsers(user_type, list) {
    return httpNoCache.get('students/' + list,
    {
      params: {user_type: user_type},
    });
  }
  sentRequest(element) {
    return http.put('students/sent_request',
    {user_id: element.user});
  }
  acceptRequest(element) {
    return http.put('students/accept_request',
      {user_id: element.user});
  }
  rejectRequest(element) {
    return http.put('students/reject_request',
      {user_id: element.user});
  }
  addElement(element) {
      return http.put('students/sent_request',
        {user_id: element.user});
    // if(type == 'friends') {
    //   return http.put('students/add_friend',
    //   {friend_id: element._id},
    //   );
    // }
    // else if(type == 'teachers') {
    //   return http.put('students/add_teacher',
    //   {teacher_id: element._id},
    //   );
    // }
  }
  removeUser(type, element) {
    if(type == 'friends') {
      return http.put('students/remove_friend',
      {friend_id: element._id});
    }
    else if(type == 'teachers') {
      return http.put('students/remove_teacher',
      {teacher_id: element._id});
    }
  }
	purchaseCourse(course_id, verificationToken) {
		return http.put('students/purchase_course',
		{
			course_id: course_id,
			location_id: LOCATION_ID,
			verificationToken: verificationToken
		});
	}
  purchaseLessons(type, teacher_id, payment_method, quantity, date, verificationToken, orderID='') {
		return http.put('students/purchase_lessons',
		{
			type: type,
			teacher_id: teacher_id,
			payment_method: payment_method,
      quantity: quantity,
      date: date, 
			location_id: LOCATION_ID,
			verificationToken: verificationToken,
			orderID: orderID,
		});
	}
	purchaseCourseCollection(course_collection_id, verificationToken) {
		return http.put('students/purchase_course_collection',
		{
			course_collection_id: course_collection_id,
			location_id: LOCATION_ID,
			verificationToken: verificationToken
		});
	}
	purchaseCoverage(coverage, verificationToken) {
		return http.put('students/purchase_coverage',
		{
			coverage: coverage,
			location_id: LOCATION_ID,
			verificationToken: verificationToken
		});
	}
}

export default new StudentDataService();
