import { render, staticRenderFns } from "./UserProfilePictureCommon.vue?vue&type=template&id=5fa3deac&scoped=true&lang=html"
import script from "./UserProfilePictureCommon.vue?vue&type=script&lang=js"
export * from "./UserProfilePictureCommon.vue?vue&type=script&lang=js"
import style0 from "./UserProfilePictureCommon.vue?vue&type=style&index=0&id=5fa3deac&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fa3deac",
  null
  
)

export default component.exports