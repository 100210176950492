import { render, staticRenderFns } from "./CoursePanelRightGrid.vue?vue&type=template&id=28003d66&scoped=true&lang=html"
import script from "./CoursePanelRightGrid.vue?vue&type=script&lang=js"
export * from "./CoursePanelRightGrid.vue?vue&type=script&lang=js"
import style0 from "./CoursePanelRightGrid.vue?vue&type=style&index=0&id=28003d66&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28003d66",
  null
  
)

export default component.exports