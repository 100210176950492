<template lang="html">
  <div class="">
    <div class="code-file-title">
      {{ section_element.meta.title }}
    </div>
    <div class="code-container">
      <prism-editor
        class="my-editor"
        v-model="section_element.text"
        :readonly="true"
        :highlight="highlighter"
        line-numbers
      >
      </prism-editor>
      <div class="language-name">
        {{ language_short }}
      </div>
    </div>
  </div>
</template>

<script>
// import Prism Editor
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

import "prismjs/themes/prism-onedark.css"; // import syntax highlight

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";

import "prismjs/components/prism-javascript.min.js";
import "prismjs/components/prism-python.min.js";
import "prismjs/components/prism-c.min.js";
import "prismjs/components/prism-csharp.min.js";
import "prismjs/components/prism-sql.min.js";
import "prismjs/components/prism-r.min.js";
import "prismjs/components/prism-ruby.min.js";
import "prismjs/components/prism-css.min.js";
import "prismjs/components/prism-scss.min.js";
import "prismjs/components/prism-java.min.js";
import "prismjs/components/prism-sass.min.js";
import "prismjs/components/prism-sas.min.js";
import "prismjs/components/prism-typescript.min.js";
import "prismjs/components/prism-rust.min.js";
import "prismjs/components/prism-cpp.min.js";
import "prismjs/components/prism-go.min.js";
import "prismjs/components/prism-markup.min.js";
import "prismjs/components/prism-matlab.min.js";

export default {
  props: {
    section_element: {
      type: Object,
      required: true,
    },
  },
  components: {
    PrismEditor,
  },
  data() {
    return {
      code_content: this.section_element.text,
    };
  },
  methods: {
    highlighter(code_content) {
      this.$emit("input", code_content);
      return highlight(code_content, languages[this.section_element.language]); //returns html
    },
  },
  computed: {
    language_short() {
      if (this.section_element.language == "python") {
        return "py";
      } else if (this.section_element.language == "typescript") {
        return "ts";
      } else if (this.section_element.language == "markup") {
        return "html";
      } else if (this.section_element.language == "csharp") {
        return "C#";
      } else if (this.section_element.language == "cpp") {
        return "C++";
      } else if (this.section_element.language == "c") {
        return "C";
      } else {
        return this.section_element.language;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/css/common/TextSectionElement.scss";
</style>
