import axios from 'axios';
const  uploadProgress = (progressEvent) => {
		console.log(Math.round((progressEvent.loaded * 100) / progressEvent.total));
	}
class S3DataService {
	uploadFileToS3(form, file, callback = uploadProgress, messageId="", fileIndex=0) {
		const form_data = new FormData();
		form_data.append("Content-Type", file.type);
		Object.entries(form.fields).forEach(([k, v]) => {
			form_data.append(k, v);
		});
		form_data.append("file", file); // must be the last one
		return axios.post(form.url,
			form_data,
			{
				onUploadProgress: (progressEvent) => {
					return callback(progressEvent, messageId, fileIndex);
				},
			}
		);
	}

}

export default new S3DataService();
