export const weekdays = {
	0: 'Monday',
	1: 'Tuesday',
	2: 'Wednesday',
	3: 'Thirsday',
	4: 'Friday',
	5: 'Saturday',
	6: 'Sunday',
}


export const parseTimestamp = (timestamp, format = '') => {
	if (!timestamp) return

	const date = timestamp.seconds
		? new Date(timestamp.seconds * 1000)
		: timestamp

	if (format === 'HH:mm') {
		return `${zeroPad(date.getHours(), 2)}:${zeroPad(date.getMinutes(), 2)}`
	} else if (format === 'DD MMMM YYYY') {
		const options = { month: 'long', year: 'numeric', day: 'numeric' }
		return `${new Intl.DateTimeFormat('en-GB', options).format(date)}`
	} else if (format === 'DD/MM/YY') {
		const options = { month: 'numeric', year: 'numeric', day: 'numeric' }
		return `${new Intl.DateTimeFormat('en-GB', options).format(date)}`
	} else if (format === 'DD MMMM, HH:mm') {
		const options = { month: 'long', day: 'numeric' }
		return `${new Intl.DateTimeFormat('en-GB', options).format(
			date
		)}, ${zeroPad(date.getHours(), 2)}:${zeroPad(date.getMinutes(), 2)}`
	}

	return date
}

const zeroPad = (num, pad) => {
	return String(num).padStart(pad, '0')
}

export const isSameDay = (d1, d2) => {
	return (
		d1.getFullYear() === d2.getFullYear() &&
		d1.getMonth() === d2.getMonth() &&
		d1.getDate() === d2.getDate()
	)
}

export const getWeekStart = (d) => {
	d = new Date(d);
	let day = d.getDay(),
		diff = d.getDate() - day + (day == 0 ? -6 : 1);
	return new Date(new Date(d.setDate(diff)).setHours(0, 0, 0, 0));
}

export const getWeekEnd = (d) => {
	d = new Date(d);
	let day = d.getDay(),
		diff = d.getDate() - day + (day == 0 ? 0 : 7);
	return new Date(new Date(d.setDate(diff)).setHours(24, 0, 0, 0));
}

export const createEventFromBusinessHour = (startTime, endTime, weekday) => {
	weekday = weekday !== 0 ? weekday - 1 : 6;
	let weekStart = getWeekStart(new Date());
	let todayMidnight = weekStart.setDate(weekStart.getDate() + weekday);
	let [startHours, startMinutes] = startTime.split(':');
	let [endHours, endMinutes] = endTime.split(':');
	let eventStart = new Date(todayMidnight);
	let eventEnd = new Date(todayMidnight);
	
	eventStart.setHours(eventStart.getHours() + startHours);
	eventStart.setMinutes(eventStart.getMinutes() + startMinutes);

	eventEnd.setHours(eventEnd.getHours() + endHours);
	eventEnd.setMinutes(eventEnd.getMinutes() + endMinutes);

	return [eventStart, eventEnd];
}

export const generateArrayOfYears = (number_of_years) => {
	let max = new Date().getFullYear()
	let min = max - number_of_years;
	let years = []

	for (let i = max; i >= min; i--) {
		years.push(i)
	}
	return years
}
