<template>
  <div class="">
    <div class="math-text-title">
      {{ section_element.meta.title }}
    </div>
    <div class="mathjax-container">
      <div v-show="!loading" ref="MathOutput" class="MathOutput" id="MathOutput">
        {{ math_text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    math_text: {
      type: String,
      required: true,
    },
    section_element: {
      type: Object,
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    let myVue = this;
    window.MathJax.Hub.Queue(
      ["Typeset", window.MathJax.Hub, this.$refs.MathOutput],
      function () {
        myVue.show_result = true;
        myVue.loading = false;
        myVue.$emit("mathjax-loaded");
      }
    );
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/variables.scss";
.math-text-title {
  font-style: italic;
  color: black;
  font-size: 12px;
  padding-left: 2px;
  margin-bottom: 2px;
  text-decoration: underline;
}

.invisible {
  visibility: hidden;
}
.mathjax-container {
  padding: 0.5rem;
  //border-color: #edf2f7;
  //background-color: #edf2f7;
  border-radius: 0.2rem;
  border-width: 2px;
}

#MathOutput {
  word-wrap: break-word;
  white-space: normal;
}
</style>
