<template lang="html">
  <div class="course-grid mb-2 rounded">
    <div class="course-grid course-grid__body rounded">
      <div v-if="!no_additional_courses && courses.length" class="row">
        <div
          class="col-12 text-center course-grid-element--container"
          v-for="(course, index) in courses"
          :key="index"
        >
          <CourseGridElement :course="course" :course_index="index" type="student" />
        </div>
      </div>
      <div
        v-if="no_additional_courses || !courses.length"
        class="no-additional-course text-center"
      >
        {{ localizedTextFct("placeholders", "no_additional_courses") }}
      </div>
    </div>
  </div>
</template>

<script>
import CourseGridElement from "@/components/common/CourseGridElement";

export default {
  name: "CoursePanelRightGrid",
  components: {
    CourseGridElement,
  },
  props: {
    courses: {
      type: Array,
      required: true,
    },
    no_additional_courses: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/common/CoursePanelRightGrid";
</style>
