import TokenService from "./TokenService";
import EventBus from "@/common/EventBus";

const setup = (store, axiosInstance) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        // config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
        config.headers["x-access-token"] = token; // for Node.js Express back-end
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (originalConfig.url !== "/users/login" && err.response &&
       err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const rs = await axiosInstance.post("/users/refreshtoken", {
            refreshToken: TokenService.getLocalRefreshToken(),
          });
          const { accessToken } = rs.data;
          store.dispatch('user_module/refreshToken', accessToken);
          TokenService.updateLocalAccessToken(accessToken);
          return axiosInstance(originalConfig);
        }
        catch (_error) {
          return Promise.reject(_error);
        }
      }
      else if (originalConfig.url !== "/users/login" &&
      err.response && err.response.status === 403) {
        EventBus.dispatch("logout");
        return;
      }
      return Promise.reject(err);
    }
  );
};

export default setup;
