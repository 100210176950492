<template>
  <div class="dropdown">
    <a
      class="nav-link nav_link dropdown-toggle"
      :class="visitor_page ? 'dropdown-toggle--visitor' : ''"
      data-toggle="dropdown"
    >
      <img
        v-if="!visitor_page"
        :src="language_button.asset"
        :alt="language_button.alt"
        class="nav-img mx-auto"
      />
      <p :class="visitor_page ? 'header-name--visitor' : 'header-name'">
        {{ this.localizedTextFct("navbar", "language") }}
      </p>
    </a>

    <ul class="dropdown-menu" :class="visitor_page ? 'visitor-dropdown' : ''">
      <li v-for="locale in supportedLocales" :key="locale" @click="switchLocale(locale)">
        <a class="dropdown-item">
          <div class="language-icon-container">
            <img
              :src="language_icons[locale + '_flag'].asset"
              :alt="language_icons[locale + '_flag'].alt"
              class="language-icon"
            />
            <span>{{ $t("menu." + locale) }}</span>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { Trans } from "@/plugins/Translation.js";
import { EventBus } from "@/event-bus";

export default {
  name: "LocaleSwitcher",
  props: {
    current_index: {
      type: [String, Number],
    },
    visitor_page: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    supportedLocales() {
      return Trans.supportedLocales;
    },
  },
  data() {
    return {
      language_button: {
        asset: require("@/assets/img/languages.svg"),
        alt: "language icon",
      },
      language_icons: {
        en_flag: {
          asset: require("@/assets/img/uk_flag.svg"),
          alt: "uk flag",
        },
        ru_flag: {
          asset: require("@/assets/img/ru_flag.svg"),
          alt: "ru flag",
        },
      },
    };
  },
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        const to = this.$router.resolve({ params: { locale } });
        return Trans.changeLocale(locale).then(async () => {
          EventBus.$emit("locale-change");
          await this.$router.push(to.location);
          this.$Progress.finish();
        });
      }
    },
    activateLink() {
      this.$emit("activate-link");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/common/NavBar.scss";
.visitor-dropdown {
  left: auto;
  right: 0;
}

.language-icon {
  width: 20px;
  vertical-align: middle;
}
.language-icon-container {
  display: flex;
  align-items: center;
}
.dropdown-toggle {
  color: #000;
  text-decoration: none;
  text-align: center;
}

.dropdown {
  cursor: pointer;
}
.dropdown-toggle:after {
  content: none;
}

@media screen and (max-width: 576px) {
  .dropdown-toggle--visitor {
    text-align: left;
    margin-left: 0;
  }
}
</style>
