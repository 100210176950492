<template lang="html">
  <div class="col-md-2">
    <div v-if="title" class="course-image-container mb-2">
      <div class="img-container">
        <ImageItem :source="courseImg(img)" :size="128" />
      </div>
      <div class="course-title">
        {{ title }}
      </div>
    </div>

    <div v-if="sections.length" class="section-names">
      <div class="section-names-header">
        {{ localizedTextFct("sections", "sections") }}
      </div>
      <div
        v-for="(section, index) in sections"
        class="section-name"
        :class="{ 'section-name--selected': index == section_index }"
        :key="index"
        @click="selectSection(index)"
      >
        {{ section.title }}
      </div>
    </div>
  </div>
</template>

<script>
import ImageItem from "@/components/common/ImageItem";
export default {
  props: {
    sections: {
      type: Array,
      required: true,
    },
    img: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    last_section_index: {
      type: Number,
    },
    course_id: {
      type: String,
      required: true,
    },
    section_index: {
      type: Number,
      default: null,
    },
  },
  components: {
    ImageItem,
  },
  data() {
    return {
      current_index: this.section_index,
    };
  },
  methods: {
    selectSection(index) {
      this.current_index = index;
      this.$emit("section-selected", index);
    },
    courseImg(img) {
      return img
        ? process.env.VUE_APP_API_URL + "img/courses/" + this.course_id + "/" + img
        : "/img/upload_image.png";
    },
  },
  created() {
    if (this.last_section_index) {
      this.selectSection(this.last_section_index);
    } else {
      this.selectSection(0);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/common/CoursePanelLeft.scss";
</style>
