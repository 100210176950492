<template lang="html">
  <div class="course-container" @mouseover="hovered = true" @mouseleave="hovered = false">
    <div class="image-button-wrapper">
      <router-link :to="{ name: routeName, params: { id: element.user } }">
        <div class="image-container">
          <ImageItem :source="elementImg()" :size="128" />
        </div>
      </router-link>
      <div v-if="show_buttons" class="the-border"></div>
      <div v-if="show_buttons" class="extra-container">
        <div class="button-container" :class="{ active: hovered }">
          <div class="button-container__add-buttons">
            <div
              @click="sentRequest"
              v-show="grid_content == 'all_students' || grid_content == 'all_teachers'"
              class="add-button div-button"
            >
              Add {{ element_type }}
            </div>
            <div
              v-show="grid_content == 'pending_requests' && inMyRequestList"
              class="add-button div-button"
            >
              {{ localizedTextFct("elements", "pending") }}
            </div>
            <div
              @click="removeUser"
              v-show="grid_content == 'friends' || grid_content == 'teachers'"
              class="add-button div-button"
            >
              {{ grid_content == "friends" ? "Remove Friend" : "Remove Teacher" }}
            </div>
            <div
              v-show="grid_content == 'pending_requests' && !inMyRequestList"
              class="add-button"
            >
              <span @click="acceptRequest" class="add-button div-button div-button--left">
                {{ localizedTextFct("elements", "accept") }}
              </span>
              <span
                @click="rejectRequest"
                class="add-button div-button div-button--right"
              >
                {{ localizedTextFct("elements", "reject") }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <router-link :to="{ name: routeName, params: { id: element._id } }">
      <div class="course-title">{{ element.first_name }} {{ element.second_name }}</div>
    </router-link>
    <div class="info" v-if="grid_content == 'all_teachers'">
      <div class="info__item info__item--spaced">
        <div class="info__rating">
          <font-awesome-icon :icon="['fas', 'star']" /><b>{{
            element.description.subjects[0]
          }}</b>
        </div>
        <div class="info__price">${{ element.settings.tutoring_hourly_rate }}/h</div>
      </div>
      <div class="info__item">
        <div class="info__title">
          {{ element.description.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageItem from "@/components/common/ImageItem";

export default {
  name: "GridElement",
  props: {
    element: {
      type: Object,
      required: true,
    },
    element_type: {
      type: String,
      required: true,
    },
    list_name: {
      type: String,
      required: true,
    },
    user_type: {
      type: String,
      required: true,
    },
    grid_content: {
      type: String,
    },
    show_buttons: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    ImageItem,
  },
  data() {
    return {
      loading: false,
      hovered: false,
    };
  },
  methods: {
    elementImg() {
      if (this.element.img) {
        if (this.element_type == "user") {
          return process.env.VUE_APP_API_URL + "img/" + this.element.img;
        } else if (this.element_type == "course") {
          return (
            process.env.VUE_APP_API_URL +
            "img/courses/" +
            this.element._id +
            "/" +
            this.element.img
          );
        }
      } else {
        return "/img/no_photo_squared.png";
      }
    },
    sentRequest() {
      if (!this.inMyList && !this.inMyRequestList && !this.loading) {
        this.loading = true;
        this.$store
          .dispatch("user_module/sentRequest", {
            type: this.list_name,
            element: this.element,
          })
          .then(() => {
            this.loading = false;
            this.$emit("request-sent", this.element);
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      }
    },
    acceptRequest() {
      this.loading = true;
      this.$store
        .dispatch("user_module/acceptRequest", {
          type: this.list_name,
          element: this.element,
        })
        .then(() => {
          this.loading = false;
          this.$emit("request-accepted", this.element);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    rejectRequest() {
      this.loading = true;
      this.$store
        .dispatch("user_module/rejectRequest", {
          type: this.list_name,
          element: this.element,
        })
        .then(() => {
          this.loading = false;
          this.$emit("request-rejected", this.element);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    removeUser() {
      if (this.inMyList && !this.loading) {
        this.loading = true;
        this.$store
          .dispatch("user_module/removeUser", {
            type: this.list_name,
            element: this.element,
          })
          .then(() => {
            this.loading = false;
            this.$emit("removed-my-element", this.element._id);
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      }
    },
  },
  computed: {
    inMyList() {
      return (
        this.$store.state.user_module.user_type_info[this.list_name].indexOf(
          this.element._id
        ) !== -1
      );
    },
    inMyRequestList() {
      return (
        this.$store.state.user_module.user_type_info.sent_request_by_me.indexOf(
          this.element.user
        ) !== -1
      );
    },
    routeName() {
      return `${this.element_type}-${this.user_type}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/common/GridElement";

.info {
  font-family: $fontFamilyCommon;
  &__item {
    display: flex;
    flex-direction: row;
    &--spaced {
      justify-content: space-between;
    }
  }
  &__price {
    font-family: $fontFamilyVerdana;
    font-weight: 500;
  }
  &__title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
</style>
