<template lang="html">
  <div class="form-container">
    <h2 class="title">{{ title + " " + (form_index + 1) }}</h2>
    <div v-for="(field, index) in this.formDataFields" :key="index">
      <div v-if="field.type == 'text'" class="form-group">
        <label :for="type + field.label + form_index">{{ field.name }}</label>
        <input
          class="form-control"
          :type="field.type"
          :name="type + field.label + form_index"
          :id="type + field.label + form_index"
          autocomplete="organization"
          :readonly="['under_review', 'verified'].includes(formData.status)"
          :required="field.required ? true : null"
          :value="formData[field.field_name]"
          @input="changeOption(field.field_name, $event.target.value)"
          :v-validate="field.required ? 'required' : ''"
        />
      </div>
      <div v-if="field.type == 'textarea'" class="form-group">
        <label :for="type + field.label + form_index">{{ field.name }}</label>
        <textarea
          class="form-control"
          :name="type + field.label + form_index"
          :id="type + field.label + form_index"
          :required="field.required ? true : null"
          :value="formData[field.field_name]"
          :readonly="['under_review', 'verified'].includes(formData.status)"
          @input="changeOption(field.field_name, $event.target.value)"
        ></textarea>
      </div>
      <div v-if="field.type == 'dropdown'" class="form-group">
        <div class="dropdown-title">{{ field.name }}</div>
        <div class="dropdown-group">
          <div class="dropdown">
            <button
              class="btn btn-primary dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              :disabled="['under_review', 'verified'].includes(formData.status)"
            >
              {{ field.choose }} <span class="caret"></span>
            </button>
            <div class="dropdown-option--selected" v-show="formData[field.field_name]">
              {{ formData[field.field_name] }}
            </div>
            <div
              class="dropdown-option--not-selected"
              v-show="!formData[field.field_name]"
            >
              {{ field.placeholder }}
            </div>
            <ul class="dropdown-menu">
              <li
                v-for="(option, index) in field.options"
                :key="index"
                class="dropdown-option-container"
                @click.prevent="changeOption(field.field_name, option)"
              >
                <a class="dropdown-option">{{ option }}</a>
              </li>
              <li
                v-if="field.other"
                class="dropdown-option-container"
                @click.prevent="changeOption(field.field_name, 'Other')"
              >
                <a class="dropdown-option">Other</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-if="field.type == 'upload'" class="form-group">
        <div class="input-group mb-3">
          <div class="custom-file">
            <input
              ref="input"
              :content="localizedTextFct('buttons', 'upload')"
              type="file"
              :name="field.name"
              :accept="field.accepted_file_types"
              @change="onFileSelected($event, field.name, field.field_name)"
              class="custom-file-input"
              :class="
                formData.file_under_review ||
                loadingURL ||
                ['under_review', 'verified'].includes(formData.status) ||
                ['under_review', 'verified'].includes(formData.file_status)
                  ? 'disabled'
                  : ''
              "
              :disabled="
                formData.file_under_review ||
                loadingURL ||
                ['under_review', 'verified'].includes(formData.status) ||
                ['under_review', 'verified'].includes(formData.file_status)
              "
              :id="type + field.label + form_index"
            />
            <label
              class="custom-file-label"
              :style="cssVars"
              :for="type + field.label + form_index"
              >{{ field.name }}</label
            >
          </div>
        </div>
        <Panel
          v-show="formData.file_under_review"
          text="Your uploaded file is under consideration and cannot be changed"
          type="info"
        />
      </div>
    </div>
    <Panel
      v-show="formData.status == 'under_review'"
      text="Thank you for submitting your information. Please allow us up to 2-3 bussiness days to review it."
      type="info"
    />
    <Panel
      v-show="formData.status == 'verified'"
      text="This form has been verified."
      type="success"
    />
    <Panel
      v-show="formData.status == 'rejected'"
      text="This form has been rejected. Please consider to make the neccessary changes before resubmitting it."
      type="danger"
    />
    <div class="m-2" v-show="formData.status == 'verified' && formData.review_comment">
      <b>Review comment</b>
    </div>
    <Panel
      v-if="formData.review_comment && formData.status == 'verified'"
      :text="formData.review_comment"
      type="info"
    />
    <Panel
      v-if="formData.review_comment && formData.status == 'rejected'"
      :text="formData.review_comment"
      type="info"
    />
    <MessageBox :message_type="message_type" :messages="messages" />
  </div>
</template>

<script>
import SharedDataService from "@/services/SharedDataService";
import S3DataService from "@/services/S3DataService";

import Panel from "./Panel.vue";

import MessageBox from "@/components/common/MessageBox";

//import mixins
import handleServerMessage from "@/mixins/handleServerMessage.js";

//               : class="
// loadingURL ||
//   formData.status == 'under_review' ||
//   formData.file_under_review
//   ? 'disabled'
//   : ''
// "
//               : disabled = "formData.status == 'under_review' || formData.file_under_review"

export default {
  components: {
    MessageBox,
    Panel,
  },
  mixins: [handleServerMessage],
  props: {
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      enum: ["certificates", "proffessional_experience", "educational_background"],
      required: true,
    },
    formDataFields: {
      type: Array,
      required: true,
    },
    form_index: {
      type: Number,
      required: true,
    },
    formData: {
      type: Object,
    },
  },
  data() {
    return {
      messages: [],
      message_type: [],
      loadingURL: false,
      uploadingFile: false,
      file_form: "",
      file: "",
    };
  },
  methods: {
    changeOption(field_name, new_value) {
      this.$emit("input", field_name, new_value);
    },
    onFileSelected(e, upload_type) {
      const file = e.target.files[0];
      if (file) {
        this.loadingURL = true;
        SharedDataService.getSignedUrlForm(file.name, file.type, {
          index: this.form_index,
          upload_type: upload_type,
          field_name: this.type,
          user_type: "teacher",
        })
          .then((res) => {
            this.handleServerMessage(res, "res", 3000);
            this.changeOption("file_under_review", res.data.url);
            this.file = file;
            this.file_form = res.data.form;
            this.loadingURL = false;
            this.handleSubmit();
          })
          .catch((err) => {
            this.handleServerMessage(err, "err", 5000);
            this.loadingURL = false;
          });
      }
    },
    handleSubmit() {
      if (!this.loadingURL && !this.uploadingFile) {
        this.uploadingFile = true;
        S3DataService.uploadFileToS3(this.file_form, this.file)
          .then(() => {
            this.uploadingFile = false;
          })
          .catch((err) => {
            this.handleServerMessage(err, "err", 5000);
            this.uploadingFile = false;
          });
      }
    },
  },
  computed: {
    cssVars() {
      let text = this.localizedTextFct("buttons", "upload");
      return { "--mycontent": `'${text}''` };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/css/variables.scss";

.upload-form {
  display: flex;
  flex-direction: column;
}

textarea {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  resize: vertical;
  padding: 0.5rem;
  border-color: transparent;
  background-color: white;
  border-radius: 0.25rem;
  border-width: 2px;
  outline: none;
  appearance: none;
  display: flex;
}

.form-container {
  margin-left: auto;
  margin-right: auto;
  background-color: $commonBgColor;
  border-radius: $borderRadius;

  padding: 5%;
}

.input-group-text:hover {
  background-color: $buttonBgColorHover;
  border-color: $buttonBgColorHover;
  color: white;
}

.custom-file-input:hover + .custom-file-label::after {
  background-color: $buttonBgColorHover;
  border-color: $buttonBgColorHover;
  color: white;
}

.custom-file-label::after {
  content: var(--mycontent) !important;
}

.player-container {
  margin-left: auto;
}

.custom-file-input {
  &:hover {
    cursor: pointer;
  }
}

.custom-file-label {
  overflow: hidden;
  cursor: pointer;
  background-color: white;
}

.input-group-append {
  &:hover {
    cursor: pointer;
  }
}

.dropdown {
  &-title {
    font-size: 24px;
  }

  &-container {
    margin-bottom: 8px;
    width: 100%;
    padding: 8px;
  }

  &-menu {
    width: 100%;
    overflow-y: scroll;
    height: 200px;
  }

  &-toggle {
    width: 100%;
    display: flex;
    position: relative;
    justify-content: space-between;
    margin: 4px 0px;

    &::after {
      right: 8px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &-option {
    color: black;
    text-decoration: none;

    &--not-selected {
      opacity: 0.5;
    }

    &-container {
      cursor: pointer;

      &:hover {
        background: $courseElementBgColorHover;
      }

      padding: 4px;
    }
  }

  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 0;
  margin-right: 2%;
}
</style>
