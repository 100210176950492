<template lang="html">
  <div class="list-button-variant__container list-button-variant__container--user-view">
    <div
      class="list-item list-button-variant"
      :class="buttonClass()"
      title="select this variant as the right answer"
      @click="$emit('update-answer')"
    ></div>
  </div>
</template>

<script>
export default {
  name: "VariantButton",
  props: {
    index: {
      type: Number,
    },
    choosed_answer: {
      type: Number,
    },
    right_answer: {
      type: Number,
    },
    edible: {
      type: Boolean,
      required: true,
      default: true,
    },
    user_view: {
      type: Boolean,
      default: false,
    },
    locked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    buttonClass() {
      let hoverOn = this.locked ? " hover-off" : "";
      let userOn = this.user_view
        ? " list-button-variant--user-view"
        : " list-button-variant--user-view";
      if (!this.edible) {
        if (this.right_answer) {
          if (this.choosed_answer == this.right_answer) {
            if (this.index == this.right_answer) {
              return "list-button-variant--right_answer" + hoverOn + userOn;
            } else {
              return "list-button-variant--default" + hoverOn + userOn;
            }
          } else {
            if (this.index == this.choosed_answer) {
              return "list-button-variant--wrong_answer" + hoverOn + userOn;
            } else if (this.index == this.right_answer) {
              return "list-button-variant--right_answer" + hoverOn + userOn;
            } else {
              return "list-button-variant--default" + hoverOn + userOn;
            }
          }
        } else {
          if (this.index == this.choosed_answer) {
            return "list-button-variant--choosed_answer" + hoverOn + userOn;
          } else {
            return "list-button-variant--default" + hoverOn + userOn;
          }
        }
      } else {
        if (this.index == this.choosed_answer) {
          return "list-button-variant--choosed_answer" + hoverOn + userOn;
        } else {
          return "list-button-variant--default" + hoverOn + userOn;
        }
      }
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
@import "@/css/variables.scss";

.list-button-variant {
  &:hover {
    cursor: pointer;
  }

  &--choosed_answer {
    background: limegreen;
  }

  &--wrong_answer {
    background: firebrick;
  }

  &--right_answer {
    background: green;
  }

  background: $courseElementBgColorDark;
  width: 6%;
  padding-bottom: 4%;
  border-radius: $borderRadius;
  margin-right: $f3;

  &--user-view {
    border-radius: 50%;
    border: 2px solid black;
    width: $f8;
    height: $f8;
  }

  &__container {
    position: relative;
    &--user-view {
      margin-right: $f3;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.hover-off {
  &:hover {
    cursor: initial;
  }
}
</style>
