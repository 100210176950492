<template>
  <div
    class="image__container"
    :style="{
      width: width + 'px',
      height: height + 'px',
    }"
  >
    <div class="image__spinner">
      <div class="loader loader-1-1"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageSpinner",
  props: {
    width: {
      type: Number,
      default: 16,
    },
    height: {
      type: Number,
      default: 16,
    },
  },
};
</script>

<style scoped lang="scss">
@use "sass:math";
.image {
  &__container {
    position: relative;
  }

  &__spinner {
    display: inline-block;
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
  }
}
.loader {
  border: 2px solid darkgrey;
  border-radius: 50%;
  border-top: 2px solid black;
  position: absolute;
  &-16-9 {
    top: math.div(100% - math.div(16, 9) * 20%, 2);
    left: 40%;
    width: 20%;
    height: math.div(16, 9) * 20%;
  }
  &-1-1 {
    top: 10%;
    left: 10%;
    width: 80%;
    height: 80%;
  }
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
