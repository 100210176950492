<template lang="html">
  <div>
    <div class="row m-1 p-2 course-col">
      <div
        class="col-md-2 col-sm-4 col-6 my-2 text-center"
        v-for="(user, index) in elements"
        :key="index"
      >
        <GridElement
          :element="user"
          :list_name="list_name"
          :user_type="user_type"
          :grid_content="grid_content"
          element_type="user"
        />
      </div>
    </div>
    <router-link :to="{ name: routeName, params: { id: element.user } }">
      <div class="image-container">
        <ImageItem :source="elementImg()" :size="128" />
      </div>
    </router-link>
    <div class="user-field__title">{{ teacher.description.title }}</div>
    <div class="user-field__about">{{ teacher.description.about }}</div>
  </div>
</template>
<script>
import SharedDataService from "@/services/SharedDataService.js";

export default {
  name: "TeachersNew",
  props: {
    grid_content: {
      type: String,
      default: "all_teachers",
      required: true,
    },
  },
  data() {
    return {
      elements: [],
      loading: true,
      type: "",
      search_loaded: true,
      search_value: "",
    };
  },
  created() {
    this.getUsers(this.grid_content);
  },
  methods: {
    async getUsers(grid_content, cache = true) {
      try {
        let res = await SharedDataService.getTheUsers(grid_content, cache);
        if (grid_content == "pending_requests") {
          this.elements = res.data.received_requests.concat(res.data.sent_request_by_me);
        } else {
          this.elements = res.data.users;
        }
        this.loading = false;
        this.$Progress.finish();
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
<style lang="scss"></style>
