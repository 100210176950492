<template lang="html">
  <div class="">
    <div v-if="!multi_text_index">
      <div class="math-text-title">
        {{ section_element.meta.title }}
      </div>
      <mathlive-mathfield
        class="mathlive mathlive--user-view"
        v-model="content"
        :read-only="true"
      >
        {{ section_element.text }}
      </mathlive-mathfield>
    </div>
    <div v-else>
      <div class="math-text-title">
        {{ section_element.meta.title }}
      </div>
      <mathlive-mathfield
        class="mathlive mathlive--user-view"
        v-model="content"
        :read-only="true"
      >
        {{ section_element.multi_text_variants[multi_text_index - 1] }}
      </mathlive-mathfield>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    section_element: {
      type: Object,
    },
    multi_text_index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      content: this.section_element.math_text,
    };
  },
  methods: {},
  watch: {
    math_text: function (newVal) {
      this.content = newVal;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/variables.scss";
@import "@/css/common/TextSectionElement.scss";
</style>
