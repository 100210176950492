<template lang="html">
  <div class="schedule">
    <div class="schedule__section schedule__header">
      <div class="schedule__item">
        <button
          class="btn schedule__button schedule__button--today"
          :disabled="checkWeekday(0) === 1"
          @click="getUserAvailability('current_week')"
        >
          {{ localizedTextFct("calendar", "today") }}
        </button>
        <button
          class="btn schedule__button schedule__button--left"
          :disabled="checkWeekday(0) === 1"
          @click="getUserAvailability('previous_week')"
        >
          <font-awesome-icon class="schedule__icon" icon="arrow-left" />
        </button>
        <button
          class="btn schedule__item schedule__button schedule__button--right"
          @click="getUserAvailability('next_week')"
        >
          <font-awesome-icon class="schedule__icon" icon="arrow-right" />
        </button>
      </div>
      <div class="schedule__item">
        <div class="schedule__range">{{ this.getWeekRange() }}</div>
      </div>

      <div class="schedule__item schedule__item--flex" v-show="!mini">
        <TimeZones @change-value="changeTimeZone" :select_id="select_id" />
      </div>
    </div>
    <div class="switch schedule__section schedule__parts-of-day">
      <div
        @click="part_of_day = 'full_day'"
        class="switch__button"
        :class="part_of_day === 'full_day' ? 'switch__button--active' : ''"
      >
        <div class="switch__text switch__text--small">
          <font-awesome-icon
            class="schedule__icon schedule__icon--time"
            :icon="['fas', 'clock']"
          />
          <div class="schedule__text" v-show="!mini">
            {{ localizedTextFct("calendar", "full") }}
          </div>
        </div>
      </div>
      <div
        @click="part_of_day = 'morning'"
        class="switch__button"
        :class="part_of_day === 'morning' ? 'switch__button--active' : ''"
      >
        <div class="switch__text switch__text--small">
          <font-awesome-icon
            class="schedule__icon schedule__icon--time"
            :icon="['fas', 'coffee']"
          />

          <div class="schedule__text" v-show="!mini">
            {{ localizedTextFct("calendar", "morning") }}
          </div>
        </div>
      </div>
      <div
        @click="part_of_day = 'afternoon'"
        class="switch__button"
        :class="part_of_day === 'afternoon' ? 'switch__button--active' : ''"
      >
        <div class="switch__text switch__text--small">
          <font-awesome-icon
            class="schedule__icon schedule__icon--time"
            :icon="['fas', 'sun']"
          />
          <div class="schedule__text" v-show="!mini">
            {{ localizedTextFct("calendar", "afternoon") }}
          </div>
        </div>
      </div>
      <div
        @click="part_of_day = 'evening'"
        class="switch__button"
        :class="part_of_day === 'evening' ? 'switch__button--active' : ''"
      >
        <div class="switch__text switch__text--small">
          <font-awesome-icon
            class="schedule__icon schedule__icon--time"
            :icon="['fas', 'cloud-sun']"
          />
          <div class="schedule__text" v-show="!mini">
            {{ localizedTextFct("calendar", "evening") }}
          </div>
        </div>
      </div>
      <div
        @click="part_of_day = 'night'"
        class="switch__button"
        :class="part_of_day === 'night' ? 'switch__button--active' : ''"
      >
        <div class="switch__text switch__text--small">
          <font-awesome-icon
            class="schedule__icon schedule__icon--time"
            :icon="['fas', 'moon']"
          />
          <div class="schedule__text" v-show="!mini">
            {{ localizedTextFct("calendar", "night") }}
          </div>
        </div>
      </div>
    </div>
    <div class="weekdays">
      <div
        class="weekdays__content"
        :class="!timeslots_expanded ? 'weekdays__content--mini' : ''"
      >
        <div
          class="weekday"
          v-for="weekindex in Object.keys(availableHours(part_of_day))"
          :key="weekindex"
        >
          <div
            class="weekday__title"
            :class="{
              'weekday__title--disabled': checkWeekday(weekindex) === 1,
              'weekday__title--today': checkWeekday(weekindex) === 0,
            }"
          >
            <p>
              {{ getWeekDay(weekindex)?.[0] }}
            </p>
            <p>
              {{ getWeekDay(weekindex)?.[2] }}
            </p>
          </div>
          <div class="timeslots">
            <div
              class="timeslot"
              @click="timeSlotClicked(element.time, element.am_pm, weekindex)"
              v-for="(element, index) in availableHours(part_of_day)[weekindex]"
              :class="{
                'timeslot--disabled':
                  checkWeekday(
                    weekindex,
                    Number(element.time.split(':')[0]),
                    element.am_pm,
                    Number(element.time.split(':')[1])
                  ) === 1,
                'timeslot--active':
                  selected_string === weekindex + element.time + element.am_pm,
              }"
              :key="index"
            >
              <span class="timeslot__time">{{ element.time }}</span>
              <span class="timeslot__am-pm">{{ " " + element.am_pm }}</span>
            </div>
          </div>
        </div>
        <div class="timeslots__cover" v-show="!timeslots_expanded"></div>
      </div>

      <div class="weekdays__buttons">
        <button
          class="btn timeslots__show"
          v-show="!timeslots_expanded"
          @click="timeslots_expanded = !timeslots_expanded"
        >
          {{ localizedTextFct("calendar", "show_more") }}
        </button>
        <button
          class="btn timeslots__hide"
          v-show="timeslots_expanded"
          @click="timeslots_expanded = !timeslots_expanded"
        >
          {{ localizedTextFct("calendar", "show_less") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import SharedDataService from "@/services/SharedDataService";

import TimeZones from "@/components/common/TimeZones";

import {
  startOfWeek,
  endOfWeek,
  compareAsc,
  add,
  sub,
  parseISO,
  format,
  getMonth,
  setDefaultOptions,
  // startOfDay,
} from "date-fns";

import { enGB, ru } from "date-fns/locale";

import { EventBus } from "@/event-bus";

const { zonedTimeToUtc, getTimezoneOffset } = require("date-fns-tz");

const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export default {
  components: {
    TimeZones,
  },
  props: {
    user_id: {
      type: String,
      required: true,
    },
    mini: {
      type: Boolean,
      default: false,
    },
    select_id: {
      type: String,
      default: "select_id",
    },
  },
  data() {
    return {
      date: new Date(),
      locales: {
        en: enGB,
        ru: ru,
      },
      selected_date: "",
      selected_string: "",
      current_time_zone: localTimeZone,
      week_start: "",
      week_end: "",
      timeslots_expanded: false,
      part_of_day: "full_day",
      available_hours: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
      },
    };
  },
  mounted() {
    this.setup();
    this.getUserAvailability();
    let locale = this.$i18n.locale;
    setDefaultOptions({ locale: this.locales[locale] });
    EventBus.$on("locale-change", () => {
      setDefaultOptions({ locale: this.locales[locale] });
    });
    this.$Progress.finish();
  },
  methods: {
    timeSlotClicked(time, am_pm, weekindex) {
      let check =
        this.checkWeekday(
          weekindex,
          Number(time.split(":")[0]),
          am_pm,
          Number(time.split(":")[1])
        ) === 1;
      if (check) {
        return;
      }
      let date = add(this.week_start, {
        days: weekindex,
        hours:
          Number(time.split(":")[0]) +
          (am_pm === "am" || Number(time.split(":")[0]) === 12 ? 0 : 12),
        minutes: Number(time.split(":")[1]),
      });
      this.selected_date = date;
      this.selected_string = weekindex + time + am_pm;
      this.$emit("element-clicked", date);
    },
    setup() {
      this.week_start = startOfWeek(this.date, { weekStartsOn: 1 });
      this.week_end = endOfWeek(this.date, { weekStartsOn: 1 });
      this.week_start = zonedTimeToUtc(this.week_start, this.current_time_zone);
      this.week_end = zonedTimeToUtc(this.week_end, this.current_time_zone);
    },
    changeTimeZone(timezone) {
      this.current_time_zone = timezone;
      this.setup();
      this.getUserAvailability("timezone");
    },
    getWeekRange() {
      if (!(this.week_start && this.week_end)) {
        return "";
      }
      return getMonth(this.week_start) === getMonth(this.week_end)
        ? format(this.week_start, "MMM dd", {
            locale: this.locales[this.$i18n.locale],
          }) +
            " - " +
            format(this.week_end, "dd, Y", {
              locale: this.locales[this.$i18n.locale],
            })
        : format(this.week_start, "MMM dd", {
            locale: this.locales[this.$i18n.locale],
          }) +
            " - " +
            format(this.week_end, "MMM dd, Y", {
              locale: this.locales[this.$i18n.locale],
            });
    },
    getWeekDay(i) {
      if (this.week_start) {
        return format(add(this.week_start, { days: i }), "E d", {
          locale: this.locales[this.$i18n.locale],
        }).split(/(\s+)/);
      }
    },
    checkWeekday(i, hours = 0, am_pm = "am", minutes = 0) {
      if (this.week_start) {
        return compareAsc(
          this.date,
          add(this.week_start, {
            days: i,
            hours: hours + (am_pm === "am" || hours === 12 ? 0 : 12),
            minutes: minutes,
          })
        );
      }
    },
    availableHours(type = "full_day") {
      if (type === "full_day") {
        return this.available_hours;
      } else if (type === "morning") {
        return Object.fromEntries(
          Object.entries(this.available_hours).map(([k, v]) => [
            k,
            v.filter(
              (el) =>
                (el.time[1] !== ":" &&
                  el.am_pm === "am" &&
                  el.time >= "05:00" &&
                  el.time < "12:00") ||
                (el.time[1] === ":" &&
                  el.am_pm === "am" &&
                  el.time >= "5:00" &&
                  el.time <= "9:00")
            ),
          ])
        );
      } else if (type === "afternoon") {
        return Object.fromEntries(
          Object.entries(this.available_hours).map(([k, v]) => [
            k,
            v.filter(
              (el) =>
                (el.time[1] !== ":" &&
                  el.am_pm === "pm" &&
                  (el.time === "12:00" || el.time === "12:30")) ||
                (el.time[1] === ":" &&
                  el.am_pm === "pm" &&
                  el.time >= "1:00" &&
                  el.time < "5:00")
            ),
          ])
        );
      } else if (type === "evening") {
        return Object.fromEntries(
          Object.entries(this.available_hours).map(([k, v]) => [
            k,
            v.filter(
              (el) =>
                el.time[1] === ":" &&
                el.am_pm === "pm" &&
                el.time >= "5:00" &&
                el.time < "9:00"
            ),
          ])
        );
      } else if (type === "night") {
        return Object.fromEntries(
          Object.entries(this.available_hours).map(([k, v]) => [
            k,
            v.filter(
              (el) =>
                (el.time[1] !== ":" &&
                  el.am_pm === "pm" &&
                  el.time >= "10:00" &&
                  el.time <= "11:30") ||
                (el.time[1] === ":" &&
                  el.am_pm === "pm" &&
                  (el.time === "9:00" || el.time === "9:30")) ||
                (el.time[1] === ":" &&
                  el.am_pm === "am" &&
                  el.time >= "0:00" &&
                  el.time <= "4:30")
            ),
          ])
        );
      }
    },
    async getUserAvailability(param) {
      try {
        if (param === "current_week") {
          this.setup();
        } else if (param === "next_week") {
          this.week_start = add(this.week_start, {
            days: 7,
          });
          this.week_end = add(this.week_end, {
            days: 7,
          });
        } else if (param === "previous_week") {
          this.week_start = sub(this.week_start, {
            days: 7,
          });
          this.week_end = sub(this.week_end, {
            days: 7,
          });
        }
        let new_available_hours = {};
        let res = await SharedDataService.getUserAvailability(
          this.user_id,
          this.week_start,
          this.week_end
        );
        let events = res.data.events;
        let business_hours = res.data.business_hours;
        let timeZone = res.data.timeZone;
        let offsetDifference =
          (getTimezoneOffset(this.current_time_zone) - getTimezoneOffset(timeZone)) /
          1000;
        for (let i = 0; i < 7; i++) {
          new_available_hours[i] = [];
          const week_day = add(this.week_start, {
            days: i,
          });
          for (let j = 0; j < 24; j++) {
            for (let q = 0; q < 2; q++) {
              const minutes = 30 * q;

              const current_hour_date = add(week_day, {
                hours: j,
                minutes: minutes,
              });
              const next_hour_date = add(week_day, {
                hours: j + 1,
                minutes: minutes,
              });
              let overlap = false;
              let inside_business_hours = false;
              for (let k = 0; k < events.length; k++) {
                const event = events[k];
                if (
                  (compareAsc(parseISO(event.start), current_hour_date) >= 0 &&
                    compareAsc(parseISO(event.start), next_hour_date) === -1) ||
                  (compareAsc(parseISO(event.end), current_hour_date) === 1 &&
                    compareAsc(parseISO(event.end), next_hour_date) <= 0) ||
                  (compareAsc(parseISO(event.start), current_hour_date) <= 0 &&
                    compareAsc(parseISO(event.end), next_hour_date) >= 0)
                ) {
                  overlap = true;
                  break;
                }
              }
              for (let l = 0; l < business_hours.length; l++) {
                let business_day = add(this.week_start, {
                  days:
                    business_hours[l].daysOfWeek[0] !== 0
                      ? business_hours[l].daysOfWeek[0] - 1
                      : business_hours[l].daysOfWeek[0] + 6,
                });
                let bussiness_hour_start = add(business_day, {
                  hours: Number(business_hours[l].startTime.substring(0, 2)),
                  minutes: Number(business_hours[l].startTime.substring(3, 5)),
                  seconds: offsetDifference,
                });
                let bussiness_hour_end = add(business_day, {
                  hours: Number(business_hours[l].endTime.substring(0, 2)),
                  minutes: Number(business_hours[l].endTime.substring(3, 5)),
                  seconds: offsetDifference,
                });
                if (
                  compareAsc(bussiness_hour_start, current_hour_date) <= 0 &&
                  compareAsc(bussiness_hour_end, next_hour_date) >= 0
                ) {
                  inside_business_hours = true;
                  break;
                }
              }
              if (!overlap && inside_business_hours) {
                new_available_hours[i].push({
                  time:
                    j <= 12
                      ? `${j}:${minutes === 0 ? "00" : "30"}`
                      : `${j - 12}:${minutes === 0 ? "00" : "30"}`,
                  am_pm: j < 12 ? "am" : "pm",
                });
              }
            }
          }
        }
        this.available_hours = new_available_hours;
      } catch (e) {
        console.log(e);
      }
    },
  },
  computed: {},
  beforeDestroy() {
    EventBus.$off("locale-change");
  },
};
</script>
<style lang="scss" scoped>
@import "@/css/variables.scss";
@import "@/css/_button.scss";

.btn {
  @include blue-button;
  font-size: 12px;

  &--danger {
    @include red-button;
  }
}

.schedule {
  max-width: 100%;
  margin: auto;
  background-color: $commonBgColor;
  &__icon {
    &--time {
      margin-right: 4px;
    }
    color: white;
  }
  &__range {
    font-family: Arial, Helvetica, sans-serif;
  }
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: $lap;
  }
  &__item {
  }
  &__button {
    background-color: $buttonBgColor;
    font-size: 12px;
    &--today {
      margin-right: $f3;
    }
    &--left {
      border-radius: 0.25rem 0 0 0.25rem;
    }
    &--right {
      border-radius: 0 0.25rem 0.25rem 0;
    }
    &--right {
      border-radius: 0 0.25rem 0.25rem 0;
    }
  }
}

.timeslots {
  &__cover {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2 * $lap;
    background-image: linear-gradient(#d3d5f500, #d3d5f5);
  }
  &--disabled {
    opacity: 0.5;
  }
}

.timeslot {
  display: flex;
  gap: $f3;
  margin-top: $f4;
  margin-bottom: $f4;

  justify-content: center;
  border-radius: $gridElementBorderRadius;
  &:hover {
    cursor: pointer;
    color: white;
    background-color: $courseElementBgColor;
  }
  &:active {
    background-color: $courseElementBgColorHover;
  }
  &--active {
    background-color: $courseElementBgColorHover;
  }
  &--disabled {
    opacity: 0.5;
  }
}

.weekdays {
  &__buttons {
  }
  &__content {
    font-family: Georgia;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    overflow: hidden;
    &--mini {
      max-height: 200px;
    }
  }
}

.weekday {
  flex-basis: 100%;
  &__title {
    margin: $lap $f4;
    font-family: Arial, Helvetica, sans-serif;
    border-bottom: 0.2em solid $courseElementBgColorHover;
    text-align: center;
    white-space: nowrap;
    &--disabled {
      border-bottom: 0.2em solid white;
    }
    &--today {
      border-bottom: 0.2em solid $courseElementBgColorDark;
    }
  }
}
.weekday__title > p {
  margin-bottom: 0;
}

.switch {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: $commonBgColor;
  border-bottom: 1px solid rgba($color: $courseElementBgColor, $alpha: 1);
  &--sticky {
    top: 0;
    position: sticky;
  }
  &--justify-left {
    justify-content: flex-start;
    margin-left: $lap;
    border-bottom: 1px solid rgba($color: $courseElementBgColor, $alpha: 1);
  }
  &__button {
    border-bottom: 0.2em solid $commonBgColor;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    &:hover {
      cursor: pointer;
      border-bottom: 0.2em solid $courseElementBgColor;
    }
    &:active {
      border-bottom: 0.2em solid $courseElementBgColorHover;
    }
    &--active {
      &:hover {
        cursor: pointer;
        border-bottom: 0.2em solid $courseElementBgColorHover;
      }
      border-bottom: 0.2em solid $courseElementBgColorHover;
    }
  }
  &__text {
    padding: $f4 $f7;

    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
    font-size: $fontSizeHeader;
    &--small {
      font-size: initial;
    }
  }
}

@media screen and (max-width: 576px) {
  .timeslots {
    font-size: $fontSizeMedium;
  }

  .booking-menu__content {
    padding: 2px;
  }
  .timeslot {
    &__am-pm {
      font-size: 9px;
    }
  }

  .schedule__header {
    flex-wrap: wrap;
  }

  .switch--justify-left {
    width: 100%;
    flex-wrap: wrap;
  }
}
</style>
