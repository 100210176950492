<template lang="html">
  <div class="">
    <div :class="section_element.count >= 2 ? 'multi-image-section' : 'image-section'">
      <div class="image-container">
        <div class="container">
          <div v-if="section_element.count == 2 || section_element.count == 3">
            <div class="row p-1">
              <div
                v-for="i in section_element.count"
                :class="'col-' + 12 / section_element.count + ' p-1'"
                :key="i"
              >
                <div
                  class="multi-image-container"
                  :class="buttonClass(i)"
                  @click="changeRightAnswer(i)"
                >
                  <SectionElementImage
                    :section_element="section_element"
                    :course_id="course_id"
                    :multi_image_index="i"
                    :selectable="selectable"
                    :status="status"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="section_element.count == 4">
            <div class="row p-1">
              <div class="col-6 p-1">
                <div
                  class="multi-image-container"
                  :class="buttonClass(1)"
                  @click="changeRightAnswer(1)"
                >
                  <SectionElementImage
                    :section_element="section_element"
                    :course_id="course_id"
                    :multi_image_index="1"
                    :selectable="selectable"
                    :status="status"
                  />
                </div>
              </div>
              <div class="col-6 p-1">
                <div
                  class="multi-image-container"
                  :class="buttonClass(2)"
                  @click="changeRightAnswer(2)"
                >
                  <SectionElementImage
                    :section_element="section_element"
                    :course_id="course_id"
                    :multi_image_index="2"
                    :selectable="selectable"
                    :status="status"
                  />
                </div>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-6 p-1">
                <div
                  class="multi-image-container"
                  :class="buttonClass(3)"
                  @click="changeRightAnswer(3)"
                >
                  <SectionElementImage
                    :section_element="section_element"
                    :course_id="course_id"
                    :multi_image_index="3"
                    :selectable="selectable"
                    :status="status"
                  />
                </div>
              </div>
              <div class="col-6 p-1">
                <div
                  class="multi-image-container"
                  :class="buttonClass(4)"
                  @click="changeRightAnswer(4)"
                >
                  <SectionElementImage
                    :section_element="section_element"
                    :course_id="course_id"
                    :multi_image_index="4"
                    :selectable="selectable"
                    :status="status"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="image-captions">
          {{ section_element.meta.captions }}
        </div>
      </div>
    </div>
    <div v-if="selectable && submitable" class="" :id="collapseId">
      <div class="button-container">
        <button
          class="btn btn-primary submit-button"
          type="button"
          @click.prevent="showHint"
          data-toggle="collapse"
          :data-target="'#collapseExample' + collapseId"
          aria-expanded="false"
          :aria-controls="'collapseExample' + collapseId"
          ref="hintButton"
        >
          Show A Hint
        </button>
        <button
          class="btn btn-primary submit-button"
          type="button"
          @click.prevent="submitAnswer"
          data-toggle="collapse"
          :data-target="'#collapseExample' + collapseId + '2'"
          aria-expanded="false"
          :aria-controls="'collapseExample' + collapseId + '2'"
          ref="submitButton"
        >
          Sumbit Answer
        </button>
      </div>
      <div
        class="collapse"
        :id="'collapseExample' + collapseId"
        :data-parent="'#' + collapseId"
      >
        {{ this.hint ? this.hint : "loading the hint" }}
      </div>
      <div
        class="collapse"
        :id="'collapseExample' + collapseId + '2'"
        :data-parent="'#' + collapseId"
      >
        {{ this.answer_comment ? this.answer_comment : "loading the comment" }}
      </div>
    </div>
  </div>
</template>

<script>
import SectionElementImage from "@/components/students/SectionElementImage.vue";

import StudentDataService from "@/services/StudentDataService.js";

export default {
  components: {
    SectionElementImage,
  },
  props: {
    section_element: {
      type: Object,
      required: true,
    },
    course_id: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      default: "published",
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    submitable: {
      type: Boolean,
      default: true,
    },
    collapseId: {
      type: String,
    },
    user_input: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      hovered: false,
      img_data: "",
      show_crop_dialog: false,
      parentImgSrc: null,
      file: {},
      height: null,
      width: null,
      choosed_answer: 0,
      loading: false,
      hint: "",
      answer_comment: "",
      answer: 0,
      right_answer: 0,
      locked: false,
    };
  },
  methods: {
    buttonClass(index) {
      let hoverOn = this.locked ? " hover-off" : "";
      if (this.selectable) {
        if (this.right_answer) {
          if (this.choosed_answer == this.right_answer) {
            if (index == this.right_answer) {
              return "list-button list-button--right_answer" + hoverOn;
            } else {
              return "list-button list-button--default" + hoverOn;
            }
          } else {
            if (index == this.choosed_answer) {
              return "list-button list-button--wrong_answer" + hoverOn;
            } else if (index == this.right_answer) {
              return "list-button list-button--right_answer" + hoverOn;
            } else {
              return "list-button list-button--default" + hoverOn;
            }
          }
        } else {
          if (index == this.choosed_answer) {
            return "list-button list-button--choosed_answer" + hoverOn;
          } else {
            return "list-button list-button--default" + hoverOn;
          }
        }
      } else {
        return "multi-image-container";
      }
    },
    changeRightAnswer(index) {
      if (!this.locked) {
        this.choosed_answer = index;
      }
    },
    showHint() {
      if (this.hint) {
        return;
      }
      StudentDataService.getHint(this.course_id, this.section_element._id)
        .then((res) => {
          this.hint = res.data.hint;
          this.$refs.hintButton.click();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submitAnswer() {
      if (!this.choosed_answer) {
        return;
      }
      if (this.answer_comment) {
        return;
      }
      StudentDataService.submitAnswer(
        this.course_id,
        this.section_element._id,
        this.choosed_answer
      )
        .then((res) => {
          this.locked = true;
          this.answer_comment = res.data.answer_comment;
          this.right_answer = res.data.multi_variants_answer;
          this.$emit("update-course-progress");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    if (this.user_input) {
      this.choosed_answer = this.user_input.choosed_variant;
      this.right_answer = this.user_input.right_variant;
      this.locked = true;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/_button.scss";
@import "@/css/common/SectionElement.scss";

$imageBg: #adadff;
$imageBorder: #9999fb;
$bgColor: #fb9781;
$marginSize: 4px 0px;
.image-section {
  margin: $marginSizeSection;
  background-color: $imageBg;
  border-radius: 4px;
}

.image-description {
  font-size: 10px;
  text-align: center;
  font-family: $fontFamilyCommon;
}

.image-captions {
  font-style: italic;
  font-size: 14px;
  padding: 2px;
}

.list-button {
  &:hover {
    cursor: pointer;
  }
  &--choosed_answer {
    background: limegreen;
  }
  &--wrong_answer {
    background: firebrick;
  }
  &--right_answer {
    background: green;
  }
  &--default {
  }
  padding: 6px;
  background: $courseElementBgColorDark;
}

.hover-off {
  &:hover {
    cursor: initial;
  }
}

.multi-image-section {
  padding: 0;
}

.multi-image-container {
  &__selectable {
    padding: 6px;
    background-color: firebrick;
    border-radius: $borderRadius;
    &--selected {
      background: limegreen;
    }
    &:hover {
      cursor: pointer;
    }
  }
}

.submit-button {
  &:disabled {
    opacity: 1;
  }
  width: calc((100% - 4 * $f5) / 2);
  margin: $f4 $f5;
}
</style>
