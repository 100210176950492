<template>
  <div :class="containerClass">
    <video
      ref="videoPlayer"
      :class="videoClass"
      @keydown.stop.prevent="onKeyPress"
    ></video>
  </div>
</template>

<script>
// import video js
import videojs from "video.js";
import "video.js/dist/video-js.min.css";
import "videojs-seek-buttons";
import "videojs-seek-buttons/dist/videojs-seek-buttons.css";

//import mixins
import windowSize from "@/mixins/windowSize.js";

export default {
  name: "VideoPlayer",
  mixins: [windowSize],
  props: {
    src: {
      type: String,
      default: "",
    },
    poster: {
      type: String,
      default: "",
    },
    show_seek_buttons: {
      type: Boolean,
      default: true,
    },
    border_radius: {
      type: Boolean,
      default: false,
    },
    custom_control_bar: {
      type: Object,
      default: () => {
        return {};
      },
    },
    dimensions: {
      type: String,
      default: "vjs-16-9",
    },
  },
  data() {
    return {
      player: null,
      seek_time: 10,
      options: {
        autoplay: false,
        controls: true,
        playbackRates: [0.5, 1, 1.5, 2],
        sources: [
          {
            src: this.src, // + '#t=5',
            type: "video/mp4",
          },
        ],
        poster: this.poster,
      },
    };
  },
  mounted() {
    if (Object.keys(this.custom_control_bar).length !== 0) {
      this.options.controlBar = this.custom_control_bar;
    }
    this.player = videojs(this.$refs.videoPlayer, this.options);
    if (this.show_seek_buttons) {
      this.player.seekButtons({
        forward: this.seek_time,
        back: this.seek_time,
      });
    }
  },
  methods: {
    seek(secs) {
      let time = this.player.currentTime() + secs;

      if (time < 0) {
        time = 0;
      }

      this.player.currentTime(time);
    },

    forward(time) {
      this.seek(time);
    },

    rewind(time) {
      this.seek(-time);
    },
    onKeyPress(e) {
      if (e.keyCode == 39) {
        this.forward(this.seek_time);
      } else if (e.keyCode == 37) {
        this.rewind(this.seek_time);
      } else if (e.keyCode == 32) {
        if (this.player.paused()) {
          this.player.play();
        } else {
          this.player.pause();
        }
      }
    },
  },
  computed: {
    videoClass() {
      let base_class =
        "video-js vjs-playback-rate vjs-big-play-centered vjs-default-skin small-player";
      base_class += " " + this.dimensions;
      if (this.border_radius) {
        base_class += " " + "video-border-radius";
      }
      return base_class;
    },
    containerClass() {
      let base_class = "videojs-container";
      if (this.border_radius) {
        base_class += " " + "video-border-radius";
      }
      return base_class;
    },
  },
  watch: {
    src() {
      this.player.src({ type: "video/mp4", src: this.src });
    },
    poster() {
      this.player.poster(this.poster);
    },
  },
  beforeDestroy() {
    if (this.player) {
      this.player.src = "";
      this.player.dispose();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/variables";

.video-js .vjs-control-bar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.video-border-radius {
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;
  background-color: white !important;
}

@media screen and (max-width: 576px) {
  .small-player .skip-forward,
  .small-player .vjs-playback-rate {
    display: none;
  }
}

@media screen and (max-width: 432px) {
  .small-player .vjs-seek-button,
  .small-player .vjs-remaining-time,
  .small-player .vjs-playback-rate {
    display: none;
  }
}
</style>
