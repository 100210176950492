import { render, staticRenderFns } from "./SectionElementVideoEmbedded.vue?vue&type=template&id=28132bf1&scoped=true&lang=html"
import script from "./SectionElementVideoEmbedded.vue?vue&type=script&lang=js"
export * from "./SectionElementVideoEmbedded.vue?vue&type=script&lang=js"
import style0 from "./SectionElementVideoEmbedded.vue?vue&type=style&index=0&id=28132bf1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28132bf1",
  null
  
)

export default component.exports