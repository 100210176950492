import axios from 'axios';
import authHeader from './auth-header';

import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions';

const API_ROUTE = process.env.VUE_APP_API_URL;
const httpNoCache = axios.create({
	baseURL: API_ROUTE,
	headers: {
    'Cache-Control': 'no-cache',
    ...authHeader(),
  },
});

const http = axios.create({
	baseURL: API_ROUTE,
	headers: {
    'Cache-Control': 'no-cache',
    ...authHeader(),
  },
	adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter), { threshold: 1000 })
});


export {
  http,
  httpNoCache
}
