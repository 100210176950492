<template lang="html">
  <div class="timezone">
    <!-- <label for="country">{{ localizedTextFct("general_form", "timezone") }}</label> -->
    <select
      :id="select_id"
      class="custom-select timezone__select"
      name="timezone"
      autocomplete="timezone"
      @change.prevent="changeValue"
      v-model="selected_time_zone"
      required
    >
      <option class="timezone__option" :value="localTimeZone">
        <div>{{ localTimeZone }}</div>
        <div>{{ findLocalGMT() }}</div>
      </option>

      <option
        class="timezone__option"
        v-for="(timezone, index) in this.time_zones"
        :value="timezone"
        :key="index"
      >
        <div>{{ timezone }}</div>
        <div>{{ timezones_gmt[index] }}</div>
      </option>
    </select>
    <button
      class="reset btn"
      @click="reset"
      :disabled="selected_time_zone == localTimeZone"
    >
      {{ localizedTextFct("calendar", "local_time_zone") }}
    </button>
  </div>
</template>

<script>
const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const time_zones = [
  "Pacific/Midway",
  "Pacific/Niue",
  "Pacific/Pago_Pago",
  "Pacific/Samoa",
  "America/Adak",
  "Pacific/Rarotonga",
  "Pacific/Tahiti",
  "Pacific/Honolulu",
  "Pacific/Marquesas",
  "America/Anchorage",
  "Pacific/Gambier",
  "America/Juneau",
  "America/Nome",
  "America/Metlakatla",
  "America/Sitka",
  "America/Yakutat",
  "America/Tijuana",
  "America/Los_Angeles",
  "Pacific/Pitcairn",
  "America/Creston",
  "America/Chihuahua",
  "America/Dawson",
  "America/Dawson_Creek",
  "America/Denver",
  "America/Ensenada",
  "America/Fort_Nelson",
  "America/Hermosillo",
  "America/Vancouver",
  "America/Whitehorse",
  "America/Phoenix",
  "America/Bahia_Banderas",
  "America/Belize",
  "America/Boise",
  "America/Costa_Rica",
  "America/Chicago",
  "America/Edmonton",
  "America/El_Salvador",
  "America/Guatemala",
  "America/Managua",
  "America/Mazatlan",
  "America/Ojinaga",
  "America/Swift_Current",
  "America/Tegucigalpa",
  "America/Mexico_City",
  "America/Regina",
  "Pacific/Galapagos",
  "America/Atikokan",
  "America/Bogota",
  "America/Cancun",
  "America/Cayman",
  "America/Coral_Harbour",
  "America/Eirunepe",
  "America/Guayaquil",
  "America/Indianapolis",
  "America/Jamaica",
  "America/Lima",
  "America/Matamoros",
  "America/Menominee",
  "America/Merida",
  "America/Monterrey",
  "America/Nipigon",
  "America/Panama",
  "America/Rainy_River",
  "America/Rio_Branco",
  "America/Thunder_Bay",
  "America/Winnipeg",
  "America/New_York",
  "Pacific/Easter",
  "America/Caracas",
  "America/Anguilla",
  "America/Antigua",
  "America/Aruba",
  "America/Asuncion",
  "America/Barbados",
  "America/Boa_Vista",
  "America/Campo_Grande",
  "America/Curacao",
  "America/Cuiaba",
  "America/Detroit",
  "America/Dominica",
  "America/Grand_Turk",
  "America/Grenada",
  "America/Guadeloupe",
  "America/Guyana",
  "America/Halifax",
  "America/Havana",
  "America/La_Paz",
  "America/Manaus",
  "America/Martinique",
  "America/Montreal",
  "America/Montserrat",
  "America/Nassau",
  "America/Port_of_Spain",
  "America/Porto_Velho",
  "America/Puerto_Rico",
  "America/Santo_Domingo",
  "America/St_Kitts",
  "America/St_Lucia",
  "America/St_Thomas",
  "America/St_Vincent",
  "America/Toronto",
  "America/Tortola",
  "America/Santiago",
  "America/St_Johns",
  "America/Araguaina",
  "America/Bahia",
  "America/Belem",
  "America/Buenos_Aires",
  "America/Cayenne",
  "America/Fortaleza",
  "America/Glace_Bay",
  "America/Goose_Bay",
  "America/Godthab",
  "America/Maceio",
  "America/Moncton",
  "America/Paramaribo",
  "America/Punta_Arenas",
  "America/Recife",
  "America/Rosario",
  "America/Santarem",
  "America/Thule",
  "America/Montevideo",
  "America/Sao_Paulo",
  "Antarctica/Rothera",
  "Atlantic/Bermuda",
  "Atlantic/Stanley",
  "America/Miquelon",
  "America/Noronha",
  "Atlantic/South_Georgia",
  "Etc/GMT + 2",
  "Atlantic/Azores",
  "Atlantic/Cape_Verde",
  "UTC",
  "America/Danmarkshavn",
  "America/Scoresbysund",
  "Africa/Accra",
  "Africa/Abidjan",
  "Africa/Bamako",
  "Africa/Banjul",
  "Africa/Bissau",
  "Africa/Conakry",
  "Africa/Dakar",
  "Africa/El_Aaiun",
  "Africa/Freetown",
  "Africa/Lome",
  "Africa/Monrovia",
  "Africa/Nouakchott",
  "Africa/Ouagadougou",
  "Africa/Sao_Tome",
  "Africa/Timbuktu",
  "Antarctica/Troll",
  "Atlantic/St_Helena",
  "Atlantic/Reykjavik",
  "Europe/London",
  "Africa/Bangui",
  "Africa/Casablanca",
  "Africa/Algiers",
  "Africa/Brazzaville",
  "Africa/Douala",
  "Africa/Kinshasa",
  "Africa/Libreville",
  "Africa/Luanda",
  "Africa/Malabo",
  "Africa/Ndjamena",
  "Africa/Niamey",
  "Africa/Tunis",
  "Africa/Lagos",
  "Atlantic/Canary",
  "Atlantic/Faroe",
  "Atlantic/Madeira",
  "Europe/Belfast",
  "Europe/Berlin",
  "Europe/Budapest",
  "Europe/Dublin",
  "Europe/Guernsey",
  "Europe/Isle_of_Man",
  "Europe/Jersey",
  "Europe/Lisbon",
  "Europe/Paris",
  "Europe/Warsaw",
  "Africa/Blantyre",
  "Africa/Bujumbura",
  "Africa/Cairo",
  "Africa/Ceuta",
  "Africa/Johannesburg",
  "Africa/Gaborone",
  "Africa/Harare",
  "Africa/Khartoum",
  "Africa/Kigali",
  "Africa/Lubumbashi",
  "Africa/Lusaka",
  "Africa/Maputo",
  "Africa/Maseru",
  "Africa/Mbabane",
  "Africa/Tripoli",
  "Africa/Windhoek",
  "Asia/Amman",
  "Asia/Beirut",
  "Asia/Damascus",
  "Asia/Jerusalem",
  "Europe/Amsterdam",
  "Europe/Andorra",
  "Europe/Belgrade",
  "Europe/Brussels",
  "Europe/Copenhagen",
  "Europe/Gibraltar",
  "Europe/Istanbul",
  "Europe/Kaliningrad",
  "Europe/Kiev",
  "Europe/Ljubljana",
  "Europe/Luxembourg",
  "Europe/Madrid",
  "Europe/Malta",
  "Europe/Monaco",
  "Europe/Minsk",
  "Europe/Oslo",
  "Europe/Prague",
  "Europe/Rome",
  "Europe/Sarajevo",
  "Europe/Skopje",
  "Europe/Stockholm",
  "Europe/Tirane",
  "Europe/Vaduz",
  "Europe/Vienna",
  "Europe/Zagreb",
  "Europe/Zurich",
  "Africa/Addis_Ababa",
  "Africa/Asmara",
  "Africa/Dar_es_Salaam",
  "Africa/Djibouti",
  "Africa/Juba",
  "Africa/Kampala",
  "Africa/Mogadishu",
  "Africa/Nairobi",
  "Asia/Aden",
  "Asia/Bahrain",
  "Asia/Baghdad",
  "Asia/Famagusta",
  "Asia/Gaza",
  "Asia/Hebron",
  "Asia/Kuwait",
  "Asia/Nicosia",
  "Asia/Qatar",
  "Asia/Tel_Aviv",
  "Asia/Riyadh",
  "Europe/Athens",
  "Europe/Bucharest",
  "Europe/Chisinau",
  "Europe/Helsinki",
  "Europe/Kirov",
  "Europe/Moscow",
  "Europe/Riga",
  "Europe/Simferopol",
  "Europe/Sofia",
  "Europe/Tallinn",
  "Europe/Tiraspol",
  "Europe/Uzhgorod",
  "Europe/Vilnius",
  "Europe/Volgograd",
  "Europe/Zaporozhye",
  "Indian/Antananarivo",
  "Indian/Comoro",
  "Indian/Mayotte",
  "Asia/Tehran",
  "Asia/Baku",
  "Asia/Muscat",
  "Asia/Dubai",
  "Asia/Tbilisi",
  "Asia/Yerevan",
  "Europe/Astrakhan",
  "Europe/Samara",
  "Europe/Saratov",
  "Europe/Ulyanovsk",
  "Indian/Mahe",
  "Indian/Mauritius",
  "Indian/Reunion",
  "Asia/Kabul",
  "Antarctica/Mawson",
  "Asia/Aqtau",
  "Asia/Aqtobe",
  "Asia/Ashgabat",
  "Asia/Atyrau",
  "Asia/Dushanbe",
  "Asia/Oral",
  "Asia/Samarkand",
  "Asia/Karachi",
  "Asia/Tashkent",
  "Asia/Yekaterinburg",
  "Indian/Maldives",
  "Asia/Calcutta",
  "Asia/Colombo",
  "Asia/Kolkata",
  "Asia/Kathmandu",
  "Asia/Almaty",
  "Asia/Bishkek",
  "Asia/Kashgar",
  "Asia/Omsk",
  "Asia/Qyzylorda",
  "Asia/Thimphu",
  "Asia/Urumqi",
  "Asia/Dhaka",
  "Asia/Novosibirsk",
  "Indian/Chagos",
  "Asia/Yangon",
  "Asia/Rangoon",
  "Indian/Cocos",
  "Antarctica/Davis",
  "Asia/Barnaul",
  "Asia/Bangkok",
  "Asia/Ho_Chi_Minh",
  "Asia/Hovd",
  "Asia/Jakarta",
  "Asia/Novokuznetsk",
  "Asia/Phnom_Penh",
  "Asia/Pontianak",
  "Asia/Tomsk",
  "Asia/Vientiane",
  "Asia/Krasnoyarsk",
  "Indian/Christmas",
  "Antarctica/Casey",
  "Asia/Brunei",
  "Asia/Choibalsan",
  "Asia/Chongqing",
  "Asia/Harbin",
  "Asia/Hong_Kong",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Asia/Macau",
  "Asia/Makassar",
  "Asia/Manila",
  "Asia/Irkutsk",
  "Asia/Shanghai",
  "Asia/Singapore",
  "Asia/Taipei",
  "Asia/Ulaanbaatar",
  "Australia/Perth",
  "Asia/Pyongyang",
  "Australia/Eucla",
  "Asia/Chita",
  "Asia/Dili",
  "Asia/Jayapura",
  "Asia/Khandyga",
  "Asia/Tokyo",
  "Asia/Seoul",
  "Asia/Yakutsk",
  "Pacific/Palau",
  "Australia/Adelaide",
  "Australia/Broken_Hill",
  "Australia/Darwin",
  "Australia/Brisbane",
  "Asia/Vladivostok",
  "Australia/Hobart",
  "Australia/Lindeman",
  "Pacific/Chuuk",
  "Pacific/Saipan",
  "Pacific/Port_Moresby",
  "Australia/LHI",
  "Australia/Lord_Howe",
  "Asia/Sakhalin",
  "Asia/Srednekolymsk",
  "Asia/Magadan",
  "Australia/Currie",
  "Australia/Melbourne",
  "Australia/Sydney",
  "Pacific/Efate",
  "Pacific/Bougainville",
  "Pacific/Guadalcanal",
  "Pacific/Kosrae",
  "Pacific/Norfolk",
  "Pacific/Noumea",
  "Pacific/Pohnpei",
  "Asia/Anadyr",
  "Asia/Kamchatka",
  "Etc/GMT - 12",
  "Pacific/Funafuti",
  "Pacific/Kwajalein",
  "Pacific/Majuro",
  "Pacific/Nauru",
  "Pacific/Tarawa",
  "Pacific/Wake",
  "Pacific/Wallis",
  "Pacific/Auckland",
  "Pacific/Fiji",
  "Pacific/Chatham",
  "Pacific/Enderbury",
  "Pacific/Fakaofo",
  "Pacific/Tongatapu",
  "Pacific/Apia",
  "Pacific/Kiritimati",
];

const timezonesgmt = [
  "(GMT-11:00) Midway",

  "(GMT-11:00) Niue",

  "(GMT-11:00) Pago_Pago",

  "(GMT-11:00) Samoa",

  "(GMT-10:00) Adak",

  "(GMT-10:00) Rarotonga",

  "(GMT-10:00) Tahiti",

  "(GMT-10:00) Hawaii",

  "(GMT-09:30) Marquesas Islands",

  "(GMT-09:00) Alaska",

  "(GMT-09:00) Gambier",

  "(GMT-08:00) Juneau",

  "(GMT-08:00) Nome",

  "(GMT-08:00) Metlakatla",

  "(GMT-08:00) Sitka",

  "(GMT-08:00) Yakutat",

  "(GMT-08:00) Baja California",

  "(GMT-08:00) Pacific Time (US & Canada)",

  "(GMT-08:00) Pitcairn",

  "(GMT-07:00) Creston",

  "(GMT-07:00) Chihuahua, Mazatlan",

  "(GMT-07:00) Dawson",

  "(GMT-07:00) Dawson Creek",

  "(GMT-07:00) Mountain Time (US & Canada)",

  "(GMT-07:00) Ensenada",

  "(GMT-07:00) Fort Nelson",

  "(GMT-07:00) Hermosillo",

  "(GMT-07:00) Vancouver",

  "(GMT-07:00) Whitehorse",

  "(GMT-07:00) Arizona",

  "(GMT-06:00) Bahia Banderas",

  "(GMT-06:00) Belize",

  "(GMT-06:00) Boise",

  "(GMT-06:00) Costa Rica",

  "(GMT-06:00) Central Time (US & Canada)",

  "(GMT-06:00) Edmonton",

  "(GMT-06:00) El Salvador",

  "(GMT-06:00) Central America",

  "(GMT-06:00) Managua",

  "(GMT-06:00) Mazatlan",

  "(GMT-06:00) Ojinaga",

  "(GMT-06:00) Swift Current",

  "(GMT-06:00) Tegucigalpa",

  "(GMT-06:00) Guadalajara, Mexico City, Monterrey",

  "(GMT-06:00) Saskatchewan",

  "(GMT-06:00) Galapagos",

  "(GMT-05:00) Atikokan",

  "(GMT-05:00) Bogota, Lima, Quito",

  "(GMT-05:00) Cancun",

  "(GMT-05:00) Cayman",

  "(GMT-05:00) Coral Harbour",

  "(GMT-05:00) Eirunepe",

  "(GMT-05:00) Guayaquil",

  "(GMT-05:00) Indiana (East)",

  "(GMT-05:00) Jamaica",

  "(GMT-05:00) Lima",

  "(GMT-05:00) Matamoros",

  "(GMT-05:00) Menominee",

  "(GMT-05:00) Merida",

  "(GMT-05:00) Monterrey",

  "(GMT-05:00) Nipigon",

  "(GMT-05:00) Panama",

  "(GMT-05:00) Rainy River",

  "(GMT-05:00) Rio Branco",

  "(GMT-05:00) Thunder Bay",

  "(GMT-05:00) Winnipeg",

  "(GMT-05:00) Eastern Time (US & Canada)",

  "(GMT-05:00) Easter",

  "(GMT-04:30) Caracas",

  "(GMT-04:00) Anguilla",

  "(GMT-04:00) Antigua",

  "(GMT-04:00) Aruba",

  "(GMT-04:00) Asuncion",

  "(GMT-04:00) Barbados",

  "(GMT-04:00) Boa Vista",

  "(GMT-04:00) Campo Grande",

  "(GMT-04:00) Curacao",

  "(GMT-04:00) Cuiaba",

  "(GMT-04:00) Detroit",

  "(GMT-04:00) Dominica",

  "(GMT-04:00) Grand Turk",

  "(GMT-04:00) Grenada",

  "(GMT-04:00) Guadeloupe",

  "(GMT-04:00) Guyana",

  "(GMT-04:00) Atlantic Time (Canada)",

  "(GMT-04:00) Havana",

  "(GMT-04:00) Georgetown, La Paz, Manaus, San Juan",

  "(GMT-04:00) Manaus",

  "(GMT-04:00) Martinique",

  "(GMT-04:00) Montreal",

  "(GMT-04:00) Montserrat",

  "(GMT-04:00) Nassau",

  "(GMT-04:00) Port of Spain",

  "(GMT-04:00) Porto Velho",

  "(GMT-04:00) Puerto Rico",

  "(GMT-04:00) Santo Domingo",

  "(GMT-04:00) St. Kitts",

  "(GMT-04:00) St. Lucia",

  "(GMT-04:00) St. Thomas",

  "(GMT-04:00) St. Vincent",

  "(GMT-04:00) Toronto",

  "(GMT-04:00) Tortola",

  "(GMT-04:00) Santiago",

  "(GMT-03:30) Newfoundland",

  "(GMT-03:00) Araguaina",

  "(GMT-03:00) Bahia",

  "(GMT-03:00) Belem",

  "(GMT-03:00) Buenos Aires",

  "(GMT-03:00) Cayenne, Fortaleza",

  "(GMT-03:00) Fortaleza",

  "(GMT-03:00) Glace Bay",

  "(GMT-03:00) Goose Bay",

  "(GMT-03:00) Greenland",

  "(GMT-03:00) Maceio",

  "(GMT-03:00) Moncton",

  "(GMT-03:00) Paramaribo",

  "(GMT-03:00) Punta Arenas",

  "(GMT-03:00) Recife",

  "(GMT-03:00) Rosario",

  "(GMT-03:00) Santarem",

  "(GMT-03:00) Thule",

  "(GMT-03:00) Montevideo",

  "(GMT-03:00) Brasilia",

  "(GMT-03:00) Rothera",

  "(GMT-03:00) Bermuda",

  "(GMT-03:00) Stanley",

  "(GMT-02:00) Miquelon",

  "(GMT-02:00) Noronha",

  "(GMT-02:00) South Georgia",

  "(GMT-02:00) Coordinated Universal Time-02",

  "(GMT-01:00) Azores",

  "(GMT-01:00) Cape Verde",

  "(GMT+00:00) Default",

  "(GMT+00:00) Danmarkshavn",

  "(GMT+00:00) Scoresbysund",

  "(GMT+00:00) Accra",

  "(GMT+00:00) Abidjan",

  "(GMT+00:00) Bamako",

  "(GMT+00:00) Banjul",

  "(GMT+00:00) Bissau",

  "(GMT+00:00) Conakry",

  "(GMT+00:00) Dakar",

  "(GMT+00:00) El Aaiun",

  "(GMT+00:00) Freetown",

  "(GMT+00:00) Lome",

  "(GMT+00:00) Monrovia",

  "(GMT+00:00) Nouakchott",

  "(GMT+00:00) Ouagadougou",

  "(GMT+00:00) Sao Tome",

  "(GMT+00:00) Timbuktu",

  "(GMT+00:00) Troll",

  "(GMT+00:00) St. Helena",

  "(GMT+00:00) Monrovia, Reykjavik",

  "(GMT+00:00) Greenwich Mean Time, Dublin, Edinburgh, Lisbon, London",

  "(GMT+01:00) Bangui",

  "(GMT+01:00) Casablanca",

  "(GMT+01:00) Algiers",

  "(GMT+01:00) Brazzaville",

  "(GMT+01:00) Douala",

  "(GMT+01:00) Kinshasa",

  "(GMT+01:00) Libreville",

  "(GMT+01:00) Luanda",

  "(GMT+01:00) Malabo",

  "(GMT+01:00) Ndjamena",

  "(GMT+01:00) Niamey",

  "(GMT+01:00) Tunis",

  "(GMT+01:00) West Central Africa",

  "(GMT+01:00) Canary",

  "(GMT+01:00) Faroe",

  "(GMT+01:00) Madeira",

  "(GMT+01:00) Belfast",

  "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",

  "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",

  "(GMT+01:00) Dublin",

  "(GMT+01:00) Guernsey",

  "(GMT+01:00) Isle of Man",

  "(GMT+01:00) Jersey",

  "(GMT+01:00) Lisbon",

  "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",

  "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb",

  "(GMT+02:00) Blantyre",

  "(GMT+02:00) Bujumbura",

  "(GMT+02:00) Cairo",

  "(GMT+02:00) Ceuta",

  "(GMT+02:00) Johannesburg",

  "(GMT+02:00) Gaborone",

  "(GMT+02:00) Harare",

  "(GMT+02:00) Khartoum",

  "(GMT+02:00) Kigali",

  "(GMT+02:00) Lubumbashi",

  "(GMT+02:00) Lusaka",

  "(GMT+02:00) Maputo",

  "(GMT+02:00) Maseru",

  "(GMT+02:00) Mbabane",

  "(GMT+02:00) Tripoli",

  "(GMT+02:00) Windhoek",

  "(GMT+02:00) Amman",

  "(GMT+02:00) Beirut",

  "(GMT+02:00) Damascus",

  "(GMT+02:00) Jerusalem",

  "(GMT+02:00) Amsterdam",

  "(GMT+02:00) Andorra",

  "(GMT+02:00) Belgrade",

  "(GMT+02:00) Brussels",

  "(GMT+02:00) Copenhagen",

  "(GMT+02:00) Gibraltar",

  "(GMT+02:00) Athens, Bucharest, Istanbul",

  "(GMT+02:00) Kaliningrad",

  "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",

  "(GMT+02:00) Ljubljana",

  "(GMT+02:00) Luxembourg",

  "(GMT+02:00) Madrid",

  "(GMT+02:00) Malta",

  "(GMT+02:00) Monaco",

  "(GMT+02:00) Minsk",

  "(GMT+02:00) Oslo",

  "(GMT+02:00) Prague",

  "(GMT+02:00) Rome",

  "(GMT+02:00) Sarajevo",

  "(GMT+02:00) Skopje",

  "(GMT+02:00) Stockholm",

  "(GMT+02:00) Tirane",

  "(GMT+02:00) Vaduz",

  "(GMT+02:00) Vienna",

  "(GMT+02:00) Zagreb",

  "(GMT+02:00) Zurich",

  "(GMT+03:00) Addis Ababa",

  "(GMT+03:00) Asmara",

  "(GMT+03:00) Dar es Salaam",

  "(GMT+03:00) Djibouti",

  "(GMT+03:00) Juba",

  "(GMT+03:00) Kampala",

  "(GMT+03:00) Mogadishu",

  "(GMT+03:00) Nairobi",

  "(GMT+03:00) Aden",

  "(GMT+03:00) Bahrain",

  "(GMT+03:00) Baghdad",

  "(GMT+03:00) Famagusta",

  "(GMT+03:00) Gaza",

  "(GMT+03:00) Hebron",

  "(GMT+03:00) Kuwait",

  "(GMT+03:00) Nicosia",

  "(GMT+03:00) Qatar",

  "(GMT+03:00) Tel Aviv",

  "(GMT+03:00) Kuwait, Riyadh",

  "(GMT+03:00) Athens",

  "(GMT+03:00) Bucharest",

  "(GMT+03:00) Chisinau",

  "(GMT+03:00) Helsinki",

  "(GMT+03:00) Kirov",

  "(GMT+03:00) Moscow, St. Petersburg, Volgograd",

  "(GMT+03:00) Riga",

  "(GMT+03:00) Simferopol",

  "(GMT+03:00) Sofia",

  "(GMT+03:00) Tallinn",

  "(GMT+03:00) Tiraspol",

  "(GMT+03:00) Uzhgorod",

  "(GMT+03:00) Vilnius",

  "(GMT+03:00) Volgograd",

  "(GMT+03:00) Zaporozhye",

  "(GMT+03:00) Antananarivo",

  "(GMT+03:00) Comoro",

  "(GMT+03:00) Mayotte",

  "(GMT+03:30) Tehran",

  "(GMT+04:00) Baku",

  "(GMT+04:00) Muscat",

  "(GMT+04:00) Abu Dhabi, Muscat",

  "(GMT+04:00) Tbilisi",

  "(GMT+04:00) Yerevan",

  "(GMT+04:00) Astrakhan",

  "(GMT+04:00) Samara",

  "(GMT+04:00) Saratov",

  "(GMT+04:00) Ulyanovsk",

  "(GMT+04:00) Mahe",

  "(GMT+04:00) Port Louis",

  "(GMT+04:00) Reunion",

  "(GMT+04:30) Kabul",

  "(GMT+05:00) Mawson",

  "(GMT+05:00) Aqtau",

  "(GMT+05:00) Aqtobe",

  "(GMT+05:00) Ashgabat",

  "(GMT+05:00) Atyrau",

  "(GMT+05:00) Dushanbe",

  "(GMT+05:00) Oral",

  "(GMT+05:00) Samarkand",

  "(GMT+05:00) Islamabad, Karachi",

  "(GMT+05:00) Tashkent",

  "(GMT+05:00) Yekaterinburg",

  "(GMT+05:00) Maldives",

  "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",

  "(GMT+05:30) Sri Jayawardenepura",

  "(GMT+05:30) Kolkata",

  "(GMT+05:45) Kathmandu",

  "(GMT+06:00) Astana",

  "(GMT+06:00) Bishkek",

  "(GMT+06:00) Kashgar",

  "(GMT+06:00) Omsk",

  "(GMT+06:00) Qyzylorda",

  "(GMT+06:00) Thimphu",

  "(GMT+06:00) Urumqi",

  "(GMT+06:00) Dhaka",

  "(GMT+06:00) Novosibirsk",

  "(GMT+06:00) Chagos",

  "(GMT+06:30) Yangon",

  "(GMT+06:30) Yangon (Rangoon)",

  "(GMT+06:30) Cocos",

  "(GMT+07:00) Davis",

  "(GMT+07:00) Barnaul",

  "(GMT+07:00) Bangkok, Hanoi, Jakarta",

  "(GMT+07:00) Ho Chi Minh",

  "(GMT+07:00) Hovd",

  "(GMT+07:00) Jakarta",

  "(GMT+07:00) Novokuznetsk",

  "(GMT+07:00) Phnom Penh",

  "(GMT+07:00) Pontianak",

  "(GMT+07:00) Tomsk",

  "(GMT+07:00) Vientiane",

  "(GMT+07:00) Krasnoyarsk",

  "(GMT+07:00) Christmas",

  "(GMT+08:00) Casey",

  "(GMT+08:00) Brunei",

  "(GMT+08:00) Choibalsan",

  "(GMT+08:00) Chongqing",

  "(GMT+08:00) Harbin",

  "(GMT+08:00) Hong Kong",

  "(GMT+08:00) Kuala Lumpur",

  "(GMT+08:00) Kuching",

  "(GMT+08:00) Macau",

  "(GMT+08:00) Makassar",

  "(GMT+08:00) Manila",

  "(GMT+08:00) Irkutsk",

  "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi",

  "(GMT+08:00) Kuala Lumpur, Singapore",

  "(GMT+08:00) Taipei",

  "(GMT+08:00) Ulaanbaatar",

  "(GMT+08:00) Perth",

  "(GMT+08:30) Pyongyang",

  "(GMT+08:30) Eucla",

  "(GMT+09:00) Chita",

  "(GMT+09:00) Dili",

  "(GMT+09:00) Jayapura",

  "(GMT+09:00) Khandyga",

  "(GMT+09:00) Osaka, Sapporo, Tokyo",

  "(GMT+09:00) Seoul",

  "(GMT+09:00) Yakutsk",

  "(GMT+09:00) Palau",

  "(GMT+09:30) Adelaide",

  "(GMT+09:30) Broken Hill",

  "(GMT+09:30) Darwin",

  "(GMT+10:00) Brisbane",

  "(GMT+10:00) Vladivostok",

  "(GMT+10:00) Hobart",

  "(GMT+10:00) Lindeman",

  "(GMT+10:00) Chuuk",

  "(GMT+10:00) Saipan",

  "(GMT+10:00) Guam, Port Moresby",

  "(GMT+10:30) Lord Howe Island",

  "(GMT+10:30) Lord Howe Island",

  "(GMT+11:00) Sakhalin",

  "(GMT+11:00) Srednekolymsk",

  "(GMT+11:00) Magadan, Solomon Is., New Caledonia",

  "(GMT+11:00) Currie",

  "(GMT+11:00) Melbourne",

  "(GMT+11:00) Sydney",

  "(GMT+11:00) Efate",

  "(GMT+11:00) Bougainville",

  "(GMT+11:00) Guadalcanal",

  "(GMT+11:00) Kosrae",

  "(GMT+11:00) Norfolk",

  "(GMT+11:00) Noumea",

  "(GMT+11:00) Pohnpei",

  "(GMT+12:00) Anadyr",

  "(GMT+12:00) Petropavlovsk-Kamchatsky",

  "(GMT+12:00) Coordinated Universal Time+12",

  "(GMT+12:00) Funafuti",

  "(GMT+12:00) Kwajalein",

  "(GMT+12:00) Majuro",

  "(GMT+12:00) Nauru",

  "(GMT+12:00) Tarawa",

  "(GMT+12:00) Wake",

  "(GMT+12:00) Wallis",

  "(GMT+12:00) Auckland, Wellington",

  "(GMT+12:00) Fiji",

  "(GMT+12:45) Chatham Islands",

  "(GMT+13:00) Phoenix Islands, Tokelau, Tonga",

  "(GMT+13:00) Fakaofo",

  "(GMT+13:00) Tongatapu",

  "(GMT+14:00) Apia",

  "(GMT+14:00) Line Islands",
];

export default {
  props: {
    select_id: {
      type: String,
      default: "timezone",
    },
  },
  data() {
    return {
      time_zones: time_zones,
      timezones_gmt: timezonesgmt,
      selected_time_zone: localTimeZone,
      localTimeZone: localTimeZone,
    };
  },
  methods: {
    changeValue(e) {
      this.selected_time_zone = e.target.value;
      this.$emit("change-value", e.target.value);
    },
    findLocalGMT() {
      return this.timezones_gmt[
        this.time_zones.findIndex((el) => el == this.localTimeZone)
      ];
    },
    reset() {
      this.selected_time_zone = localTimeZone;
      this.$emit("change-value", localTimeZone);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/variables.scss";
@import "@/css/_button.scss";

.btn {
  @include blue-button;
  font-size: 12px;

  &--danger {
    @include red-button;
  }
}
.timezone {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $f3;
  &__select {
    font-size: 12px;
    max-width: 200px;
    &:hover {
      cursor: pointer;
    }
  }
  &__option {
    max-width: 200px;
  }
}
label {
  font-size: 12px;
  width: 100%;
}
</style>
