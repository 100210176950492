<template lang="html">
  <div v-if="!loading">
    <div class="grid rounded grid__clickable">
      <div class="grid grid__title">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-6 grid__text">
              {{ grid_title }}
            </div>
            <div class="col-sm-6">
              <div class="search-block">
                <div class="search-container">
                  <form @submit.prevent="search">
                    <input
                      type="text"
                      :placeholder="localizedTextFct('general', 'search')"
                      name="search"
                      v-model="search_value"
                      autocomplete="search"
                    />
                    <button type="submit" :disabled="loading">
                      <font-awesome-icon icon="search" />
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid grid__body">
        <div
          v-if="!search_loaded && !loading && !elements.length"
          class="nothing-found text-center"
        >
          <div class="no-course-text my-auto">
            {{ noContent }}
          </div>
        </div>
        <div v-if="search_loaded & !elements.length" class="nothing-found text-center">
          <div class="no-course-text my-auto">
            {{ localizedTextFct("general", "nothing_found") }}
          </div>
        </div>
        <div class="row m-1 p-2 course-col">
          <div
            class="col-md-2 col-sm-4 col-6 my-2 text-center"
            v-for="(user, index) in elements"
            :key="index"
          >
            <GridElementClickable
              :element="user"
              :list_name="list_name"
              :user_type="user_type"
              :grid_content="grid_content"
              @element-clicked="$emit('element-clicked', user)"
              element_type="user"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GridElementClickable from "@/components/common/GridElementClickable.vue";

import SharedDataService from "@/services/SharedDataService.js";

export default {
  name: "UserGrid",
  components: {
    GridElementClickable,
  },
  props: {
    grid_content: {
      type: String,
      required: true,
    },
    grid_title: {
      type: String,
      required: true,
    },
    cache: {
      type: Boolean,
      default: true,
    },
    list_name: {
      type: String,
      required: true,
    },
    user_type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      elements: [],
      loading: true,
      search_loaded: true,
      type: "",
      search_value: "",
    };
  },
  methods: {
    getUser(user_id) {
      return this.elements.find((e) => e._id == user_id);
    },
    async getUsers(grid_content, cache = true) {
      try {
        let res = await SharedDataService.getTheUsers(grid_content, cache);
        if (grid_content == "pending_requests") {
          this.elements = res.data.received_requests.concat(res.data.sent_request_by_me);
        } else {
          this.elements = res.data.users;
        }
        this.loading = false;
        this.$Progress.finish();
      } catch (e) {
        console.log(e);
      }
    },
    async search() {
      try {
        this.search_loaded = false;
        let res = await SharedDataService.search(this.grid_content, this.search_value);
        if (this.grid_content == "pending_requests") {
          this.elements = res.data.received_requests.concat(res.data.sent_request_by_me);
        } else {
          this.elements = res.data.users;
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.search_loaded = true;
      }
    },
  },
  created() {
    this.getUsers(this.grid_content, this.cache);
  },
  computed: {
    noContent() {
      return this.grid_content == "friends"
        ? "No friends? Find them!"
        : "No pending requests";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/common/CourseGrid";
</style>
