class TokenService {
  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.refreshToken;
  }
  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.accessToken;
  }
  updateLocalAccessToken(token) {
    let user = JSON.parse(localStorage.getItem("user"));
    user.accessToken = token;
    localStorage.setItem("user", JSON.stringify(user));
  }
  getUser() {
    return JSON.parse(localStorage.getItem("user"));
  }
  setUser(user, user_type_info, setUserType=false) {
    localStorage.setItem("user", JSON.stringify(user));
    if (setUserType) {
      localStorage.setItem("user_type_info", JSON.stringify(user_type_info));
      localStorage.setItem("user_type", JSON.stringify(user.user_type));
    }
  }
  removeUser(type) {
    localStorage.removeItem('user');
    if(type) {
      localStorage.removeItem('user_type');
      localStorage.removeItem('user_type_info');
    }
  }
}
export default new TokenService();
