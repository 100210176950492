<template lang="html">
  <div class="course-container"
  @mouseover="hovered = true"
  @mouseleave="hovered = false">
  <div class="image-button-wrapper">
    <router-link :to="{ name: linkName, params: {id: course._id} }">
      <div class="image-container">
        <ImageItem
          :source="courseImg()"
          :size="128"
        />
      </div>
    </router-link>
    <div v-if="type === 'student' && bottom_tab === 'add_remove' || type === 'student' && bottom_tab === 'progress_bar'" class="the-border">
    </div>
    <div v-if="type === 'student' && bottom_tab === 'add_remove'" class="extra-container">
      <div class="button-container" :class="{active : hovered}">
        <span class="button-container__add-buttons">
          <transition name="fade" mode="out-in">
            <img
            @click="is_my_course = !is_my_course; addToMyCourses()"
            v-if="!is_my_course"
            class="add-button"
            src="@/assets/img/plus-24-white.png"
            alt="plus sign"
            key="emptyPluss"
            >
            <img
            @click="is_my_course = !is_my_course; removeFromMyCourses()"
            v-if="is_my_course"
            class="add-button"
            src="@/assets/img/plus-24-black.png"
            alt="plus sign"
            key="fullPluss"
            >
          </transition>
        </span>
        <span class="button-container__add-favorite-buttons">
          <transition name="fade" mode="out-in">
            <img
            @click="is_my_fav_course = !is_my_fav_course; addToMyFavoriteCourses()"
            v-if="!is_my_fav_course"
            class="favorite-button"
            src="@/assets/img/love-24-white.png"
            alt="heart sign"
            key="emptyHeart"
            >
            <img
            @click="is_my_fav_course = !is_my_fav_course; removeFromMyFavoriteCourses()"
            v-if="is_my_fav_course"
            class="favorite-button"
            src="@/assets/img/love-24-red.png"
            alt="heart sign"
            key="fullHeart"
            >
          </transition>
        </span>
      </div>
    </div>
    <div v-if="type === 'student' && bottom_tab === 'progress_bar'" class="extra-container">
      <div class="course-progress-bar">
        <div :style="{ right: (100 - progress) + '%' }" class="course-progress-bar-filler">
        </div>
        <div class="course-progress-bar-text">
          {{progress + "%"}}
        </div>
      </div>
    </div>
  </div>
    <router-link :to="{ name: linkName, params: {id: course._id} }">
      <div class="course-title">
        {{course.title}}
      </div>
    </router-link>
  </div>
</template>

<script>
import ImageItem from "@/components/common/ImageItem";

export default {
  name: 'CourseGridElement',
  props: {
    course: {
      type: Object,
      required: true
    },
    course_progress: {
      type: Object,
    },
    bottom_tab: {
      type: String,
    },
  },
  components: {
    ImageItem
  },
  data() {
    return {
      type: this.$store.state.user_module.user.user_type,
      loading: false,
      hovered: false,
      is_my_course: (this.$store.state.user_module.user.user_type == "student" && this.$store.state.user_module.user_type_info.my_courses.indexOf(this.course._id) == -1) ? false : true,
      is_my_fav_course: (this.$store.state.user_module.user.user_type == "student" && this.$store.state.user_module.user_type_info.favorite_courses.indexOf(this.course._id) == -1) ? false : true
    }
  },
  methods: {
    courseImg() {
      return process.env.VUE_APP_API_URL + "img/courses/" + this.course._id + '/' + this.course.img;
    },
    addToMyCourses() {
      if(!this.loading) {
        this.loading = true;
        this.$store.dispatch("user_module/addToMyCourses", this.course)
        .then(() => {
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        })
      }
    },
    removeFromMyCourses() {
      if(!this.loading) {
        this.loading = true;
        this.$store.dispatch("user_module/removeFromMyCourses", this.course)
        .then(() => {
          this.loading = false;
          this.$emit('removed-my-course', this.course._id);
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        })
      }
    },
    addToMyFavoriteCourses() {
      if(!this.loading) {
        this.loading = true;
        this.$store.dispatch("user_module/addToMyFavoriteCourses", this.course)
        .then(() => {
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        })
      }
    },
    removeFromMyFavoriteCourses() {
      if(!this.loading) {
        this.loading = true;
        this.$store.dispatch("user_module/removeFromMyFavoriteCourses", this.course)
        .then(() => {
          this.loading = false;
          this.$emit('removed-my-favorite-course', this.course._id);
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        })
      }
    },
    isMyCourse() {
      if (this.$store.state.user_module) {
        return this.$store.state.user_module.user_type_info.my_courses.indexOf(this.course._id) == -1 ? false : true;
      }
    },
    isMyFavoriteCourse() {
      if (this.$store.state.user_module) {
        return this.$store.state.user_module.user_type_info.favorite_courses.indexOf(this.course._id) == -1 ? false : true;
      }
    }
  },
  computed: {
    linkName() {
      return this.$store.state.user_module.user.user_type === 'student' ? 'course_overview' : 'course';
    },
    progress() {
      if (this.course_progress.status === 'finished') {
        return 100;
      }
      if (this.course.number_of_questions === 0) {
        return 0;
      }
      return Math.ceil((this.course_progress.number_of_completed_questions/this.course.number_of_questions)*100);
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/css/common/CourseGridElement';

.fade-enter-active, .fade-leave-active {
  transition: opacity .05s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.course-progress-bar-filler {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0px;
  bottom: 0px;
  background-color: lightgreen;
}

.course-progress-bar {
  z-index: 0;
  position: relative;
}

.course-progress-bar-text {
  font-size: 14px;
  font-family: $fontFamilyCommon;
}

.course-container:hover .course-progress-bar-filler {
  background-color: green;
}

</style>
