<template lang="html">
  <div class="user-field">
    <div class="user-field user-field__label">
      {{label}}
    </div>
    <div class="user-field user-field__value">
      <p class="text_field">{{value}}</p>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: String,
      required: true
    },
    field: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/css/common/UserInfoField.scss";

</style>
