<template lang="html">
  <div class="user-info container-fluid border rounded">
    <div class="read-mode">
      <UserInfoPicture :img="user.img" />
      <div class="user-info">
        <UserInfoField
          :value="user.first_name"
          field="first_name"
          :label="localizedTextFct('user_info', 'first_name')"
        />
        <UserInfoField
          :value="user.second_name"
          field="second_name"
          :label="localizedTextFct('user_info', 'second_name')"
        />
      </div>
      <div class="user-fields">
        <div
          @click="showGrid('my_courses')"
          class="user-grid my-courses"
          :class="current_grid == 'my_courses' ? 'user-grid--active' : ''"
        >
          <img class="img-button" src="@/assets/img/hat-48.png" />
          <span class="text-container">
            {{ localizedTextFct("user_info", "student_courses") }}
          </span>
        </div>
        <div
          @click="showGrid('favorite_courses')"
          class="user-grid favorite-courses"
          :class="current_grid == 'favorite_courses' ? 'user-grid--active' : ''"
        >
          <img class="img-button" src="@/assets/img/heart-outline-48.png" />
          <span class="text-container">
            {{ localizedTextFct("user_info", "favorite_courses") }}
          </span>
        </div>
        <div
          @click="showGrid('friends')"
          class="user-grid friends"
          :class="current_grid == 'friends' ? 'user-grid--active' : ''"
        >
          <img class="img-button" src="@/assets/img/friends-48.png" />
          <span class="text-container">
            {{ localizedTextFct("user_info", "friends") }}
          </span>
        </div>
        <div
          @click="showGrid('teachers')"
          class="user-grid teachers"
          :class="current_grid == 'teachers' ? 'user-grid--active' : ''"
        >
          <img class="img-button" src="@/assets/img/teacher_0.png" />
          <span class="text-container">
            {{ localizedTextFct("user_info", "teachers") }}
          </span>
        </div>
        <div @click="message" class="user-grid--message teachers">
          <img class="img-button" src="@/assets/img/chat_3.svg" />
          <span class="text-container user-grid--message-text">
            {{ localizedTextFct("user_info", "message") }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserInfoPicture from "@/components/common/UserInfoPicture";
import UserInfoField from "@/components/common/UserInfoField";

export default {
  components: {
    UserInfoField,
    UserInfoPicture,
  },
  props: {
    user: {
      type: Object,
    },
  },
  data() {
    return {
      current_grid: "my_courses",
    };
  },
  methods: {
    message() {
      this.$router.push({ name: "chat", params: { user_id: this.user._id } });
    },
    showGrid(grid_name) {
      this.current_grid = grid_name;
      this.$emit("show-grid", grid_name);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/common/UserInfo";
</style>
