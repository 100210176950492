<template lang="html">
  <div class="">
    <div class="background">
      <form @submit.prevent="withdraw">
        {{
          localizedTextFct("settings", "current_balance") +
          " " +
          $store.state.user_module.user.balance +
          "$"
        }}
        <div class="mb-2">
          <label for="amount">{{ localizedTextFct("settings", "enter_amount") }}</label>
          <input
            type="number"
            max="1000"
            min="30"
            step="0.01"
            placeholder="Price"
            name="amount"
            id="amount"
            class="form-control"
            autocomplete="amount"
            required
            v-model="amount"
            v-validate="'required|between:30,1000|decimal:2'"
          />
        </div>
        <div class="button-container">
          <button type="submit" name="button" class="btn btn-primary">
            {{ localizedTextFct("buttons", "withdraw") }}
          </button>
        </div>
        <div v-if="errors.has('amount')" class="alert alert-danger" role="alert">
          {{ localizedTextFct("settings", "enter_amount_warning") }}
        </div>
      </form>
      <div
        class="panel-container"
        v-show="$store.state.user_module.user.withdraw_request"
      >
        <Panel :text="localizedTextFct('messages', 'withdraw_request')" type="info" />
      </div>
    </div>
    <MessageBox :message_type="message_type" :messages="messages" />
  </div>
</template>

<script>
//import components
import MessageBox from "@/components/common/MessageBox.vue";
import Panel from "@/components/common/Panel.vue";

//import mixins
import handleServerMessage from "@/mixins/handleServerMessage.js";

export default {
  name: "SquareAccountBalance",
  components: {
    MessageBox,
    Panel,
  },
  mixins: [handleServerMessage],
  data() {
    return {
      amount: 35,
      messages: [],
      loading: false,
      message_type: [],
    };
  },
  methods: {
    withdraw() {
      this.loading = true;
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.$store
          .dispatch("user_module/withdraw", this.amount)
          .then(() => {})
          .catch((err) => {
            this.handleServerMessage(err, "err", 5000);
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/_button.scss";
@import "@/css/variables.scss";
$lap: 19px;
$imageBg: #adadff;
$imageBorder: #9999fb;

.btn {
  @include blue-button;
  width: 100%;
  box-shadow: 1px;
  font-size: 12px;

  &--danger {
    @include red-button;
  }
}
.background {
  background-color: $commonBgColor;
  padding: $f5 $f5;
  min-height: 190px;
  border-radius: $borderRadius;
}

.panel-container {
  margin-top: $f5;
}
</style>
