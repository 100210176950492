<template lang="html">
  <div v-if="!loading">
    <div class="grid border rounded">
      <div class="grid grid__title">
        {{ grid_title }}
      </div>
      <div class="grid grid__body">
        <div v-if="!courses.length & !loading" class="nothing-found text-center">
          <div class="no-course-text my-auto">
            {{ localizedTextFct("course_grid", "no_course_found") }}
          </div>
        </div>
        <div v-if="courses.length" class="row m-1 p-2 course-col">
          <div
            class="col-md-2 col-sm-4 col-6 my-2 text-center"
            v-for="(course, index) in courses"
            :key="index"
          >
            <CourseGridElement
              :type="type"
              :bottom_tab="bottom_tab"
              :course="course"
              :course_progress="courseProgress(course._id)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CourseGridElement from "@/components/common/CourseGridElement";

import SharedDataService from "@/services/SharedDataService.js";

export default {
  name: "CourseGrid",
  components: {
    CourseGridElement,
  },
  props: {
    course_list_name: {
      type: String,
      required: true,
    },
    parent_courses: {
      type: Array,
      default: () => {
        return [];
      },
      required: false,
    },
    type: {
      type: String,
      required: true,
    },
    bottom_tab: {
      type: String,
    },
    grid_title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      courses: [],
      course_progress: [],
      loading: true,
    };
  },
  methods: {
    getTheCourses(course_list_name) {
      SharedDataService.getTheCourses(course_list_name)
        .then((res) => {
          this.courses = res.data.courses;
          if (res.data.course_progress) {
            this.course_progress = res.data.course_progress;
          }
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    courseProgress(course_id) {
      if (this.course_progress) {
        return this.course_progress.filter((x) => x.course._id === course_id)[0];
      } else {
        return null;
      }
    },
  },
  created() {
    if (this.parent_courses.length) {
      this.courses = this.parent_courses;
      this.loading = false;
    } else {
      this.getTheCourses(this.course_list_name);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/common/CourseGrid.scss";
</style>
