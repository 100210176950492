<template lang="html">
  <div class="container-fluid" v-show="loaded">
    <div class="row main-container">
      <div class="col-md-2 rounded settings-options container-fluid">
        <SettingsOptions
          :cards="cards"
          :user_type="user_type"
          @show-options="active_panel = $event"
          :current_index="current_index"
          ref="options"
        />
      </div>
      <div class="col-md-10 settings-container">
        <MyProfile v-if="user_type === 'teacher' && active_panel === 'my_profile'" />
        <SquareStoreCreditCard
          v-show="active_panel === 'add-card'"
          @update-default-card="
            default_card_id = $event;
            getUserPaymentData(false);
          "
          @update-cards="updateCards"
        />
        <!-- <SubscriptionOptions
          :cardId="default_card_id"
          v-show="active_panel === 'subscriptions'"
          @update-payment-info="getUserPaymentData"
        /> -->
        <RegisteredCards
          :default_card_id="default_card_id"
          @update-cards="updateCards"
          @show-options="changeOption"
          @update-default-card="
            default_card_id = $event;
            getUserPaymentData(false);
          "
          v-show="active_panel === 'cards'"
          :cards="cards"
        />
        <SquareAccountBalance v-show="active_panel === 'account-balance'" />
        <Help v-show="user_type === 'teacher' && active_panel === 'help'" />
      </div>
    </div>
  </div>
</template>

<script>
import SquareStoreCreditCard from "@/components/common/SquareStoreCreditCard.vue";
// import SubscriptionOptions from "@/components/common/SubscriptionOptions.vue";
import RegisteredCards from "@/components/common/RegisteredCards.vue";
import SquareAccountBalance from "@/components/common/SquareAccountBalance.vue";

import SettingsOptions from "@/components/common/SettingsOptions.vue";

import MyProfile from "@/components/common/MyProfile.vue";

import Help from "@/components/common/Help.vue";

import PaymentDataService from "@/services/PaymentDataService.js";

export default {
  name: "settings",
  metaInfo: {
    title: "Settings",
  },
  components: {
    SquareStoreCreditCard,
    // SubscriptionOptions,
    RegisteredCards,
    SquareAccountBalance,
    MyProfile,

    Help,

    SettingsOptions,
  },
  data() {
    return {
      cards: [],
      default_card_id: "",
      user_type: JSON.parse(localStorage.getItem("user_type")),
      subscription_details: {
        trial_period: {},
      },
      billingContact: {},
      active_panel:
        this.$store.state.user_module.user.user_type === "student"
          ? "cards"
          : "my_profile",
      current_index: this.$store.state.user_module.user.user_type === "student" ? 0 : 3,
      loading: false,
      loaded: false,
    };
  },
  created() {
    if (this.$route.params.option) {
      this.active_panel = this.$route.params.option;
      if (this.active_panel == "cards") {
        this.current_index = 0;
      } else if (this.active_panel == "add-card") {
        this.current_index = 1;
      } else if (this.active_panel == "subscriptions") {
        this.current_index = 2;
      }
    }
  },
  mounted() {
    this.getUserPaymentData();
  },
  methods: {
    changeOption(e) {
      this.active_panel = e;
      this.current_index = 1;
      this.$refs.options.changeOption(1);
    },
    getUserPaymentData(get_cards = true) {
      this.loading = true;
      PaymentDataService.getUserPaymentData().then((res) => {
        if (get_cards) {
          this.cards = res.data.cards;
        }
        this.default_card_id = res.data.default_card_id;
        this.subscription_details = res.data.subscription_details;
        this.$store.commit("user_module" + "/changeDefaultCardId", {
          default_card_id: this.default_card_id,
          subscription_details: this.subscription_details,
        });
        this.billingContact = res.data.billingContact;
        this.loading = false;
        if (!this.loaded) {
          this.loaded = true;
        }
        this.$Progress.finish();
      });
    },
    updateCards(type, card) {
      if (type === "remove") {
        this.cards = this.cards.filter((c) => c.id !== card.id);
      } else if (type === "add") {
        this.cards.push(card);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/variables.scss";

.main-container {
  margin-bottom: $f5;
}

.settings-options {
  height: calc(100vh - 100px - 90px);
  padding-bottom: 4px;
}

.setting-option-container {
  background-color: #d3d5f5;
  border-radius: $borderRadius;
  padding: $f2 $f5 0px $f5;
}

@media screen and (max-width: 769px) {
  .settings-options {
    flex: auto;
    max-width: none;
    background-color: #d3d5f5;
    height: auto;
    padding-bottom: 4px;
    margin: 0px 15px 15px 15px;
  }

  .settings-container {
    flex: auto;
    max-width: none;
  }
}
</style>
