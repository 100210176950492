// ES6 import or TypeScript
import { io } from "socket.io-client";

import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL;


export default function() {
  return io(API_URL, {
    extraHeaders: authHeader(),
  })
}
