import {httpNoCache} from '@/services/httpSetup';

const LOCATION_ID = process.env.VUE_APP_SQUARE_LOCATION_ID;


class PaymentDataService {
	updateDefaultCard(cardId) {
		return httpNoCache.post('payments/update_default_card', {
			cardId: cardId,
		});
	}
	getUserPaymentData() {
		return httpNoCache.get('payments/get_payment_data', {
			withCredentials: true,
		});
	}
  cardPayment(sourceId) {
		return httpNoCache.post('payments/card_payment',
		{
			locationId: LOCATION_ID,
			sourceId: sourceId,
		})
	}
	storeCard(sourceId, verificationToken, billingContact) {
		return httpNoCache.post('payments/store_card',
		{
			locationId: LOCATION_ID,
			sourceId: sourceId,
			verificationToken: verificationToken,
			billingContact: billingContact,
		})
	}
	removeCard(cardId) {
		return httpNoCache.post('payments/remove_card', {
			cardId: cardId,
		})
	}
	subscribe(cardId) {
		return httpNoCache.post('payments/subscribe', {
			locationId: LOCATION_ID,
			cardId: cardId,
		})
	}
	unsubscribe() {
		return httpNoCache.post('payments/unsubscribe');
	}

	//Paypal service
	paypalCreateOrder(cart) {
		return httpNoCache.post('payments/paypal_create_order', {
			cart: cart,
		})
	}
	paypalCaptureOrder() {
		return httpNoCache.post('payments/paypal_capture_order', {
		})
	}
}

export default new PaymentDataService();
