<template lang="html">
  <div class="checkout">
    <div class="checkout__content">
      <div class="checkout__element info">
        <div class="info__group info__group--border info__user">
          <div class="info__item info__teacher-img">
            <ImageItem :source="imageHref" />
          </div>
          <div class="info__item info__teacher-details">
            <div class="detail detail__name">
              {{ user.first_name + " " + user.second_name }}
            </div>
            <div class="detail detail__subject">
              {{ user.description.subjects[0] }} teacher
            </div>
          </div>
        </div>
        <div class="info__group info__group--column info__group--border">
          <div class="info__item info__date">{{ formatDate(date) }}</div>
          <div class="info__item info__text info__text--small">
            {{ localizedTextFct("checkout", "date_info") }}
          </div>
        </div>
        <div class="info__item info__order">
          {{ localizedTextFct("checkout", "order") }}
        </div>

        <div class="info__group">
          <div class="info__item info__text">
            {{ localizedTextFct("checkout", "lesson_duration") }}
          </div>
          <div class="info__item info__quantity">
            <font-awesome-icon
              class="schedule__icon"
              icon="arrow-left"
              @click="reduceQuantity"
            />
          </div>
          <div class="info__item info__quantity">
            {{ "x" + final_quantity }}
          </div>
          <div class="info__item info__quantity">
            <font-awesome-icon
              class="schedule__icon"
              icon="arrow-right"
              @click="increaseQuantity"
            />
          </div>
          <div class="info__item info__price">
            {{ "$" + user.settings.tutoring_hourly_rate }}
          </div>
        </div>
        <div class="info__group">
          <div class="info__item info__text">
            {{ localizedTextFct("checkout", "taxes") }}
          </div>
          <div class="info__item info__price">
            {{ "$" + processingFees }}
          </div>
        </div>
        <div class="info__group">
          <div class="info__item info__text info__text--large">
            {{ localizedTextFct("checkout", "total") }}
          </div>
          <div class="info__item info__total">
            {{
              "$" + (user.settings.tutoring_hourly_rate * final_quantity + processingFees)
            }}
          </div>
        </div>
      </div>

      <div v-if="!loading" class="checkout__element payment">
        <div>
          <div>{{ localizedTextFct("checkout", "choose_payment_method") }}</div>
          <div class="payment__switch">
            <div
              class="payment__option"
              @click="payment_method = 'square'"
              :class="payment_method == 'square' ? 'payment__option--selected' : ''"
            >
              <font-awesome-icon :icon="['fas', 'credit-card']" />
              <div class="payment__text">Credit Card</div>
            </div>
            <div
              class="payment__option"
              @click="payment_method = 'paypal'"
              :class="payment_method == 'paypal' ? 'payment__option--selected' : ''"
            >
              <font-awesome-icon :icon="['fab', 'paypal']" />
              <div class="payment__text">Paypal</div>
            </div>
          </div>
        </div>

        <div class="h-100">
          <div class="payment__method" v-show="payment_method === 'square'">
            <div
              class="payment__element"
              v-if="
                !loading && default_card_id && !Object.keys(default_card_id).length == 0
              "
            >
              <RegisteredCards
                :default_card_id="default_card_id"
                @update-cards="updateCards"
                @show-options="changeOption"
                @update-default-card="
                  default_card_id = $event;
                  getUserPaymentData(false);
                "
                :cards="cards"
              />
            </div>
            <div
              v-if="
                !loading &&
                (!default_card_id ||
                  Object.keys(default_card_id).length == 0 ||
                  add_card_triggered)
              "
              class="payment__element"
            >
              <SquareStoreCreditCard
                :billing_address_prompt="false"
                @update-default-card="
                  default_card_id = $event;
                  getUserPaymentData(false);
                "
                @update-cards="updateCards"
              />
            </div>
            <div class="message__container" ref="message_box">
              <MessageBox :message_type="message_type" :messages="messages" />
            </div>
            <div class="payment__element payment__element--margin">
              <button
                @click="purchaseLessons()"
                class="btn payment__button"
                :disabled="!default_card_id || Object.keys(default_card_id).length == 0"
              >
                {{ localizedTextFct("checkout", "buy_lessons") }} -
                {{
                  "$" +
                  (user.settings.tutoring_hourly_rate * final_quantity + processingFees)
                }}
              </button>
            </div>
          </div>
          <div class="payment__method" v-show="payment_method === 'paypal'">
            <div class="reg-header">
              {{ localizedTextFct("settings", "pay_with_paypal") }}
            </div>
            <div class="payment__element">
              <PaypalButton
                product="lesson"
                :quantity="this.final_quantity"
                :details="{
                  teacher_id: this.user._id,
                  date: this.date,
                }"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="loading" class="spinner-container">
        <ImageSpinner :width="48" :height="48" />
      </div>
    </div>
  </div>
</template>
<script>
const API_ROUTE = process.env.VUE_APP_API_URL + "img/";
import { format } from "date-fns";
import { enGB, ru } from "date-fns/locale";

import money from "money-math";

import SquareStoreCreditCard from "@/components/common/SquareStoreCreditCard.vue";
import RegisteredCards from "@/components/common/RegisteredCards.vue";
import PaypalButton from "@/components/common/PaypalButton.vue";

import ImageItem from "@/components/common/ImageItem.vue";
import MessageBox from "@/components/common/MessageBox.vue";
import ImageSpinner from "@/components/common/ImageSpinner.vue";

import CustomError from "@/models/custom_error";

import PaymentDataService from "@/services/PaymentDataService.js";

//import mixins
import handleServerMessage from "@/mixins/handleServerMessage.js";

export default {
  name: "CheckoutLesson",
  metaInfo: {
    title: "CheckoutLesson",
  },
  components: {
    SquareStoreCreditCard,
    RegisteredCards,
    PaypalButton,

    MessageBox,
    ImageItem,
    ImageSpinner,
  },
  mixins: [handleServerMessage],
  props: {
    user: {
      type: Object,
      default: () => {
        return {
          _id: "123",
          img: "123.png",
          first_name: "Han",
          second_name: "Solo",
          description: {
            subject: "Math",
          },
        };
      },
    },
    date: {
      type: Date,
      default: () => new Date(),
    },
    quantity: {
      type: Number,
      default: 5,
    },
  },

  data() {
    return {
      cards: [],
      default_card_id: "",
      final_quantity: this.quantity,
      user_type: JSON.parse(localStorage.getItem("user_type")),
      subscription_details: {
        trial_period: {},
      },
      billingContact: {},
      loading: true,
      loaded: false,
      add_card_triggered: false,
      messages: [],
      message_type: [],
      animated: false,
      payments: {},
      payment_method: "square",

      zoom_pricing_per_minute: 0.0035,
      california_sales_tax: 10.25,

      locales: {
        en: enGB,
        ru: ru,
      },
    };
  },
  async mounted() {
    try {
      this.payments = window.Square.payments(
        process.env.VUE_APP_SQUARE_APP_ID,
        process.env.VUE_APP_SQUARE_LOCATION_ID
      );
      this.getUserPaymentData();
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    formatDate(d) {
      return format(d, "HH:mm, MMMM d, yyyy", {
        locale: this.locales[this.$i18n.locale],
      });
    },
    changeOption() {
      this.add_card_triggered = true;
    },
    updateCards(type, card) {
      if (type === "remove") {
        this.cards = this.cards.filter((c) => c.id !== card.id);
      } else if (type === "add") {
        this.cards.push(card);
        this.add_card_triggered = false;
      }
    },
    getUserPaymentData(get_cards = true) {
      this.loading = true;
      PaymentDataService.getUserPaymentData().then((res) => {
        if (get_cards) {
          this.cards = res.data.cards;
        }
        this.default_card_id = res.data.default_card_id;
        this.subscription_details = res.data.subscription_details;
        this.$store.commit("user_module" + "/changeDefaultCardId", {
          default_card_id: this.default_card_id,
          subscription_details: this.subscription_details,
        });
        this.billingContact = res.data.billingContact;
        this.loading = false;
        if (!this.loaded) {
          this.loaded = true;
        }
        this.$Progress.finish();
      });
    },
    async verifyBuyer(sourceId) {
      const verificationDetails = {
        amount: money.floatToAmount(
          this.user.settings.tutoring_hourly_rate * this.final_quantity
        ),
        billingContact: this.$store.state.user_module.user.billing_info,
        currencyCode: "USD",
        intent: "CHARGE",
      };

      const verificationResults = await this.payments.verifyBuyer(
        sourceId,
        verificationDetails
      );

      return verificationResults.token;
    },
    async purchaseLessons() {
      try {
        this.loading = true;
        if (!this.$store.state.user_module.user.default_card_id) {
          throw new CustomError("error", "No card on file");
        }
        let verificationToken = await this.verifyBuyer(
          this.$store.state.user_module.user.default_card_id
        );
        let res = await this.$store.dispatch("user_module/purchaseLessons", {
          verificationToken: verificationToken,
          teacher_id: this.user._id,
          quantity: this.final_quantity,
          date: this.date,
          type: "one_time_purchase",
          payment_method: "square",
        });
        this.handleServerMessage(res, "res", 3000);
        this.loading = false;
      } catch (e) {
        this.loading = false;
        if (
          e.message == "No card on file" ||
          (e.errors && e.errors[0] && e.errors[0].code === "NOT_FOUND")
        ) {
          this.animated = true;
          const self = this;
          setTimeout(() => {
            self.animated = false;
          }, 1000);
        }

        this.handleServerMessage(e, "err", 5000);
        this.loading = false;
      }
    },
    increaseQuantity() {
      if (this.final_quantity <= 100) {
        this.final_quantity += 1;
      }
    },
    reduceQuantity() {
      if (this.final_quantity > 1) {
        this.final_quantity -= 1;
      }
    },
  },
  computed: {
    imageHref: function () {
      return this.user.img ? API_ROUTE + this.user.img : "/img/no_photo_squared.png";
    },
    processingFees: function () {
      return Math.ceil(
        (this.user.settings.tutoring_hourly_rate *
          this.final_quantity *
          this.california_sales_tax) /
          100 +
          60 * this.final_quantity * this.zoom_pricing_per_minute
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/css/variables.scss";
@import "@/css/_button.scss";

.schedule {
  &__icon {
    &:hover {
      cursor: pointer;
    }
  }
}

.btn {
  @include blue-button;
  font-size: 12px;

  &--danger {
    @include red-button;
  }
}

.checkout {
  &__content {
    flex-direction: row;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    gap: $lap;
  }

  display: flex;
  flex-direction: row;
}

.info {
  padding: $f6;
  margin-top: $f7;
  border: 1px solid $courseElementBgColorHover;

  background-color: $commonBgColor;
  border-radius: $borderRadius;

  &__group {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    padding: $f5;

    &--border {
      border-bottom: 1px solid $courseElementBgColorHover;
    }

    &--column {
      flex-direction: column;
      padding-top: $f7;
      padding-bottom: $f7;
    }
  }

  &__item {
    padding: $f2;
    margin: $f2;
  }

  &__order,
  &__price,
  &__total {
    font-weight: 500;
  }

  &__total,
  &__text--large {
    font-size: $fontSizeHeader;
  }

  &__order {
    font-size: $fontSizeLarge;
  }

  &__date {
    font-family: $fontFamilyCommon;
    font-weight: 600;
  }

  &__text {
    font-family: $fontFamilyCommon;

    &--small {
      font-size: $fontSizeMedium;
      font-family: Arial, Helvetica, sans-serif;
    }
  }

  &__teacher-img {
    width: 128px;
    height: 128px;
  }
}

.detail {
  &__name {
    font-size: $fontSizeExtraLarge;
    font-weight: 600;
    line-height: 36px;
  }
}

.reg-header {
  text-align: center;
  margin-bottom: 21px;
  font-size: 24px;
  padding: $f5;
}

.payment {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;

  &__switch {
    display: flex;
    flex-direction: row;
    gap: $f4;
    align-items: center;
  }

  &__text {
    flex: 1;
  }

  &__option {
    flex: 1;
    font-family: $fontFamilyCommon;
    font-size: $fontSizeHeader;
    padding: 0 $f4;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    text-align: center;
    border-radius: $borderRadius;
    background-color: $courseElementBgColor;
    &:hover {
      background-color: $courseElementBgColorHover;
      cursor: pointer;
    }

    &--selected {
      background-color: $courseElementBgColorDark;
      cursor: pointer;

      &:hover {
        background-color: $courseElementBgColorDark;
        cursor: pointer;
      }
    }
  }

  &__method {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__element {
    &--margin {
      padding: 0px 8px;
      margin-top: auto;
    }
  }

  &__button {
    width: 100%;
    font-size: $fontSizeLarge;
  }
}

.spinner-container {
  width: 48px;
  height: 48px;
  margin: auto;
}

.payment-enter-active,
.payment-leave-active {
  transition: opacity 0.5s;
}

.payment-enter,
.payment-leave-to

/* .menu-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@media screen and (max-width: 768px) {
  .checkout__content {
    display: flex;
    flex-direction: column;
    align-items: normal;
  }
}
</style>
