<template lang="html">
  <div class="">
    <video-embed :params="{modestbranding: 1}" :src="section_element.url"></video-embed>
    <div class="video-element-captions">
      {{section_element.meta.captions}}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    section_element: {
      type: Object,
      required: true,
    }
  },
}
</script>

<style lang="scss" scoped>

.video-element-captions {
  font-style: italic;
  font-size: 14px;
  padding: 2px;
}

</style>
