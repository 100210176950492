import { httpNoCache } from '@/services/httpSetup';

class ZoomDataService {
  generateToken(identity, room_name) {
    return httpNoCache.post('zoom/generate_token', {
      identity: identity,
      room_name: room_name,
    });
  }

}

export default new ZoomDataService();
