<template lang="html">
  <div class="user-info container-fluid rounded no-padding">
    <div class="read-mode">
      <ImageItem :source="imageHref" />
      <div class="user-nav-button--container">
        <div @click="showGrid('about')" class="user-nav-button user-nav-button--first"
          :class="current_grid == 'about' ? 'user-nav-button--active' : ''">
          <font-awesome-icon class="user-nav-button-icon user-nav-button-icon--left" :icon="['far', 'address-card']" />
          <span class="user-nav-button--text">
            {{ localizedTextFct("user_info", "about") }}
          </span>
        </div>
        <div @click="showGrid('my_courses')" class="user-nav-button user-nav-button"
          :class="current_grid == 'my_courses' ? 'user-nav-button--active' : ''">
          <img class="user-nav-button-icon user-nav-button-icon--left" src="@/assets/img/hat-48.png" />
          <span class="user-nav-button--text">
            {{ localizedTextFct("user_info", "teacher_courses") }}
          </span>
        </div>
        <!-- <div
          @click="showGrid('my_students')"
          class="user-nav-button students"
          :class="current_grid == 'my_students' ? 'user-nav-button--active' : ''"
        >
          <img
            class="user-nav-button-icon user-nav-button-icon--left"
            src="@/assets/img/teacher_0.png"
          />
          <span class="user-nav-button--text">
            {{ localizedTextFct("user_info", "teacher_students") }}
          </span>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import ImageItem from "@/components/common/ImageItem";

const API_ROUTE = process.env.VUE_APP_API_URL + "img/";

export default {
  components: {
    ImageItem,
  },
  props: {
    user: {
      type: Object,
    },
  },
  data() {
    return {
      current_grid: "about",
    };
  },
  methods: {
    showGrid(grid_name) {
      this.current_grid = grid_name;
      this.$emit("show-grid", grid_name);
    },
    message() {
      this.$router.push({ name: "chat", params: { user_id: this.user._id } });
    },
  },
  computed: {
    imageHref: function () {
      return this.user.img ? API_ROUTE + this.user.img : "/img/no_photo_squared.png";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/variables.scss";

//@import "@/css/common/UserInfo";
.user-nav-button {
  margin-top: 2px;
  margin-bottom: 2px;
  padding-top: 4px;
  padding-bottom: 4px;
  &--last {
    margin-bottom: 0;
  }
  &--first {
    margin-top: 0;
  }
  border-radius: $borderRadius;
  &:hover {
    background-color: $courseElementBgColor;
    cursor: pointer;
  }
  &--active {
    background-color: $courseElementBgColorHover;
    &:hover {
      background-color: $courseElementBgColorHover;
    }
  }
  &--container {
    border-radius: $borderRadius;
    background-color: $commonBgColor;
    margin-top: $f5;
    padding: 8px;
  }
  //  vertical-align: middle;
  //  position: relative;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  text-align: center;
  align-items: center;

  font-family: $fontFamilyChat;
  font-size: $fontSizeHeader;
  &--text {
    flex: 1;
  }
  &-icon {
    height: 24px;
    margin-right: 4px;
    &-container {
    }
  }
}
</style>
