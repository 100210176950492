<template lang="html">
  <div
    class="text-section-element text-section-element--user-view"
    :class="this.multi_text_index !== 0 ? 'text-section-element--variant' : ''"
  >
    <div v-if="this.multi_text_index == 0" class="text-section-title">
      {{ section_element.meta.title }}
    </div>
    <p v-if="this.multi_text_index == 0">{{ section_element.text }}</p>
    <div v-if="this.multi_text_index !== 0" class="text-section-element--text">
      <div>{{ section_element.multi_text_variants[multi_text_index - 1] }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    section_element: {
      type: Object,
      required: true,
    },
    multi_text_index: {
      type: Number,
      default: 0,
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
@import "@/css/common/SectionElement.scss";
@import "@/css/common/TextSectionElement.scss";
</style>
