export default class Course {
  constructor(id, title, sections, course_index) {
    this.id = id;
    this.title = title;
    this.author = '';
    this.sections = sections;
    this.course_index = course_index;
    this.img = '';
    this.description = '';
    this.future_courses = [];
    this.preliminary_courses = [];
    this.related_courses = [];
    this.status = '';
    this.price = 0;
    this.teacher_access_type = 'free_copy';
  }
}
