<template lang="html">
  <div class="container-fluid settings-container rounded">
    <div
      class="settings-option settings-option--top"
      v-if="user_type == 'teacher'"
      @click="
        $emit('show-options', 'my_profile');
        active_index = 3;
      "
      :class="active_index === 3 ? 'settings-option--active' : ''"
    >
      {{ localizedTextFct("settings", "my_profile") }}
    </div>
    <div
      class="settings-option settings-option--top"
      @click="
        $emit('show-options', 'cards');
        active_index = 0;
      "
      :class="active_index === 0 ? 'settings-option--active' : ''"
    >
      {{ localizedTextFct("settings", "registered_cards") }}
    </div>
    <div
      class="settings-option"
      @click="
        $emit('show-options', 'add-card');
        active_index = 1;
      "
      :class="active_index === 1 ? 'settings-option--active' : ''"
    >
      {{ localizedTextFct("settings", "add_new_card") }}
    </div>
    <!-- <div
      class="settings-option"
      @click="
        $emit('show-options', 'subscriptions');
        active_index = 2;
      "
      v-if="user_type == 'student'"
      :class="active_index === 2 ? 'settings-option--active' : ''"
    >
      {{ localizedTextFct("settings", "subscriptions") }}
    </div> -->
    <div
      class="settings-option"
      @click="
        $emit('show-options', 'account-balance');
        active_index = 2;
      "
      v-if="user_type == 'teacher'"
      :class="active_index === 2 ? 'settings-option--active' : ''"
    >
      {{ localizedTextFct("settings", "account_balance") }}
    </div>
    <div
      class="settings-option"
      @click="
        $emit('show-options', 'help');
        active_index = 4;
      "
      v-if="user_type == 'teacher'"
      :class="active_index === 4 ? 'settings-option--active' : ''"
    >
      {{ localizedTextFct("settings", "help") }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cards: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
    },
    user_type: {
      type: String,
      required: true,
    },
    current_index: {
      type: Number,
    },
  },
  data() {
    return {
      active_index: this.current_index,
    };
  },
  methods: {
    changeOption(index) {
      this.active_index = index;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/_button.scss";
@import "@/css/variables.scss";

.settings {
  &-container {
    height: inherit;
    padding-top: 15px;
    background-color: #d3d5f5;
  }
  padding-bottom: 12px;
  &-option {
    padding: $f2;
    border: 2px solid black;
    border-radius: $borderRadius;
    font-size: min(1.3vw, 1rem);

    text-align: center;
    margin: 15px 0px 12px 0px;

    &--top {
      margin: 0px 0px 12px 0px;
    }

    &--active {
      background-color: #1b3df8;
      color: white;
    }

    &:hover {
      background-color: #1b3df8;
      color: white;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 768px) {
  .settings {
    &-option {
      font-size: 14px;
      min-height: 32px;
    }
  }
}
</style>
