export default class CourseCollection {
  constructor(id, title, course_collection_index) {
    this.id = id;
    this.title = title;
    this.author = '';
    this.course_collection_index = course_collection_index;
    this.img = '';
    this.description = '';
    this.status = '';
    this.price = 0;
    this.teacher_access_type = 'free_copy';
  }
}
