<template lang="html">
  <div>
    <FullCalendar :options="calendarOptions"> </FullCalendar>
  </div>
</template>
<script>
import FullCalendar from "@fullcalendar/vue";
import ruLocale from "@fullcalendar/core/locales/ru";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";

//import services
import SharedDataService from "@/services/SharedDataService.js";

import { parseISO, differenceInMinutes } from "date-fns";

import { EventBus } from "@/event-bus";

import styles from "@/css/variables.scss";

export default {
  components: {
    FullCalendar,
  },
  data() {
    return {
      calendarOptions: {
        height: "auto",
        plugins: [listPlugin, interactionPlugin],
        locales: [ruLocale],

        locale: this.$i18n.locale,
        initialView: "listMonth",
        eventSources: [
          {
            events: this.getCalendarEvents,
            id: "main_event_source",
            color: "#adadff",
            textColor: "black",
          },
        ],
        eventClick: this.handleEventClick,
        eventDidMount: function (info) {
          if (info.event.backgroundColor === styles.courseElementBgColorDark) {
            // Change background color of row
            info.el.style.backgroundColor = styles.courseElementBgColor;
          }
        },
      },
    };
  },
  mounted() {
    EventBus.$on("locale-change", () => {
      this.calendarOptions.locale = this.$i18n.locale;
    });
  },
  methods: {
    getCalendarEvents(fetchInfo, successCallback, failureCallback) {
      this.currentEvents = [];
      this.$Progress.start();
      SharedDataService.getUserEvents(fetchInfo.start, fetchInfo.end)
        .then((res) => {
          let currentDate = new Date();
          let closestNextEvent = currentDate;
          let differenceBetweenEvents = Number.POSITIVE_INFINITY;
          let changed = false;
          for (let i = 0; i < res.data.events.length; i++) {
            const event = res.data.events[i];
            let difference = differenceInMinutes(parseISO(event.end), currentDate);

            if (difference >= 0 && difference < differenceBetweenEvents) {
              differenceBetweenEvents = difference;
              closestNextEvent = event;
              changed = true;
            }
          }
          if (changed) {
            closestNextEvent.color = styles.courseElementBgColorDark;
            closestNextEvent.classNames = "fc-active-lesson";
          }
          successCallback(res.data.events);
          this.$Progress.finish();
        })
        .catch((err) => {
          failureCallback(err);
        });
    },
    handleEventClick(clickInfo) {
      this.$emit("event-clicked", clickInfo.event);
    },
  },
  beforeDestroy() {
    EventBus.$off("locale-change");
  },
};
</script>

<style lang="scss">
@import "@/css/variables.scss";
.zoom-room {
  --fc-border-color: #{$commonBgColor};
  --fc-list-event-hover-bg-color: #{$courseElementBgColorHover};
}

.fc-event {
  &:hover {
    cursor: pointer;
  }
}

.fc .fc-list-empty {
  background-color: $commonBgColor;
}

.fc-header-toolbar {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}
</style>
<style lang="scss" scoped></style>
