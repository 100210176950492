<template lang="html">
  <div>
    <div v-if="section_element.meta.question" class="text-section-question">
      {{ section_element.meta.question }}
    </div>
    <div class="pb-1">
      <div
        @click="changeAnswer(index)"
        v-for="index in section_element.count"
        class="text-variant-container text-variant-container--user-view"
        :key="index"
      >
        <slot
          name="variants"
          :edible="false"
          :choosed_answer="choosed_answer"
          :right_answer="answer"
          :index="index"
          :locked="locked"
        ></slot>
        <div class="list-item variant variant--user-view">
          <slot
            name="items"
            :section_element="section_element"
            :multi_text_index="index"
          ></slot>
        </div>
      </div>
    </div>
    <div v-if="submitable" :id="collapseId">
      <div class="button-container">
        <button
          class="btn btn-primary submit-button"
          type="button"
          @click.prevent="showHint"
          data-toggle="collapse"
          :data-target="'#collapseExample' + collapseId"
          aria-expanded="false"
          :aria-controls="'collapseExample' + collapseId"
          ref="hintButton"
        >
          Show A Hint
        </button>
        <button
          class="btn btn-primary submit-button"
          type="button"
          @click.prevent="submitAnswer"
          data-toggle="collapse"
          :data-target="'#collapseExample' + collapseId + '2'"
          aria-expanded="false"
          :aria-controls="'collapseExample' + collapseId + '2'"
          ref="submitButton"
        >
          Sumbit Answer
        </button>
      </div>
      <div
        class="collapse comment-container"
        :id="'collapseExample' + collapseId"
        :data-parent="'#' + collapseId"
      >
        {{ this.hint ? this.hint : "loading" }}
      </div>
      <div
        class="collapse comment-container"
        :id="'collapseExample' + collapseId + '2'"
        :data-parent="'#' + collapseId"
      >
        {{ this.answer_comment ? this.answer_comment : "loading" }}
      </div>
    </div>
  </div>
</template>

<script>
import StudentDataService from "@/services/StudentDataService.js";

export default {
  props: {
    section_element: {
      type: Object,
      required: true,
    },
    course_id: {
      type: String,
      required: true,
    },
    collapseId: {
      type: String,
      required: true,
    },
    submitable: {
      type: Boolean,
      default: true,
    },
    user_input: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  components: {},
  data() {
    return {
      choosed_answer: 0,
      loading: false,
      hint: "",
      answer_comment: "",
      answer: 0,
      locked: false,
    };
  },
  methods: {
    changeAnswer(index) {
      if (!this.locked) {
        this.choosed_answer = index;
      }
    },
    showHint() {
      if (this.hint) {
        return;
      }
      StudentDataService.getHint(this.course_id, this.section_element._id)
        .then((res) => {
          this.hint = res.data.hint;
          // this.$refs.hintButton.click();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submitAnswer() {
      if (!this.choosed_answer) {
        return;
      }
      if (this.answer_comment) {
        return;
      }
      StudentDataService.submitAnswer(
        this.course_id,
        this.section_element._id,
        this.choosed_answer
      )
        .then((res) => {
          this.locked = true;
          this.answer_comment = res.data.answer_comment;
          this.answer = res.data.multi_variants_answer;
          this.$emit("update-course-progress");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    if (this.user_input) {
      this.choosed_answer = this.user_input.choosed_variant;
      this.answer = this.user_input.right_variant;
      this.locked = true;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/common/SectionElementMultiText";

.submit-button {
  &:disabled {
    opacity: 1;
  }
  width: calc((100% - 4 * $f5) / 2);
  margin: $f4 $f5;
}

.comment-container {
  padding: 0px $f5;
}
</style>
