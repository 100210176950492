<template lang="html">
  <div class="">
    <div id="creditCards" class="settings registered-cards rounded" v-show="cards.length">
      <div class="reg-header">
        {{ localizedTextFct("settings", "registered_cards") }}
      </div>
      <div class="card-master" v-for="(card, index) in cards" :key="index">
        <div
          class="card-container"
          :class="default_card_id === card.id ? 'card-container--selected' : ''"
        >
          <div class="card-logo">
            <font-awesome-icon :icon="['fab', cardLogo(card.cardBrand)]" size="2x" />
            <div class="card-type">
              {{ card.cardType }}
            </div>
          </div>
          <div class="card-details">
            <div class="card-brand">
              {{ card.cardBrand ? card.cardBrand.toLowerCase() : "Visa" }}
            </div>
            <div class="card-expiration">
              {{
                "Expires " +
                (card.expMonth > 9 ? card.expMonth : "0" + card.expMonth) +
                "/" +
                card.expYear
              }}
            </div>
            <div class="card-number">
              {{ "**** **** **** " + card.last4 }}
            </div>
          </div>
          <div class="card-remove">
            <font-awesome-icon
              class="times-icon"
              icon="times"
              data-toggle="collapse"
              :data-target="'#collapseDeleteCard' + index"
              aria-expanded="false"
              :aria-controls="'collapseDeleteCard' + index"
            />
          </div>
          <div
            class="card-default-button"
            @click="updateDefaultCard(card.id)"
            v-if="default_card_id !== card.id"
          >
            {{ localizedTextFct("settings", "make_default") }}
          </div>
          <div class="card-default-button" v-else>
            {{ localizedTextFct("settings", "default") }}
          </div>
        </div>
        <div
          class="collapse"
          :id="'collapseDeleteCard' + index"
          data-parent="#creditCards"
        >
          <div class="collapse-text">
            {{ localizedTextFct("settings", "delete_card_message") }}
          </div>
          <div class="collapse-buttons">
            <button
              type="button"
              name="button"
              class="btn btn--danger add-section-button add-section-button--navigation-left"
              data-toggle="collapse"
              :data-target="'#collapseDeleteCard' + index"
              aria-expanded="false"
              :aria-controls="'collapseDeleteCard' + index"
              @click="removeCard(card.id)"
            >
              {{ localizedTextFct("general", "yes") }}
            </button>
            <button
              type="button"
              name="button"
              class="btn add-section-button add-section-button--navigation-right"
              data-toggle="collapse"
              :data-target="'#collapseDeleteCard' + index"
              aria-expanded="false"
              :aria-controls="'collapseDeleteCard' + index"
            >
              {{ localizedTextFct("general", "no") }}
            </button>
          </div>
        </div>
      </div>
      <div class="add-card-container">
        <button
          type="button"
          name="button"
          class="btn btn-primary add-card"
          @click="$emit('show-options', 'add-card')"
        >
          {{ localizedTextFct("settings", "add_another_card") }}
        </button>
      </div>
    </div>
    <div class="no-cards" v-show="!cards.length">
      No registered cards
      <div class="add-card-container">
        <button
          type="button"
          name="button"
          class="btn btn-primary add-card"
          @click="$emit('show-options', 'add-card')"
        >
          Add Card
        </button>
      </div>
    </div>
    <div class="message_container" ref="message_box">
      <MessageBox :message_type="message_type" :messages="messages" />
    </div>
  </div>
</template>

<script>
import PaymentDataService from "@/services/PaymentDataService.js";

import MessageBox from "@/components/common/MessageBox.vue";

//import mixins
import handleServerMessage from "@/mixins/handleServerMessage.js";

export default {
  components: {
    MessageBox,
  },
  mixins: [handleServerMessage],
  props: {
    cards: {
      type: Array,
      default: function () {
        return [];
      },
    },
    default_card_id: {
      type: String,
    },
    subscription_details: {
      type: Object,
    },
  },
  data() {
    return {
      messages: [],
      message_type: [],
    };
  },
  methods: {
    cardLogo(brand) {
      if (brand === "VISA") {
        return "cc-visa";
      } else if (brand === "MASTERCARD") {
        return "cc-mastercard";
      } else if (brand === "DISCOVER") {
        return "cc-discover";
      } else if (brand === "AMERICAN_EXPRESS") {
        return "cc-amex";
      } else {
        return "cc-visa";
      }
    },
    removeCard(id) {
      if (
        !this.$store.state.user_module.user.subscription_details.active &&
        this.default_card_id == id
      ) {
        PaymentDataService.removeCard(id).then((res) => {
          this.$emit("update-cards", "remove", res.data.card);
          this.$emit("update-default-card", res.data.new_default_card);
          this.handleServerMessage(res, "res", 3000);
        });
      } else {
        let err = {
          message: "Please consider unsubscribing before deleting your default card.",
          type: "error",
        };
        this.handleServerMessage(err, "err", 5000);
      }
    },
    updateDefaultCard(id) {
      PaymentDataService.updateDefaultCard(id)
        .then((res) => {
          this.$emit("update-default-card", id);
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/_button.scss";
@import "@/css/variables.scss";
$lap: 19px;
$imageBg: #adadff;
$imageBorder: #9999fb;
.btn {
  @include blue-button;
  width: 100%;
  box-shadow: 1px;
  font-size: 12px;
  &--danger {
    @include red-button;
  }
}

.registered-cards {
  background-color: #d3d5f5;
  border-radius: $borderRadius;
  padding: $f5 $f5;
}

//edit course
.add-section-button {
  &:disabled {
    opacity: 1;
  }
  position: relative;
  width: calc((100% - 4 * $f5) / 2);
  margin: $f4 $f5;
  &--navigation-left {
    width: 49%;
    margin: 1% 1% 0% 0%;
  }
  &--navigation-right {
    width: 49%;
    margin: 1% 0% 0% 1%;
  }
}

.card {
  &-master {
    background-color: inherit;
  }
  &-container {
    display: flex;
    position: relative;
    background: $courseElementBgColor;
    margin-top: 4px;
    border: 1px solid black;
    border-radius: 4px;
    &--selected {
      background: $courseElementBgColorHover;
    }
  }
  &-logo {
    margin-left: 4px;
  }
  &-details {
    margin-left: 4px;
    overflow: hidden;
  }
  &-brand {
    font-size: 12px;
    text-transform: capitalize;
  }
  &-type {
    font-size: 12px;
  }
  &-number {
    font-size: 10px;
  }
  &-expiration {
    font-size: 10px;
  }
  &-default-button {
    position: absolute;
    bottom: 2px;
    right: 2px;
    &:hover {
      cursor: pointer;
      color: red;
    }
  }
}

.times-icon {
  position: absolute;
  top: 2px;
  right: 2px;
  &:hover {
    cursor: pointer;
    color: red;
  }
}

.add-card {
  margin-top: 4px;
  width: 100%;
}

.add-card-container {
  text-align: center;
  width: 100%;
}

.no-cards {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $commonBgColor;
  color: rgba(0, 0, 0, 0.6);
  padding: 8px 8px;
  min-height: 190px;
  border-radius: $borderRadius;
}

.reg-header {
  text-align: center;
  margin-bottom: $f7;
  font-size: $fontSizeLarge;
}
</style>
