<template lang="html">
  <div class="messages-container">
    <ul class="messages">
      <transition-group name="message">
        <li
          class="list-group-item"
          :class="liClass(index)"
          v-for="(message, index) in messages"
          :key="message + index"
        >
          {{ message }}
        </li>
      </transition-group>
    </ul>
  </div>
</template>

<script>
export default {
  name: "MessageBox",
  props: {
    message_type: {
      type: [String, Array],
      required: true,
    },
    messages: {
      type: Array,
      required: true,
    },
    small: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  methods: {
    liClass(i) {
      if (this.small) {
        return this.message_type[i] + " " + "small-message";
      } else {
        return this.message_type[i];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.messages-container {
  margin-top: 4px;
}

.messages {
  display: block;
  margin: 4px 0px;
  padding: 3px 0px;
  text-align: center;
}

.success {
  color: #3c763d;
  background-color: #f1f1f1;
  border-color: #d6e9c6;
}

.info {
  color: cornflowerblue;
  background-color: #f1f1f1;
  border-color: beige;
}

.error {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.error,
.info,
.success {
  list-style-type: none;
  border-radius: 3px;
  margin: 5px 0px;
  padding: 3px 0px;
  font-size: 15px;
}

.list-group-item {
  transition: all 0.4s ease-in-out;
}

.small-message {
  font-size: 10px;
}

//transitions
.message-enter-active,
.message-leave-active {
  transition: opacity 0.25s, font-size 0.5s 0.25s, padding 0.5s 0.25s, margin 0.5s 0.25s;
}
.message-enter,
.message-leave-to {
  font-size: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
}
.message-enter-to,
.message-leave {
}
</style>
