<template>
  <div :style="cssVars" class="container-fluid preview-and-crop">
    <input ref="input" type="file" name="image" accept="image/*" @change="setImage" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-10 col-12">
          <div class="cropper-area">
            <div class="img-cropper">
              <vue-cropper
                ref="cropper"
                :aspect-ratio="aspectRatio"
                :src="parentImgSrc"
                preview=".preview"
              />
            </div>
            <div class="actions">
              <div class="first-row">
                <span class="btn-group zoom">
                  <a
                    href="#"
                    role="button"
                    :disabled="loading"
                    @click.prevent="zoom(0.2)"
                  >
                    <font-awesome-icon icon="search-plus" />
                  </a>
                  <a
                    href="#"
                    role="button"
                    :disabled="loading"
                    @click.prevent="zoom(-0.2)"
                  >
                    <font-awesome-icon icon="search-minus" />
                  </a>
                </span>
                <span class="btn-group move">
                  <a
                    href="#"
                    role="button"
                    :disabled="loading"
                    @click.prevent="move(-10, 0)"
                  >
                    <font-awesome-icon icon="arrow-left" />
                  </a>
                  <a
                    href="#"
                    role="button"
                    :disabled="loading"
                    @click.prevent="move(10, 0)"
                  >
                    <font-awesome-icon icon="arrow-right" />
                  </a>
                  <a
                    href="#"
                    role="button"
                    :disabled="loading"
                    @click.prevent="move(0, -10)"
                  >
                    <font-awesome-icon icon="arrow-up" />
                  </a>
                  <a
                    href="#"
                    role="button"
                    :disabled="loading"
                    @click.prevent="move(0, 10)"
                  >
                    <font-awesome-icon icon="arrow-down" />
                  </a>
                </span>
              </div>
              <div class="second-row">
                <span class="btn-group rotate">
                  <a
                    href="#"
                    role="button"
                    :disabled="loading"
                    @click.prevent="rotate(90)"
                  >
                    <font-awesome-icon icon="redo-alt" />
                  </a>
                  <a
                    href="#"
                    role="button"
                    :disabled="loading"
                    @click.prevent="rotate(-90)"
                  >
                    <font-awesome-icon icon="undo-alt" />
                  </a>
                </span>
                <span class="btn-group flip">
                  <a
                    ref="flipX"
                    href="#"
                    role="button"
                    :disabled="loading"
                    @click.prevent="flipX"
                  >
                    <font-awesome-icon icon="arrows-alt-h" />
                  </a>
                  <a
                    ref="flipY"
                    href="#"
                    role="button"
                    :disabled="loading"
                    @click.prevent="flipY"
                  >
                    <font-awesome-icon icon="arrows-alt-v" />
                  </a>
                </span>
                <span v-if="manyAspectRatios" class="btn-group ratio">
                  <a
                    href="#"
                    role="button"
                    :disabled="loading"
                    @click.prevent="setAspectRatio(16 / 9)"
                  >
                    16 : 9
                  </a>
                  <a
                    href="#"
                    role="button"
                    :disabled="loading"
                    @click.prevent="setAspectRatio(1 / 1)"
                  >
                    1 : 1
                  </a>
                </span>
              </div>
              <div class="third-row">
                <div class="btn-group reset-btn-group">
                  <a
                    href="#"
                    class="showFileChooser"
                    role="button"
                    :disabled="loading"
                    @click.prevent="showFileChooser"
                  >
                    Choose Another Image
                  </a>
                  <a
                    href="#"
                    class="reset"
                    role="button"
                    :disabled="loading"
                    @click.prevent="reset"
                  >
                    Reset
                  </a>
                </div>
              </div>
              <div class="forth-row">
                <div class="btn-group main-btn-group">
                  <!-- <a
                    href="#"
                    class="cropImage"
                    :class="{yellowBg : !cropButtonPressed}"
                    role="button"
                    :disabled="loading"
                    @click.prevent="cropImage"
                  >
                    Crop
                  </a> -->
                  <a
                    href="#"
                    class="showFileChooser"
                    :class="{ redBg: cropButtonPressed }"
                    role="button"
                    :disabled="loading"
                    @click.prevent="uploadImage"
                  >
                    Crop and Upload Image
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-2 col-12">
          <div class="preview-area">
            <div class="row">
              <div class="col-sm-12 col-6">
                <p class="crop-text">Preview</p>
                <div class="stretchy-wrapper preview" />
              </div>
              <div class="col-sm-12 col-6">
                <p class="crop-text">Cropped Image</p>
                <div
                  class="stretchy-wrapper cropped-image"
                  :class="{ 'stretchy-wrapper--filled': cropImg }"
                >
                  <img v-if="cropImg" :src="cropImg" alt="Cropped Image" />
                  <div v-else class="crop-placeholder" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
export default {
  name: "ImageCrop",
  props: {
    parentImgSrc: {
      type: String,
      required: true,
      default: "",
    },
    aspectRatioFromParrent: {
      type: Number,
      default: 1,
      validator: function (value) {
        if (value === 1 || value === 16 / 9) {
          return true;
        } else {
          return false;
        }
      },
    },
    imageEvent: {
      type: Object,
      default: null,
    },
    manyAspectRatios: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VueCropper,
  },
  data() {
    return {
      imgSrc: this.parentImgSrc,
      cropImg: "",
      cropCanvas: {},
      aspectRatio: this.aspectRatioFromParrent,
      cropButtonPressed: false,
    };
  },
  methods: {
    setAspectRatio(ratio) {
      this.aspectRatio = ratio;
      this.$refs.cropper.setAspectRatio(ratio);
    },
    uploadImage() {
      if (this.cropCanvas) {
        this.cropImage();
        this.$emit("upload-image", this.cropCanvas);
      }
    },
    cropImage() {
      this.cropButtonPressed = true;
      // get image data for post processing, e.g. upload or setting image src
      this.cropCanvas = this.$refs.cropper.getCroppedCanvas();
      this.cropImg = this.cropCanvas.toDataURL();
    },
    flipX() {
      const dom = this.$refs.flipX;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleX(scale);
      dom.setAttribute("data-scale", scale);
    },
    flipY() {
      const dom = this.$refs.flipY;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleY(scale);
      dom.setAttribute("data-scale", scale);
    },
    ratio(r) {
      this.aspectRatio = r;
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
    setImage(e) {
      const file = e.target.files[0];
      if (file) {
        if (file.type.indexOf("image/") === -1) {
          alert("Please select an image file");
          return;
        }
        this.$emit("set-image", file);
        if (typeof FileReader === "function") {
          const reader = new FileReader();
          reader.onload = (event) => {
            this.imgSrc = event.target.result;
            // rebuild cropperjs with the updated source
            this.$refs.cropper.replace(event.target.result);
          };
          reader.readAsDataURL(file);
        } else {
          alert("Sorry, FileReader API not supported");
        }
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
  },
  computed: {
    cssVars() {
      if (this.aspectRatio !== 0) {
        return {
          "--ratio": (1 / this.aspectRatio) * 100 + "%",
        };
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/_button.scss";

input[type="file"] {
  display: none;
}

div.stretchy-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: var(--ratio);
  &--filled {
    padding-bottom: 0;
  }
}

div.stretchy-wrapper > div {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.preview-area p {
  font-size: 1rem;
  margin: 0;
  margin-bottom: 1rem;
}

@media screen and (max-width: 576px) {
  .preview-area p {
    font-size: 0.8rem;
  }
}

.preview-area p:last-of-type {
  margin-top: 1rem;
}

.preview {
  background: $courseElementBgColor;
  width: 100%;
  overflow: hidden;
}

.crop-placeholder {
  width: 100%;
  background: $courseElementBgColor;
}

.cropped-image img {
  background: $courseElementBgColor;
  max-width: 100%;
}

.actions {
  margin-top: 1rem;
}

.btn-group a:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.btn-group {
  margin: 0px 6px;
  text-align: center;
}

.btn-group a {
  width: 28px;
}

.ratio a {
  width: 56px !important;
}

.reset-btn-group a,
.main-btn-group a {
  width: auto !important;
}

.btn-group a:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.actions a {
  display: inline-block;
  padding: 4px 6px;
  color: white;
  font-size: 12px;
  text-decoration: none;
  border: 1px solid blue;
  margin-bottom: 2px;
  @include blue-button;
}

.forth-row {
  margin-top: 20px;
}

.main-btn-group a {
  font-size: 16px;
}

@media screen and (max-width: 576px) {
  .preview-area p {
    font-size: 0.8rem;
  }
}

.yellowBg {
  background-color: #d2c73d !important;
  color: black !important;
  &:hover {
    background-color: #fceb0e !important;
  }
  &:active {
    background-color: yellow !important;
  }
}

.redBg {
  background-color: $buttonRedBgColor !important;
  color: black !important;
  &:hover {
    background-color: $buttonRedBgColorHover !important;
  }
  &:active {
    background-color: $buttonRedBgColorActive !important;
  }
  &:focus {
    background-color: $buttonBgColor !important;
    color: white !important;
    &:hover {
      background-color: $buttonBgColorHover !important;
    }
  }
}

.crop-text {
  white-space: nowrap;
}
</style>
