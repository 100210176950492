import { http, httpNoCache } from '@/services/httpSetup';

class SharedDataService {
  getTheCourses(course_list_name) {
    return http.post('shared/get_courses', {
      course_list_name: course_list_name,
    });
  }
  getTheCourseCollections(list_name) {
    return http.post('shared/get_course_collections', {
      list_name: list_name,
    });
  }
  getStudentInfo(list_name, user_id) {
    return http.get('shared/student/' + user_id + '/' + list_name);
  }
  getTeacherInfo(list_name, user_id) {
    return http.get('shared/teacher/' + user_id + '/' + list_name);
  }
  getUserAvailability(user_id, start_date, end_date) {
    return http.post('shared/get_user_availability', {
      user_id: user_id, 
      start_date: start_date,
      end_date: end_date,
    });
  }
  getTheUsers(list_name, cache = true) {
    if (cache) {
      return http.get('shared/get_users',
        {
          params: {
            grid_name: list_name
          }
        });

    }
    else {
      return httpNoCache.get('shared/get_users',
        {
          params: {
            grid_name: list_name
          }
        });

    }
  }
  getCourse(id) {
    return httpNoCache.get('shared/get_course/' + id, {
      withCredentials: true,
    });
  }
  getCourseCollection(id) {
    return httpNoCache.get('shared/get_course_collection/' + id, {
      withCredentials: true,
    });
  }
  getUser(id) {
    return http.get('shared/get_user/' + id);
  }
  getProfileInfo(id) {
    return http.get('shared/get_profile_info/' + id);
  }
  getUserTasks() {
    return httpNoCache.get('shared/get_user_tasks');
  }
  addUserTask(task) {
    return httpNoCache.put('shared/add_user_task',
      {
        task: task
      });
  }
  deleteUserTask(task_id) {
    return httpNoCache.post('shared/delete_user_task',
      {
        task_id: task_id
      });
  }
  search(grid_name, search_value) {
    return http.get('shared/search',
      {
        params: {
          grid_name: grid_name,
          search_value: search_value,
        }
      });
  }
  updateTour(tour_name, status, last_step) {
    return httpNoCache.put('shared/update_tour',
      {
        tour_name: tour_name,
        status: status,
        last_step: last_step,
      });
  }
  getUserCalendarData() {
    return httpNoCache.get("shared/get_user_calendar");
  }
  getLessonDetails(lesson_id) {
    return httpNoCache.get('shared/get_lesson_details',
      {
        params: {
          lesson_id: lesson_id,
        }
      }
    );
  }
  getUserEvents(start_date, end_date) {
    return httpNoCache.get('shared/get_user_events',
      {
        params: {
          start_date: start_date,
          end_date: end_date
        }
      }
    );
  }
  createEvent(event, type = 'time_off', lesson=null) {
    return httpNoCache.put('shared/create_event',
      {
        type: type,
        event: event,
        lesson: lesson,
      }
    );
  }
  updateEvent(event, changes) {
    return httpNoCache.put('shared/update_event',
      {
        event: event,
        changes: changes,
      }
    );
  }
  deleteEvent(event) {
    return httpNoCache.post('shared/delete_event',
      {
        event: event,
      }
    );
  }
  getMeetingToken(lesson_id) {
    return httpNoCache.post('shared/get_meeting_token',
      {
        lesson_id: lesson_id,
      }
    );
  }
  getUserLessons() {
    return httpNoCache.get('shared/get_user_lessons',
    );
  }
  getSignedUrlForm(filename, type, details) {
    return httpNoCache.post('shared/signed_form_url',
      {
        filename: filename,
        type: type,
        details: details,
      }
    );
  }
  addBusinessHour(businessHour) {
    return http.post('shared/add_business_hour', {
      businessHour: businessHour
    });
  }
  removeBusinessHour(businessHourId) {
    return http.post('shared/remove_business_hour', {
      businessHourId: businessHourId
    });
  }
}

export default new SharedDataService();
