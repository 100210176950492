<template lang="html">
  <div :class="!selectable ? 'image-section image-section--user-view' : ''">
    <div :class="!selectable ? 'image-container' : ''">
      <ImageItem
        v-if="section_element.img_href && multi_image_index == 0"
        :source="sectionElementImg(section_element.img_href)"
        :aspectRatio="getAspectRatio()"
      />
      <ImageItem
        v-if="multi_image_index != 0"
        :source="
          sectionElementImg(section_element.multi_img_hrefs[multi_image_index - 1])
        "
        :aspectRatio="getAspectRatio()"
      />
      <ImageItem
        v-if="!section_element.img_href && multi_image_index == 0"
        :source="sectionElementImg(section_element.img_href)"
        :aspectRatio="getAspectRatio()"
      />
      <div v-if="this.multi_image_index == 0" class="image-captions">
        {{ section_element.meta.captions }}
      </div>
    </div>
  </div>
</template>

<script>
import ImageItem from "@/components/common/ImageItem";

export default {
  props: {
    section_element: {
      type: Object,
      required: true,
    },
    course_id: {
      type: String,
      required: true,
    },
    multi_image_index: {
      type: Number,
      default: 0,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: "published",
    },
  },
  components: {
    ImageItem,
  },
  methods: {
    sectionElementImg(img_href) {
      return this.$store.state.user_module.user.user_type == "teacher" &&
        this.status == "under_construction"
        ? this.noPhotoSourse()
        : process.env.VUE_APP_API_URL + "img/courses/" + this.course_id + "/" + img_href;
    },
    noPhotoSourse() {
      if (this.section_element.aspect_ratio == "1/1") {
        return "/img/upload_image.png";
      } else if (this.section_element.aspect_ratio == "16/9") {
        return "/img/upload_image_sixteen_by_nine.png";
      } else {
        return "/img/upload_image_sixteen_by_nine.png";
      }
    },
    getAspectRatio() {
      if (this.section_element.aspect_ratio == "1/1") {
        return 1;
      } else if (this.section_element.aspect_ratio == "16/9") {
        return 9 / 16;
      } else {
        return 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/variables.scss";

.image-section {
  margin: $marginSizeSection;
  background-color: $commonBgColor;
  background-color: transparent;
  padding: 10px;
  border-radius: 4px;

  &--user-view {
    background-color: transparent;
  }
}

.image-description {
  font-size: 10px;
  text-align: center;
  font-family: $fontFamilyCommon;
}

.image-captions {
  font-style: italic;
  font-size: 14px;
  padding: 2px;
}
</style>
