<template lang="html">
  <div class="">
    <div class="main-container">
      <div class="store-card-header">
        {{ localizedTextFct("settings", "add_payment_method") }}
      </div>
      <form id="payment-form">
        <div v-show="billing_address_prompt" class="mb-3 section-header">
          {{ localizedTextFct("settings", "payment_method") }}
        </div>
        <div id="card-container" style="margin-top: 0" v-show="card_initialized"></div>
        <div v-if="billing_address_prompt">
          <a
            data-toggle="collapse"
            href="#billingInfoCollapse"
            role="button"
            aria-expanded="false"
            aria-controls="billingInfoCollapse"
          >
            <div class="billing-title-container">
              <div class="mb-3 mr-1 section-header">
                {{ localizedTextFct("settings", "billing_address") }}
              </div>
              <p>{{ localizedTextFct("general_form", "optional") }}</p>
            </div>
          </a>
          <div class="collapse billing-info-container" id="billingInfoCollapse">
            <div class="card card-body">
              <div class="row">
                <div class="col-md-6 mb-3">
                  <label for="first_name">{{
                    localizedTextFct("general_form", "first_name")
                  }}</label>
                  <input
                    id="first_name"
                    type="text"
                    placeholder="First Name"
                    name="first_name"
                    class="form-control"
                    autocomplete="first_name"
                    v-model="billingContact.givenName"
                  />
                  <div
                    v-if="errors.has('first_name')"
                    class="alert alert-danger"
                    role="alert"
                  >
                    First name is required!
                  </div>
                </div>

                <div class="col-md-6 mb-3">
                  <label for="last_name">{{
                    localizedTextFct("general_form", "last_name")
                  }}</label>
                  <input
                    id="last_name"
                    type="text"
                    placeholder="Last Name"
                    name="last_name"
                    autocomplete="family-name"
                    class="form-control"
                    v-model="billingContact.familyName"
                  />
                  <div
                    v-if="errors.has('last_name')"
                    class="alert alert-danger"
                    role="alert"
                  >
                    Last name is required!
                  </div>
                </div>
              </div>

              <div class="mb-3">
                <label for="address">{{
                  localizedTextFct("general_form", "address")
                }}</label>
                <input
                  id="address"
                  type="text"
                  class="form-control"
                  name="address"
                  autocomplete="address"
                  placeholder="1234 Main St"
                  v-model="billingContact.addressLines[0]"
                />
                <div
                  v-if="errors.has('last_name')"
                  class="alert alert-danger"
                  role="alert"
                >
                  Address is required!
                </div>
              </div>

              <div class="mb-3">
                <label for="address2"
                  >{{ localizedTextFct("general_form", "address_2")
                  }}<span class="text-muted">{{
                    localizedTextFct("general_form", "optional")
                  }}</span></label
                >
                <input
                  id="address2"
                  type="text"
                  class="form-control"
                  name="address2"
                  autocomplete="address-level3"
                  placeholder="Apartment or suite"
                  v-model="billingContact.addressLines[1]"
                />
              </div>

              <div class="row">
                <Alpha2CountryCodes @change-value="billingContact.country = $event" />

                <Alpha2USAStates @change-value="billingContact.state = $event" />

                <div class="col-md-4 mb-3">
                  <label for="city">{{ localizedTextFct("general_form", "city") }}</label>
                  <input
                    id="city"
                    type="text"
                    class="form-control"
                    name="city"
                    autocomplete="address-level2"
                    :placeholder="localizedTextFct('general_form', 'city')"
                    v-model="billingContact.city"
                  />
                  <div v-if="errors.has('city')" class="alert alert-danger" role="alert">
                    City is required!
                  </div>
                </div>
                <div class="col-md-3 mb-3">
                  <label for="zip">{{ localizedTextFct("general_form", "zip") }}</label>
                  <input
                    id="zip"
                    type="text"
                    class="form-control"
                    name="zip"
                    autocomplete="postal-code"
                    placeholder=""
                    v-model="billingContact.zip"
                  />
                  <div
                    v-if="errors.has('last_name')"
                    class="alert alert-danger"
                    role="alert"
                  >
                    Zip code is required!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="button-container">
          <button
            id="card-button"
            type="button"
            class="btn btn-primary"
            :disabled="loading"
            @click="handleStoreCardMethodSubmission"
          >
            {{ localizedTextFct("buttons", "add_card") }}
          </button>
          <div class="spinner-container" v-show="loading">
            <ImageSpinner />
          </div>
        </div>
      </form>
    </div>
    <div class="message_container" ref="message_box">
      <MessageBox :message_type="message_type" :messages="messages" />
    </div>
  </div>
</template>

<script>
import PaymentDataService from "@/services/PaymentDataService";

import Alpha2CountryCodes from "@/components/common/Alpha2CountryCodes.vue";
import Alpha2USAStates from "@/components/common/Alpha2USAStates.vue";

import MessageBox from "@/components/common/MessageBox.vue";
import ImageSpinner from "@/components/common/ImageSpinner";

import BillingContact from "@/models/billing_contact.js";

//import mixins
import handleServerMessage from "@/mixins/handleServerMessage.js";

export default {
  components: {
    Alpha2CountryCodes,
    Alpha2USAStates,

    MessageBox,
    ImageSpinner,
  },
  props: {
    how: {
      default: false,
    },
    billing_address_prompt: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      user_type: JSON.parse(localStorage.getItem("user_type")),
      customerId: "7QGCQ0BZ2CZZSFFWYW4HFGA1FM",
      status: "",
      statusVisibility: false,
      card: {},
      payments: {},
      billingContact: new BillingContact(),
      messages: [],
      message_type: [],
      loading: false,
      card_initialized: false,
    };
  },
  mixins: [handleServerMessage],
  async mounted() {
    if (this.how) {
      console.log("mounted");
    }
    try {
      this.payments = await window.Square.payments(
        process.env.VUE_APP_SQUARE_APP_ID,
        process.env.VUE_APP_SQUARE_LOCATION_ID
      );
      this.payments.setLocale(this.$i18n.locale);
    } catch (e) {
      this.handleServerMessage(e, "err", 5000);
    }
    this.card = await this.initializeCard(this.payments);
    this.card_initialized = true;
    this.$Progress.finish();
  },
  methods: {
    async initializeCard(payments) {
      const card = await payments.card();
      await card.attach("#card-container");
      return card;
    },
    async storeCard(token, verificationToken) {
      try {
        const paymentResponse = await PaymentDataService.storeCard(
          token,
          verificationToken,
          this.billingContact
        );
        return paymentResponse;
      } catch (e) {
        this.handleServerMessage(e, "err", 5000);
      }
    },
    async createPayment(token) {
      const paymentResponse = await PaymentDataService.payment(token);
      return paymentResponse;
    },
    async tokenize() {
      const tokenResult = await this.card.tokenize();
      if (tokenResult.status === "OK") {
        return tokenResult.token;
      } else {
        let object = {
          response: {
            data: {
              message: [],
              type: "error",
            },
          },
        };
        if (tokenResult.errors.length) {
          for (let i = 0; i < tokenResult.errors.length; i++) {
            const tokenError = tokenResult.errors[i];
            object.response.data.message.push(tokenError.message);
          }
        }
        throw object;
      }
    },
    async verifyBuyer(token) {
      const verificationDetails = {
        billingContact: this.billingContact,
        intent: "STORE",
      };

      const verificationResults = await this.payments.verifyBuyer(
        token,
        verificationDetails
      );

      return verificationResults.token;
    },
    async handleStoreCardMethodSubmission() {
      try {
        let self = this;
        self.loading = true;
        self.$validator.validateAll().then(async function (isValid) {
          try {
            if (!isValid) {
              self.loading = false;
              return;
            }

            const token = await self.tokenize();

            let verificationToken = await self.verifyBuyer(token);
            const storeCardResults = await self.storeCard(token, verificationToken);

            self.handleServerMessage(storeCardResults, "res", 3000);

            if (storeCardResults.data.new_default_card) {
              self.$emit("update-default-card", storeCardResults.data.new_default_card);
            }

            self.$emit("update-cards", "add", storeCardResults.data.card);
            self.loading = false;
          } catch (e) {
            self.loading = false;
            self.handleServerMessage(e, "err", 5000);
          }
        });
      } catch (e) {
        this.handleServerMessage(e, "err", 5000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/common/SquareForm.scss";
@import "@/css/variables.scss";
@import "@/css/_button.scss";

.btn {
  @include blue-button;
  font-size: 12px;

  &--danger {
    @include red-button;
  }
}

.billing-title-container {
  display: flex;
  align-items: baseline;
}

.billing-info-container {
  margin-bottom: 8px;
}

#payment-form {
  padding-bottom: 4px;
}

.store-card-header {
  text-align: center;
  font-size: $fontSizeLarge;
  margin-top: $f5;
  margin-bottom: $f5;
}

.form-control {
  margin-top: 0;
}

.spinner-container {
  position: absolute;
  right: 2px;
  bottom: 2px;
}

.button-container {
  position: relative;
}

.main-container {
  background: $commonBgColor;
  padding: 4px 8px;
  border-radius: $borderRadius;
}

.card-body {
  padding: 16px;
}

.section-header {
  font-size: $fontSizeHeader;
}
</style>
