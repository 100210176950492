<template lang="html">
  <div class="main-container my-3">
    <div class="row calendar">
      <div class="col-md-2 mb-3 mb-md-0">
        <div>
          <ImageItem :source="img" />
        </div>
        <div class="left-panel">
          <div class="availability-button--container">
            <button
              class="btn availability-button"
              @click="showAvailabilityCalendar"
              v-show="show_availability"
            >
              {{ localizedTextFct("calendar", "calendar") }}
            </button>
            <button
              class="btn availability-button"
              @click="showAvailabilityCalendar"
              v-show="!show_availability"
            >
              {{ localizedTextFct("calendar", "availability") }}
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-10">
        <div v-if="!show_availability" id="mainCalendar" class="calendar-main">
          <FullCalendar
            ref="mainCalendar"
            class="calendar-calendar"
            :options="calendarOptions"
          >
            <template v-slot:eventContent="arg">
              <div class="event">
                <div class="event__element">
                  <b>{{ arg.timeText }}</b>
                </div>
                <div class="event__element">
                  <i>{{ arg.event.title }}</i>
                </div>
              </div>
            </template>
          </FullCalendar>
        </div>
        <!-- <div class="calendar-main availability-calendar"> -->
        <div
          v-if="show_availability"
          id="availabilityCalendar"
          class="calendar-main availability-calendar"
        >
          <div class="calendar-title">
            {{ localizedTextFct("calendar", "business_hours") }}
          </div>
          <FullCalendar
            ref="availabilityCalendar"
            class="calendar-calendar"
            :options="availabilityCalendarOptions"
          >
            <template v-slot:eventContent="arg1">
              <div class="event">
                <div class="event__element">
                  <b>{{ arg1.timeText }}</b>
                </div>
                <div class="event__element">
                  <i>{{ arg1.event.title }}</i>
                </div>
              </div>
            </template>
          </FullCalendar>
        </div>
      </div>
    </div>
    <transition name="pop-up">
      <div class="pop-up" v-if="show_pop_up" @click.self="closeTab">
        <div class="pop-up__content">
          <div class="pop-up__element pop-up__element--header">
            <div class="pop-up__title">{{ this.popUpTitle }}</div>
            <font-awesome-icon icon="times" class="pop-up__close" @click="closeTab" />
          </div>
          <div
            class="pop-up__tab pop-up__tab--inline pop-up__tab--big"
            v-if="pop_up.current_tab == 'schedule_event'"
          >
            <div class="pop-up__element pop-up__element--header"></div>
            <div class="pop-up__element w-100">
              <UserGridClickable
                ref="teachers"
                :grid_title="localizedTextFct('grid_titles', `my_${otherUserType}s`)"
                :list_name="otherUserType + 's'"
                grid_content="lesson_plans"
                :cache="false"
                :user_type="otherUserType"
                @element-clicked="menuNext"
              />
            </div>
          </div>
          <div class="pop-up__tab" v-if="pop_up.current_tab == 'confirm_event'">
            <div class="pop-up__element">
              <div class="checkout__element info">
                <div class="info__group info__group--border info__user">
                  <div class="info__item info__teacher-img">
                    <ImageItem :source="imageHref(this.pop_up.schedule_user.img)" />
                  </div>
                  <div class="info__item info__teacher-details">
                    <div class="detail detail__name">
                      {{
                        this.pop_up.schedule_user.first_name +
                        " " +
                        this.pop_up.schedule_user.second_name
                      }}
                    </div>
                    <div
                      ifclass="detail detail__subject"
                      v-if="otherUserType === 'teacher'"
                    >
                      {{ this.pop_up.schedule_user.description.subjects[0] }} teacher
                    </div>
                  </div>
                </div>
                <div class="info__group info__group--column info__group--border">
                  <div class="info__item info__title">Event start</div>
                  <div class="info__item info__date">
                    {{ formatDate(this.pop_up.select_info.start) }}
                  </div>
                  <div class="info__item info__title">Event end</div>
                  <div class="info__item info__date">
                    {{ formatDate(this.pop_up.select_info.end) }}
                  </div>
                  <div class="info__item info__text info__text--small">
                    This date is in your local timezone
                  </div>
                </div>
                <div class="info__group info__group--column info__group--border">
                  <div class="info__item info__quantity">
                    {{
                      (this.pop_up.schedule_user.balance -
                        this.pop_up.schedule_user.scheduled_balance) /
                        this.pop_up.schedule_user.hourly_rate +
                      " hours"
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div class="pop-up__element pop-up__buttons">
              <button
                class="btn pop-up__button"
                @click="pop_up.current_tab = 'schedule_event'"
                :disabled="loading"
              >
                Go back
              </button>
              <button
                class="btn pop-up__button"
                @click="scheduleEvent"
                :disabled="loading"
              >
                Schedule the lesson
              </button>
            </div>
          </div>
          <div class="pop-up__tab" v-if="pop_up.current_tab == 'event_info'">
            <div class="pop-up__element">
              <div class="pop-up__title">{{ pop_up.event.title }}</div>
            </div>
            <div class="pop-up__element">
              <div class="info__item info__teacher-img">
                <ImageItem
                  :source="imageHref(getLessonParticipants().otherUser.user_type_id.img)"
                />
              </div>
              <div class="info__item info__teacher-details">
                <div class="detail detail__name">
                  {{
                    getLessonParticipants().otherUser.user_type_id.first_name +
                    " " +
                    getLessonParticipants().otherUser.user_type_id.second_name
                  }}
                </div>
              </div>
            </div>
            <div class="pop-up__element">
              <div class="pop-up__description" v-if="otherUserType === 'teacher'">
                {{
                  getLessonParticipants().teacherUser.user_type_id.description
                    .subjects[0] + " teacher"
                }}
              </div>
              <div class="info__item info__title">Event start</div>
              <div class="info__item info__date">
                {{ formatDate(this.pop_up.event.start) }}
              </div>
              <div class="info__item info__title">Event end</div>
              <div class="info__item info__date">
                {{ formatDate(this.pop_up.event.end) }}
              </div>
            </div>
            <div class="pop-up__element pop-up__buttons">
              <button
                v-show="joinTime"
                class="btn pop-up__button"
                @click="joinLesson"
                :disabled="loading"
              >
                Join the lesson
              </button>
            </div>
            <div class="pop-up__element pop-up__buttons">
              <button
                class="btn pop-up__button"
                @click="pop_up.current_tab = 'calendar_schedule'"
                :disabled="loading || tooLateToChange"
              >
                Reschedule the lesson
              </button>
              <button
                class="btn pop-up__button"
                @click="cancelEvent"
                :disabled="loading || tooLateToChange"
              >
                Cancel the lesson
              </button>
            </div>
          </div>
          <div
            class="pop-up__tab"
            v-if="
              pop_up.current_tab == 'calendar_schedule' &&
              this.$store.state.user_module.user
            "
          >
            <FullCalendarScheduleTime
              :mini="true"
              :user_id="this.$store.state.user_module.user._id"
              @element-clicked="menuNext"
            />
          </div>
          <div
            class="pop-up__tab"
            v-if="pop_up.current_tab == 'reschedule_confirm_pop_up'"
          >
            <div class="pop-up__element">
              <div class="pop-up__date">{{ pop_up.event.title }}</div>
              <div class="pop-up__sub-title">Previous Date</div>
              <div class="pop-up__date">
                {{
                  formatDate(this.pop_up.update_info.oldEvent.start, true) +
                  " - " +
                  formatDate(this.pop_up.update_info.oldEvent.end, true)
                }}
              </div>
              <div class="pop-up__sub-title">New Date</div>
              <div class="pop-up__date">
                {{
                  formatDate(this.pop_up.update_info.event.start, true) +
                  " - " +
                  formatDate(this.pop_up.update_info.event.end, true)
                }}
              </div>
            </div>
            <div class="pop-up__element pop-up__buttons">
              <button
                class="btn pop-up__button"
                @click="rescheduleEventPopUp"
                :disabled="loading"
              >
                Reschedule the lesson
              </button>
              <button
                class="btn pop-up__button"
                @click="closeTab(true)"
                :disabled="loading"
              >
                Cancel
              </button>
            </div>
          </div>
          <div class="pop-up__tab" v-if="pop_up.current_tab == 'reschedule_confirm'">
            <div class="pop-up__element">
              <div class="pop-up__date">{{ formatDate(this.pop_up.selected_date) }}</div>
              <div class="pop-up__date">{{ pop_up.event.title }}</div>
            </div>
            <div class="pop-up__element pop-up__buttons">
              <button
                class="btn pop-up__button"
                @click="rescheduleEvent"
                :disabled="loading"
              >
                Reschedule the lesson
              </button>
              <button
                class="btn pop-up__button"
                @click="closeTab(true)"
                :disabled="loading"
              >
                Cancel
              </button>
            </div>
          </div>
          <div class="pop-up__footer">
            <MessageBox :messages="messages" :message_type="message_type" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import FullCalendar from "@fullcalendar/vue";
import ruLocale from "@fullcalendar/core/locales/ru";
import FullCalendarScheduleTime from "@/components/common/FullCalendarScheduleTime.vue";
import UserGridClickable from "@/components/common/UserGridClickable.vue";
import ImageItem from "@/components/common/ImageItem.vue";
import MessageBox from "@/components/common/MessageBox.vue";

import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { createEventFromBusinessHour } from "@/utils/dates";

//import services
import SharedDataService from "@/services/SharedDataService.js";

//import mixins
import handleServerMessage from "@/mixins/handleServerMessage.js";
import windowSize from "@/mixins/windowSize.js";

import { EventBus } from "@/event-bus";

const API_ROUTE = process.env.VUE_APP_API_URL + "img/";

import {
  differenceInMinutes,
  add,
  sub,
  format,
  compareAsc,
  // startOfDay,
} from "date-fns";

import { enGB, ru } from "date-fns/locale";

export default {
  name: "calendar",
  metaInfo: {
    title: "Calendar",
  },
  components: {
    FullCalendar,
    FullCalendarScheduleTime,
    UserGridClickable,
    ImageItem,
    MessageBox,
  },

  mixins: [windowSize, handleServerMessage],

  data() {
    return {
      current_user_type: this.$store.state.user_module.user.user_type,
      messages: [],
      message_type: [],
      loading: false,

      img: process.env.VUE_APP_PUBLIC_CDN + "time_change.png",

      locales: {
        en: enGB,
        ru: ru,
      },

      pop_up: {
        title: "Lesson",
        description: "description",
        selected_date: "",
        current_tab: "event_info",
        reschedule_update: false,
        click_info: {},
        update_info: {},
        select_info: {},
        event: {},
        lesson: {},
        schedule_user: {},
      },
      show_pop_up: false,
      show_availability: false,
      calendarOptions: {
        plugins: [timeGridPlugin, interactionPlugin],
        locales: [ruLocale],
        locale: this.$i18n.locale,
        initialView: "timeGrid",
        duration: { weeks: 1 },
        headerToolbar: {
          left: "prev,next",
          center: "title",
          right: "today", // user can switch between the two
        },
        views: {
          timeGrid: {
            dayHeaderFormat: {
              day: "numeric",
              weekday: "short",
              omitCommas: true,
            },
          },
        },
        firstDay: 1,
        nowIndicator: true,
        eventSources: [
          {
            events: this.getCalendarEvents,
            id: "main_event_source",
            color: "#adadff",
            textColor: "black",
          },
        ],
        businessHours: [],
        editable: true,
        allDaySlot: false,
        eventMaxStack: 1,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        eventResizableFromStart: true,
        slotLabelFormat: {
          hour: "numeric",
          minute: "numeric",
          hour12: false,
        },
        eventTimeFormat: {
          hour: "numeric",
          minute: "numeric",
          hour12: false,
        },

        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        // eventDrop: this.handleEventUpdate,

        eventsSet: this.handleEvents,
        eventChange: this.handleEventUpdate,
        /* you can update a remote database when these fire:
        eventAdd:
        eventRemove:
        */
      },
      availabilityCalendarOptions: {
        plugins: [timeGridPlugin, interactionPlugin],
        locale: this.$i18n.locale,

        initialView: "timeGrid",
        duration: { weeks: 1 },
        headerToolbar: {
          left: "",
          center: "",
          right: "",
        },
        views: {
          timeGrid: {
            dayHeaderFormat: {
              weekday: "short",
              omitCommas: true,
            },
          },
        },
        firstDay: 1,
        nowIndicator: false,
        selectOverlap: false,

        eventSources: [
          {
            events: this.getCalendarBusinessHours,
            id: "business_hours_source",
            color: "#adadff",
            textColor: "black",
          },
        ],
        editable: true,
        allDaySlot: false,
        eventMaxStack: 1,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,

        slotLabelFormat: {
          hour: "numeric",
          minute: "numeric",
          omitZeroMinute: false,
          hour12: false,
        },
        eventTimeFormat: {
          hour: "numeric",
          minute: "numeric",
          hour12: false,
        },

        select: this.handleBusinessHourSelect,
        eventClick: this.handleBusinessHourClick,

        eventsSet: this.handleEvents,
        eventChange: (changeInfo) => {
          changeInfo.revert();
        },
      },
      currentEvents: [],
      userCalendar: null,
    };
  },

  mounted() {
    if (this.windowWidth <= 576) {
      this.calendarOptions.duration = { days: 4 };
      this.calendarOptions.headerToolbar = {
        left: "prev",
        center: "title",
        right: "next", // user can switch between the two
      };
      this.calendarOptions.aspectRatio = 0.8;
      this.availabilityCalendarOptions.aspectRatio = 0.8;
    }
    this.getUserCalendarData();
    EventBus.$on("locale-change", () => {
      this.calendarOptions.locale = this.$i18n.locale;
      this.availabilityCalendarOptions.locale = this.$i18n.locale;
    });
  },

  methods: {
    async joinLesson() {
      try {
        this.$router.push({
          name: "video_chat",
          params: {
            lesson_id: this.pop_up.lesson._id,
          },
        });
      } catch (error) {
        console.log(error);
      }
    },
    getUserCalendarData() {
      SharedDataService.getUserCalendarData()
        .then((res) => {
          this.userCalendar = res.data.calendar;
          this.calendarOptions.businessHours = res.data.calendar.businessHours;
        })
        .catch((err) => {
          this.handleServerMessage(err, "err", 5000);
          this.loading = false;
          console.log(err);
        });
    },
    createEventsFromBusinessHours(businessHours) {
      let id = 0;
      let events = [];
      for (let i = 0; i < businessHours.length; i++) {
        let businessHour = businessHours[i];
        for (let j = 0; j < businessHour.daysOfWeek.length; j++) {
          let weekday = businessHour.daysOfWeek[j];
          let [eventStart, eventEnd] = createEventFromBusinessHour(
            businessHour.startTime,
            businessHour.endTime,
            weekday
          );
          events[id] = {
            id: businessHour._id,
            title: "business hour",
            start: eventStart,
            end: eventEnd,
            source: "business_hours_source",
          };

          id++;
        }
      }
      return events;
    },
    async getCalendarBusinessHours(fetchInfo, successCallback) {
      let businessHours = this.userCalendar ? this.userCalendar.businessHours : [];
      let events = this.createEventsFromBusinessHours(businessHours);
      successCallback(events);
    },
    async handleBusinessHourSelect(selectInfo) {
      try {
        this.loading = true;
        let calendarApi = selectInfo.view.calendar;

        calendarApi.unselect();

        let startTime =
          (selectInfo.start.getHours() < 10 ? "0" : "") +
          selectInfo.start.getHours() +
          ":" +
          (selectInfo.start.getMinutes() < 10 ? "0" : "") +
          selectInfo.start.getMinutes();
        let endTime =
          (selectInfo.end.getHours() < 10 ? "0" : "") +
          selectInfo.end.getHours() +
          ":" +
          (selectInfo.end.getMinutes() < 10 ? "0" : "") +
          selectInfo.end.getMinutes();
        let dayOfWeek = selectInfo.start.getDay();
        if (endTime == "00:00" && selectInfo.end > selectInfo.start) {
          endTime = "24:00";
        }
        let businessHour = {
          daysOfWeek: [dayOfWeek],
          startTime: startTime,
          endTime: endTime,
        };

        let res = await SharedDataService.addBusinessHour(businessHour);
        this.userCalendar.businessHours = res.data.businessHours;
        this.calendarOptions.businessHours = res.data.businessHours;
        calendarApi.refetchEvents();
        this.loading = false;
      } catch (err) {
        this.handleServerMessage(err, "err", 5000);
        this.loading = false;
        console.log(err);
      }
    },

    async handleBusinessHourClick(clickInfo) {
      try {
        this.loading = true;
        if (confirm(`Are you sure you want to delete this business hour?`)) {
          let res = await SharedDataService.removeBusinessHour(clickInfo.event.id);
          this.userCalendar.businessHours = res.data.businessHours;

          this.calendarOptions.businessHours = res.data.businessHours;

          clickInfo.event.remove();
          this.loading = false;
        }
      } catch (err) {
        this.handleServerMessage(err, "err", 5000);
        this.loading = false;
        console.log(err);
      }
    },

    getCalendarEvents(fetchInfo, successCallback, failureCallback) {
      this.currentEvents = [];
      this.$Progress.start();
      this.loading = true;
      SharedDataService.getUserEvents(fetchInfo.start, fetchInfo.end)
        .then((res) => {
          successCallback(res.data.events);
          this.$Progress.finish();
          this.loading = false;
        })
        .catch((err) => {
          this.handleServerMessage(err, "err", 5000);
          this.loading = false;
          failureCallback(err);
        });
    },
    async scheduleEvent() {
      try {
        this.loading = true;
        let calendarApi = this.pop_up.select_info.view.calendar;

        calendarApi.unselect(); // clear date selection
        let lesson = {
          participants: [
            this.pop_up.schedule_user.user,
            this.$store.state.user_module.user._id,
          ],
          type: "single_lesson",
        };

        let res = await SharedDataService.createEvent(
          this.pop_up.select_info,
          "lesson",
          lesson
        );

        let createdEvent = res.data.event;

        calendarApi.addEvent(
          {
            id: createdEvent.id,
            title: createdEvent.title,
            start: createdEvent.startStr,
            end: createdEvent.endStr,
            allDay: createdEvent.allDay,
            lesson: createdEvent.lesson,
            type: createdEvent.type,
          },
          "main_event_source"
        );

        this.closeTab();
      } catch (err) {
        this.handleServerMessage(err, "err", 5000);
        this.loading = false;
      }
    },
    async handleDateSelect(selectInfo) {
      try {
        this.pop_up.select_info = selectInfo;
        this.pop_up.current_tab = "schedule_event";
        this.show_pop_up = true;
      } catch (err) {
        this.handleServerMessage(err, "err", 5000);
        this.loading = false;
      }
    },
    async handleEventClick(clickInfo) {
      try {
        this.loading = true;
        if (clickInfo.event.extendedProps.type === "lesson") {
          let res = await SharedDataService.getLessonDetails(
            clickInfo.event.extendedProps.lesson
          );
          this.pop_up.lesson = res.data.lesson;
        }
        this.pop_up.event = clickInfo.event;
        this.pop_up.click_info = clickInfo;
        this.loading = false;
        this.show_pop_up = true;
      } catch (err) {
        this.handleServerMessage(err, "err", 5000);
        this.loading = false;
        console.log(err);
      }
    },

    async cancelEvent() {
      try {
        this.loading = true;
        await SharedDataService.deleteEvent(this.pop_up.event);
        this.pop_up.click_info.event.remove();
        this.closeTab();
      } catch (err) {
        this.handleServerMessage(err, "err", 5000);
        this.loading = false;
        console.log(err);
      }
    },

    async rescheduleEventPopUp() {
      try {
        this.loading = true;
        await SharedDataService.updateEvent(
          this.pop_up.update_info.oldEvent,
          this.pop_up.update_info.event
        );
        this.closeTab();
      } catch (err) {
        this.handleServerMessage(err, "err", 5000);
        this.loading = false;
        console.log(err);
      }
    },

    async rescheduleEvent() {
      try {
        let difference = differenceInMinutes(
          this.pop_up.event.end,
          this.pop_up.event.start
        );
        let new_date_end = add(this.pop_up.selected_date, {
          minutes: difference,
        });
        this.pop_up.reschedule_update = true;
        this.pop_up.event.setDates(this.pop_up.selected_date, new_date_end);
        this.closeTab();
      } catch (err) {
        this.handleServerMessage(err, "err", 5000);
        this.loading = false;
        console.log(err);
      }
    },

    async handleEventUpdate(changeInfo) {
      try {
        if (this.pop_up.reschedule_update) {
          this.loading = true;
          await SharedDataService.updateEvent(changeInfo.oldEvent, changeInfo.event);
          this.pop_up.reschedule_update = false;
          return;
        }
        this.pop_up.update_info = changeInfo;
        this.pop_up.current_tab = "reschedule_confirm_pop_up";
        this.show_pop_up = true;
        this.loading = false;
      } catch (err) {
        changeInfo.revert();
        this.handleServerMessage(err, "err", 5000);
        this.loading = false;
        console.log(err);
      }
    },

    handleEvents(events) {
      this.currentEvents = events;
    },
    showAvailabilityCalendar() {
      this.show_availability = !this.show_availability;
      if (this.show_availability) {
        this.$nextTick(function () {
          let calendarApi = this.$refs.availabilityCalendar.getApi();
          calendarApi.today();
        });
      }
    },
    closeTab(revert = false) {
      if (Object.keys(this.pop_up.update_info).length && revert) {
        this.pop_up.update_info.revert();
      }
      this.show_pop_up = false;
      this.loading = false;
      this.pop_up = {
        title: "Lesson",
        description: "description",
        selected_date: "",
        current_tab: "event_info",
        reschedule_update: false,
        update_info: {},
        select_info: {},
        click_info: {},
        event: {},
        lesson: {},
        schedule_user: {},
      };
    },
    menuNext(info) {
      if (this.pop_up.current_tab == "calendar_schedule") {
        this.pop_up.selected_date = info;
        this.pop_up.current_tab = "reschedule_confirm";
      } else if (this.pop_up.current_tab == "schedule_event") {
        this.pop_up.schedule_user = info;
        this.pop_up.current_tab = "confirm_event";
      }
    },
    getLessonParticipants() {
      let lesson = this.pop_up.lesson;
      let participant1 = lesson.participants[0];
      let participant2 = lesson.participants[1];
      let currentUser, otherUser, studentUser, teacherUser;

      if (participant1._id === this.$store.state.user_module.user._id) {
        currentUser = participant1;
        otherUser = participant2;
      } else if (participant2._id === this.$store.state.user_module.user._id) {
        currentUser = participant2;
        otherUser = participant1;
      }

      if (participant1.user_type === "student") {
        studentUser = participant1;
        teacherUser = participant2;
      } else if (participant2.user_type === "student") {
        studentUser = participant2;
        teacherUser = participant1;
      }

      return { currentUser, otherUser, studentUser, teacherUser };
    },

    imageHref(img) {
      return img ? API_ROUTE + img : "/img/no_photo_squared.png";
    },
    formatDate(d, short = false) {
      if (short) {
        return format(d, "H:mm, MMM d, yyyy", {
          locale: this.locales[this.$i18n.locale],
        });
      } else {
        return format(d, "H:mm, MMMM d, yyyy", {
          locale: this.locales[this.$i18n.locale],
        });
      }
    },
    toType(obj) {
      return {}.toString
        .call(obj)
        .match(/\s([a-zA-Z]+)/)[1]
        .toLowerCase();
    },
  },
  watch: {
    windowWidth(newVal) {
      if (newVal <= 576) {
        if (!this.show_availability) {
          let calendarApi = this.$refs.mainCalendar.getApi();
          calendarApi.setOption("duration", { days: 4 });
          calendarApi.setOption("headerToolbar", {
            left: "prev",
            center: "title",
            right: "next", // user can switch between the two
          });
          calendarApi.setOption("aspectRatio", 0.8);
        } else if (this.show_availability) {
          let calendarApi = this.$refs.availabilityCalendar.getApi();
          calendarApi.setOption("aspectRatio", 0.8);
        }
      } else if (newVal > 576) {
        if (!this.show_availability) {
          let calendarApi = this.$refs.mainCalendar.getApi();
          calendarApi.setOption("duration", { weeks: 1 });
          calendarApi.setOption("headerToolbar", {
            left: "prev,next",
            center: "title",
            right: "today", // user can switch between the two
          });
          calendarApi.setOption("aspectRatio", 1.35);
        } else if (this.show_availability) {
          let calendarApi = this.$refs.availabilityCalendar.getApi();
          calendarApi.setOption("aspectRatio", 1.35);
        }
      }
    },
    // windowWidth(newVal) {
    //   if (newVal <= 576) {
    //     let calendarApi = this.$refs.mainCalendar.getApi();
    //     console.log(calendarApi);
    //     calendarApi.setOption("duration", { days: 4 });
    //   } else if (newVal > 576) {
    //     let calendarApi = this.$refs.mainCalendar.getApi();
    //     console.log(calendarApi);
    //     calendarApi.setOption("duration", { weeks: 1 });
    //   }
    // },
  },
  computed: {
    tooLateToChange() {
      if (this.current_user_type === "student") {
        let currentDate = new Date();
        let minTimeBuffer = sub(this.pop_up.event.start, {
          minutes: 30,
        });
        return compareAsc(currentDate, minTimeBuffer) >= 0;
      } else {
        return false;
      }
    },
    joinTime() {
      let currentDate = new Date();
      let minTimeBuffer = sub(this.pop_up.event.start, {
        minutes: 30,
      });
      let isBigger = compareAsc(currentDate, minTimeBuffer);
      let isSmaller = compareAsc(this.pop_up.event.end, currentDate);
      return isBigger >= 0 && isSmaller >= 0;
    },
    otherUserType() {
      return this.current_user_type === "student" ? "teacher" : "student";
    },
    currentBusinessHours() {
      return this.userCalendar.businessHours;
    },
    popUpTitle() {
      if (this.pop_up.current_tab === "schedule_event") {
        return "Schedule a new lesson";
      } else if (this.pop_up.current_tab === "confirm_event") {
        return "Schedule a new lesson";
      } else if (this.pop_up.current_tab === "calendar_schedule") {
        return "";
      } else if (this.pop_up.current_tab === "event_info") {
        return "";
      } else if (this.pop_up.current_tab === "reschedule_confirm_pop_up") {
        return "Confirm reschedule";
      } else {
        return "";
      }
    },
  },
  beforeDestroy() {
    EventBus.$off("locale-change");
  },
};
</script>

<style lang="scss">
@import "@/css/variables.scss";

.calendar {
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.calendar-sidebar {
  width: 300px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}

.calendar-sidebar-section {
  padding: $lap;
}

.calendar-main {
  padding: $lap;
  background-color: $commonBgColor;
  border-radius: $borderRadius;
}

.fc {
  /* the calendar root */
  margin: 0 auto;
}

.fc .fc-timegrid-slot {
  height: 2.5rem;
}

.availability-calendar .fc .fc-timegrid-slot {
  height: 1.5rem;
}

.fc-timegrid {
  background: white;
  overflow: hidden;
  border-radius: $gridElementBorderRadius;
}

.fc-scrollgrid {
  border-radius: $gridElementBorderRadius;
  border: none;
}

.fc-col-header-cell-cushion {
  color: black;

  &:hover {
    color: black;
  }
}

@media screen and (max-width: 576px) {
  .fc .fc-toolbar-title {
    font-size: 1.2em;
    font-weight: 600;
  }
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}
</style>

<style lang="scss" scoped>
@import "@/css/_button.scss";
@import "@/css/_text.scss";

.row {
  margin-right: 0;
  margin-left: 0;
}

.calendar-title {
  text-align: center;
  font-size: $fontSizeLarge;
  font-family: $fontFamilyCommon;
}

.btn {
  @include blue-button;
  font-size: 12px;

  &--danger {
    @include red-button;
  }
}

.left-panel {
  padding-left: 15px;
  padding-right: 15px;
  margin-top: $f5;
  background: $commonBgColor;
  border-radius: $borderRadius;
}

.availability-button {
  width: 100%;
  margin-top: $lap;
  margin-bottom: $lap;
}

h2 {
  margin: 0;
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

li {
  margin: 1.5em 0;
  padding: 0;
}

b {
  /* used for event dates/times */
  margin-right: 3px;
}

.info {
  padding: $f6;
  margin-top: $f7;
  border: 1px solid $courseElementBgColorHover;

  background-color: $commonBgColor;
  border-radius: $borderRadius;

  &__group {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    padding: $f5;

    &--border {
      border-bottom: 1px solid $courseElementBgColorHover;
    }

    &--column {
      flex-direction: column;
      padding-top: $f7;
      padding-bottom: $f7;
    }
  }

  &__item {
    padding: $f2;
    margin: $f2;
  }

  &__order,
  &__price,
  &__total {
    font-weight: 500;
  }

  &__total,
  &__text--large {
    font-size: $fontSizeHeader;
  }

  &__order {
    font-size: $fontSizeLarge;
  }

  &__date {
    font-family: $fontFamilyCommon;
    font-weight: 600;
  }

  &__text {
    font-family: $fontFamilyCommon;

    &--small {
      font-size: $fontSizeMedium;
      font-family: Arial, Helvetica, sans-serif;
    }
  }

  &__teacher-img {
    width: 128px;
    height: 128px;
  }

  &__title {
    font-size: $fontSizeMedium;
  }
}

.event {
  display: flex;
  flex-direction: column;
}

.pop-up {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  overflow-y: scroll;
  transition: opacity 2s ease-in-out;
  text-align: center;

  &__buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: $f5;
  }

  &__button {
    flex: 1;
  }

  &__close {
    align-self: flex-end;
    margin-bottom: auto;
    color: $buttonBgColor;
    border: $buttonBgColor;

    &:hover {
      color: $buttonBgColorHover;
      border: $buttonBgColorHover;
    }

    &:active {
      color: $buttonBgColorActive;
      border: $buttonBgColorActive;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__element {
    margin-top: $f4;

    &--header {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: row;
    }
  }

  &__content {
    border-radius: $borderRadius;
    padding: $lap;
    display: inline-block;
    background-color: $commonBgColor;
    margin-top: 20vh;
  }

  &__tab {
    display: flex;
    flex-direction: column;
    align-items: center;

    &--big {
      min-width: 800px;
    }
  }

  &__date {
    font-family: $fontFamilyCommon;
    font-weight: 600;
  }

  &__title {
    @include pop-up__title;
    margin: auto;
  }

  &__sub-title {
    font-size: $fontSizeMedium;
  }
}

.pop-up-enter-active,
.pop-up-leave-active {
  transition: opacity 0.5s;
}

.pop-up-enter,
.pop-up-leave-to

/* .pop-up-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@media screen and (max-width: 1200px) {
  .pop-up__tab--big {
    min-width: 80vw;
  }
}
</style>
