<template lang="html">
  <div class="col-md-2">
    <div class="demo carousel slide" data-interval="false">
      <!-- The slideshow -->
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="related-courses-container">
            <div class="header" @mouseover="hovered = true" @mouseleave="hovered = false">
              <div class="header-text">
                {{ localizedTextFct("course_grid_titles", "related_courses") }}
              </div>
            </div>
            <div class="related-courses">
              <CoursePanelRightGrid
                :no_additional_courses="no_additional_courses"
                :courses="related_courses"
              />
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="related-courses-container">
            <div class="header" @mouseover="hovered = true" @mouseleave="hovered = false">
              <div class="header-text">
                {{ localizedTextFct("course_grid_titles", "future_courses") }}
              </div>
            </div>
            <div class="related-courses">
              <CoursePanelRightGrid
                :no_additional_courses="no_additional_courses"
                :courses="future_courses"
              />
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="related-courses-container">
            <div class="header" @mouseover="hovered = true" @mouseleave="hovered = false">
              <div class="header-text">
                {{ localizedTextFct("course_grid_titles", "preliminary_courses") }}
              </div>
            </div>
            <div class="related-courses">
              <CoursePanelRightGrid
                :no_additional_courses="no_additional_courses"
                :courses="preliminary_courses"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Left and right controls -->
      <a
        class="carousel-control-prev"
        href=".demo"
        data-slide="prev"
        @mouseover="hovered = true"
        @mouseleave="hovered = false"
      >
        <span
          class="carousel-control-prev-icon"
          :class="{ 'carousel-control-prev-icon--vissible': hovered }"
        >
        </span>
      </a>
      <a
        class="carousel-control-next"
        href=".demo"
        data-slide="next"
        @mouseover="hovered = true"
        @mouseleave="hovered = false"
      >
        <span
          class="carousel-control-next-icon"
          :class="{ 'carousel-control-next-icon--vissible': hovered }"
        >
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import CoursePanelRightGrid from "@/components/common/CoursePanelRightGrid";

export default {
  name: "CoursePanelRight",
  components: {
    CoursePanelRightGrid,
  },
  props: {
    related_courses: {
      type: Array,
      required: true,
    },
    preliminary_courses: {
      type: Array,
      required: true,
    },
    future_courses: {
      type: Array,
      required: true,
    },
    no_additional_courses: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hovered: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/common/CoursePanelRight";
</style>
