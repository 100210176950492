export default class BillingContact {
  constructor() {
    this.addressLines = [];
    this.familyName = undefined;
    this.givenName = undefined;
    this.email = undefined;
    this.country = undefined;
    this.phone = undefined;
    this.region = undefined;
    this.city = undefined;
    this.zip = undefined;
  }
}
