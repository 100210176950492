<template lang="html">
  <div class="">
    <div class="accordion" id="accordionExample">
      <div class="card">
        <div class="card-header" id="heading1">
          <h2 class="mb-0">
            <button
              class="btn btn-block text-left"
              type="button"
              data-toggle="collapse"
              data-target="#collapse1"
              aria-expanded="true"
              aria-controls="collapse1"
            >
              How can I get students?
            </button>
          </h2>
        </div>

        <div
          id="collapse1"
          class="collapse show"
          aria-labelledby="heading1"
          data-parent="#accordionExample"
        >
          <div class="card-body">
            Teachers can't directly reach to students. If student likes your profile he
            will reach you through chat or will schedule a lesson with you directly after
            reviewing your profile. Once that happens you will receive an email
            notification with further info.
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="heading2">
          <h2 class="mb-0">
            <button
              class="btn btn-block text-left collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#collapse2"
              aria-expanded="false"
              aria-controls="collapse2"
            >
              How lessons are getting conducted?
            </button>
          </h2>
        </div>
        <div
          id="collapse2"
          class="collapse"
          aria-labelledby="heading2"
          data-parent="#accordionExample"
        >
          <div class="card-body">
            You shall conduct the lessons using our video conference platform. After the
            lesson is scheduled you can join the lesson on the lesson time through your
            calendar or directly from the video conference page. ATTENTION! You
            <b>can't</b> conduct lessons using any other tool like Skype or Zoom or your
            account <b>will be banned without any notice.</b>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="heading3">
          <h2 class="mb-0">
            <button
              class="btn btn-block text-left collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#collapse3"
              aria-expanded="false"
              aria-controls="collapse3"
            >
              How I can get paid?
            </button>
          </h2>
        </div>
        <div
          id="collapse3"
          class="collapse"
          aria-labelledby="heading3"
          data-parent="#accordionExample"
        >
          <div class="card-body">
            You get paid after the lesson is over. You can withdraw your earnings by
            navigating to <b>Settings > Account balance.</b> After you enter the withdraw
            amount your earnings will be transfered to your payment method(currently we
            support credit cards and PayPal).
            <b
              >Getting payments from Promi students outside the platform is prohibited.</b
            >
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="heading4">
          <h2 class="mb-0">
            <button
              class="btn btn-block text-left collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#collapse4"
              aria-expanded="false"
              aria-controls="collapse4"
            >
              What are the commision rates?
            </button>
          </h2>
        </div>
        <div
          id="collapse4"
          class="collapse"
          aria-labelledby="heading4"
          data-parent="#accordionExample"
        >
          <div class="card-body">
            Currently the commision rate for both lessons and paid courses is
            <b>25%.</b> We use this money to attract more students and improve the
            website.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
</style>
