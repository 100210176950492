<template lang="html">
  <div class="panel-right">
    <div class="video-container">
      <video-player
        v-if="user.video_introduction"
        :src="user.video_introduction.video"
        :border_radius="true"
        :show_seek_buttons="false"
        :poster="user.video_introduction.poster"
        :custom_control_bar="customBar"
      />
    </div>
    <div class="buttons">
      <div @click="$emit('schedule-lesson')" class="button button--large">
        <font-awesome-icon class="button__icon" :icon="['far', 'calendar-check']" />
        <div class="button__text">
          {{ localizedTextFct("profile", "schedule_lesson") }}
        </div>
      </div>
      <div @click="message" class="button">
        <img class="button__icon" src="@/assets/img/chat_3.svg" />
        <div class="button__text">
          {{ localizedTextFct("user_info", "message") }}
        </div>
      </div>

      <!-- <div v-if="this.$store.state.user_module.user.User_type == 'student'" @click="addToMyTeachers" class="button"> -->
      <!-- <div @click="addToMyTeachers" class="button">
        <img class="button__icon" src="@/assets/img/heart-outline-48.png" />
        <div class="button__text">
          {{ localizedTextFct("profile", "add_teacher") }}
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import VideoPlayer from "@/components/common/VideoPlayer.vue";

export default {
  props: {
    user: {
      type: Object,
    },
  },
  components: {
    VideoPlayer,
  },
  data() {
    return {
      customBar: {
        pictureInPictureToggle: false,
        volumePanel: false,
        playbackRateMenuButton: false,
        loading: false,
      },
    };
  },
  methods: {
    message() {
      this.$router.push({ name: "chat", params: { user_id: this.user.user } });
    },
    addToMyTeachers() {
      if (!this.inMyList && !this.inMyRequestList && !this.loading) {
        this.loading = true;
        this.$store
          .dispatch("user_module/sentRequest", {
            type: "teachers",
            element: this.user,
          })
          .then(() => {
            this.loading = false;
            this.$emit("request-sent", this.user);
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      }
    },
  },
  computed: {
    inMyList() {
      return (
        this.$store.state.user_module.user_type_info["teachers"].indexOf(
          this.user._id
        ) !== -1
      );
    },
    inMyRequestList() {
      return (
        this.$store.state.user_module.user_type_info.sent_request_by_me.indexOf(
          this.user.user
        ) !== -1
      );
    },
  },
};
</script>
<style lang="scss">
@import "@/css/variables.scss";

.panel-right {
  padding: $lap;
  position: sticky;
  top: 0;
}

.buttons {
  margin-top: $f5;
  padding: $f5 0;
}

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 0px;
  font-size: $fontSizeHeader;
  border-radius: $borderRadius;
  background-color: $commonBgColor;
  margin-bottom: $f4;

  &:hover {
    background-color: $courseElementBgColor;
    cursor: pointer;
  }

  &:active {
    background-color: $courseElementBgColorHover;
  }

  &__icon {
    height: $buttonIconHeight;
    width: $buttonIconHeight;
    font-size: $buttonIconHeight;
    margin-left: $f4;
  }

  &__text {
    flex: 1;
    text-align: center;
  }

  &--large {
    background-color: $courseElementBgColorHover;

    &:hover {
      background-color: $courseElementBgColorDark;
      cursor: pointer;
    }

    &:active {
      background-color: $courseElementBgColorDark;
    }
    font-weight: 500;
    border: 2px solid black;
  }
}
</style>
